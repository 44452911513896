import numeral from "numeral";
import {
  SLOPED_TYPES,
  FLATTYPES,
  FLOOR,
  ELEVATION,
  ELEVATIONDETAILS,
  DIFFICULTY,
  WORKTYPE,
  G_LEVEL,
  G_ELEVATIONS,
  G_ELEVATION_DETAILS,
  UserRole,
  salespersion,
} from "./const";

export function clog(text) {
  console.log(text);
}

export function checkPhone(number) {
  let res = number.replace("-", "");
  res = res.replace(" ", "");
  res = res.replace("(", "");
  res = res.replace(")", "");

  return res.includes("_");
}

export function convertDate(date) {
  const DateObj = new Date(date);
  return `${
    DateObj.getMonth() + 1
  }/${DateObj.getDate()}/${DateObj.getFullYear()}`;
}

export const checkPassword = /^(?=.*\d)(?=.*[A-Za-z]).{6,20}$/;

export const getDataLabel = (data, id) => {
  const match = data.find((item) => item.id === id);
  if (match) {
    return match.name;
  }
  return id;
};

export const getFlatOrShingles = (data) => {
  if (!data) {
    return "";
  }

  const index = SLOPED_TYPES.findIndex((item) => item.id === data);
  if (index > -1) {
    return "Shingles";
  }
  return "Flat";
};

export const getElevationLetters = (array, data) => {
  let match = "";
  array.map((elem) =>
    data.includes(elem.id)
      ? (match = `${match ? `${match} ` : ""}${elem.name[0].toUpperCase()}`)
      : match
  );
  return match;
};

export const formatNumber = (num) => numeral(num).format("0,0");

export const debounce = (fn, delay) => {
  let timer;
  return (...args) => {
    const context = this;

    clearTimeout(timer);
    timer = setTimeout(() => {
      fn.call(context, args);
    }, delay);
  };
};

export const getRoofingSectionData = (section, i) => ({
  id: section.sectionId,
  sec: i + 1,
  floor: getDataLabel(FLOOR, section.floor),
  elevation:
    `${getElevationLetters(ELEVATION, section.elevation)}` +
    "\n" +
    `${getDataLabel(ELEVATIONDETAILS, section.elevation_details)}`,
  pitch: getDataLabel(DIFFICULTY, section.pitch),
  workType: getDataLabel(WORKTYPE, section.work_type),
  install: getDataLabel([...SLOPED_TYPES, ...FLATTYPES], section.install_types),
  l:
    section.lwfData.length && section.lwfData.length < 2
      ? section.lwfData[0].l
      : "*",
  w:
    section.lwfData.length && section.lwfData.length < 2
      ? section.lwfData[0].w
      : "*",
  f:
    section.lwfData.length && section.lwfData.length < 2
      ? section.lwfData[0].f
      : "*",
  sq:
    getFlatOrShingles(section.install_types) !== "Flat"
      ? section.lwfData.length && section.lwfData.length < 2
        ? formatNumber(section.lwfData[0].lwfTotal)
        : formatNumber(section.totalFlat)
      : "",
  flat:
    getFlatOrShingles(section.install_types) === "Flat"
      ? section.lwfData.length && section.lwfData.length < 2
        ? formatNumber(section.lwfData[0].lwfTotal)
        : formatNumber(section.totalFlat)
      : "",
});

export const getGuttersSectionData = (section, i) => ({
  id: section.sectionId,
  sec: i + 1,
  level: getDataLabel(G_LEVEL, section.level),
  elevation:
    `${getElevationLetters(G_ELEVATIONS, section.elevation)}` +
    "\n" +
    `${getDataLabel(G_ELEVATION_DETAILS, section.elevation_details)}`,
  gutters: section.guttersTotal ? section.guttersTotal : "",
  leaders: section.leadersTotal ? section.leadersTotal : "",
  screens: section.screensTotal ? section.screensTotal : "",
  fascia: section.fasciaTotal ? section.fasciaTotal : "",
  cap: section.cappingTotal ? section.cappingTotal : "",
  heads: section.leaderheadsTotal ? section.leaderheadsTotal : "",
});
console.log(
  "UserRole",
  UserRole.filter((val) => val)
);

export const getUserPermissions = (pageName, role, history) => {
  console.log("role ", role);
  const filterData = UserRole[0][role[0]][pageName];

  console.log("filterData", filterData);
  console.log("filterDa", history);

  if (filterData.read === false && pageName !== "subscription") {
    history.goBack();
  }
  return filterData;
};
/**
 * Scheduling modal
 * All Api end point of Scheduling Modal
 */
export const GET_SCEDULING_TIME = "/admin/getSchedulingTime";
export const GET_SCEDULING_TIME_BY_ID = "/admin/getSchedulingTime/";

export const UPDATE_SSCHEDULING_MODAL_SALES =
  "/admin/updateSchedulingModalSaleTime/";
export const UPDATE_SSCHEDULING_MODAL_REPAIR =
  "/admin/updateSchedulingModalRepairTime/";
export const UPDATE_SSCHEDULING_MODAL_PROJECT =
  "/admin/updateSchedulingModalPMT/";
export const UPDATE_SSCHEDULING_MODAL_GAPTIME_REPAIR =
  "/admin/updateSchedulingModalRepairGapTime/";
export const UPDATE_SSCHEDULING_MODAL_GAPTIME_SALES =
  "/admin/updateSchedulingModalSalesGapTime/";
export const ADMIN_NOTIFICATION = "/admin/notifications";
export const ADMIN_NOTIFICATION_GET = "/admin/notifications/";
export const ADMIN_NOTIFICATION_RETRACT = "/admin/notifications/retract";
export const GET_ALL_COMPANY_NAME = "/admin/notifications/getCompanies";

export const CLEAR_BROADCAST_NOTIFICATION =
  "/admin/notifications/clearBroadcast";

export const styyle = {
  position: "relative",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  height: 200,
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  p: 2,
  overflow: "auto",
  // borderRadius: "10px",
};

export const formatDataStateCountyExcelData = (inputArray) => {
  let outputArray = [];
  let currentCounty = {}; // Object to store the current county data

  // Iterate through the input array
  inputArray.forEach((item) => {
      // Check if the item has 'County Name' property
      if ('County Name' in item) {
          // If a new county is encountered, push the previous county data to the output array
          if (Object.keys(currentCounty).length !== 0) {
              outputArray.push(currentCounty);
          }
          // Start a new county object
          currentCounty = {
              'county': item['County Name'],
              'state': item['state'],
              'percentage': item['percentage'],
              'state_tax_percentage': item['state_tax_percentage'],
              'zipCodes': []
          };
          // Check if percentage is present and add it to the current county object
          // if ('percentage' in item) {
          //     currentCounty['percentage'] = parseFloat(item['percentage']);
          // }
      } else {
          // If 'County Name' property is not present, it means it's a zip code
          // Add the zip code to the current county object
          currentCounty['zipCodes'].push({
              'code': item['zip']
          });
          // Check if percentage is present and add it to the zip object
          if ('percentage' in item) {
              currentCounty['zipCodes'][currentCounty['zipCodes'].length - 1]['percentage'] = parseFloat(item['percentage']);
          }
      }
  });

  // Push the last county data to the output array
  outputArray.push(currentCounty);

  return outputArray;
}



export const GET_ALLVIDEO = "/admin/videos/firstTimeSetup";
export const DELETE_INDIVISUAL_VIDEO = "/admin/videos/deleteVideos/";
export const DELETE_SUBSCREEN_VIDEO = "/admin/videos/deleteSubType";
export const EDIT_SUBSCREEN_VIDEO = "/admin/videos/editSubTypes";

export const ADD_SUB_CATEGORY = "/admin/videos/addSubcategories";
export const UPLOAD_VIDEO = "/admin/videos/upload";
export const GET_ALL_ADMIN_VIDEO = "/admin/videos/firstTimeSetup";
export const DELETE_INDIVISUAL_ALL_ADMIN_VIDEO =
  "/admin/videos/firsttimeSetupDeleteVideos/";
export const ADD_SUB_ALL_ADMIN_CATEGORY = "/admin/videos/addFirstTimeSetup";
export const UPLOAD_ALL_ADMIN__VIDEO = "/admin/videos/firstTimeSetupUpload ";

export const randomKey = () => Math.floor( Math.random() * 1000);