import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";

export function YearSelector({ onChange, value }) {
  const [years, setYears] = useState([]);

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const beginningYear = 2023;
    let tmp_years = [];
    let i = currentYear;
    while (i >= beginningYear) {
      tmp_years.push(i);
      i--;
    }
    setYears([...tmp_years]);
  }, []);

  const showOptions = (list) =>
    list.map((data) => (
      <option style={{ textTransform: "capitalize" }} value={data} key={data}>
        {data}
      </option>
    ));

  return (
    <Form.Group>
      <Form.Label style={{ margin: 0 }}>{"Select Year"}</Form.Label>
      <Form.Control
        // className={`form-control select-state ${
        //   (errAt === name || errors[name]) && "error"
        // }`}
        as="select"
        name={"Select Year"}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      >
        <option value="">Choose...</option>
        {showOptions(years)}
      </Form.Control>
    </Form.Group>
  );
}
