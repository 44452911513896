import { autoTextActionTypes } from "./auto-text.types";

const INITIAL_STATE = {
  gettingTexts: false,
  autoTexts: null,
  autoTextsErr: null,
};

export const AutoTextFetchReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case autoTextActionTypes.AUTO_TEXT_FETCH_START:
      return {
        ...state,
        gettingTexts: true,
        autoTexts: null,
        autoTextsErr: null,
      };

    case autoTextActionTypes.AUTO_TEXT_FETCH_SUCCESS:
      return {
        ...state,
        gettingTexts: false,
        autoTexts: action.payload.result,
        autoTextsErr: null,
      };

    case autoTextActionTypes.AUTO_TEXT_FETCH_FAILURE:
      return {
        ...state,
        gettingTexts: false,
        autoTexts: null,
        autoTextsErr: action.payload,
      };

    default:
      return state;
  }
};

const STANDARND_STATEMENTS_INITIAL_STATE = {
  gettingStatements: false,
  statements: null,
  statementsErr: null,
};

export const StandardStatementsFetchReducer = (
  state = STANDARND_STATEMENTS_INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case autoTextActionTypes.STANDARD_STATEMENTS_FETCH_START:
      return {
        ...state,
        gettingStatements: true,
        statements: null,
        statementsErr: null,
      };

    case autoTextActionTypes.STANDARD_STATEMENTS_FETCH_SUCCESS:
      return {
        ...state,
        gettingStatements: false,
        statements: action.payload.result,
        statementsErr: null,
      };

    case autoTextActionTypes.STANDARD_STATEMENTS_FETCH_FAILURE:
      return {
        ...state,
        gettingStatements: false,
        statements: null,
        statementsErr: action.payload,
      };

    default:
      return state;
  }
};
