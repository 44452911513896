import MainComponent from "components/MainComponent";
import useHelp from "../../customHooks/useHelp/useHelp";
import React, { useCallback } from "react";
import { Col, Form, Row } from "react-bootstrap";
import "react-multi-carousel/lib/styles.css";
import { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { debounce } from "lodash";
import "../Subscription/Subscription.scss";
import Subscreen from "./Subscreen";
import { randomKey } from "../../utils/utils";

const Help = () => {
  const {
    selectCategory,
    setSelectCategory,
    setCategory,
    category,
    addCategory,
    setAddCategory,
    displaypTypeOptionsScreen,
    uploadVideo,
    UploadVideos,
    addSubCategoeies,
    allVideo,
    progressBar,
    deleteIndivisualVideo,
    setIsDelete,
    isDelete,
    setAddSetupCategory,
    addSetupCategory,
    setSetupCategory,
    setupCategory,
    setSelectSetup,
    selectSetup,
    setVideoId,
    videoId,
    setEditSubscreen,
    editSubscreen,
    handleOnChangeCategory,
    addSubCategoeiesSetup,
    UploadVideosSetup,
    handleOnClickDeleteSubscreen,
    isDeleteSubScreen,
    setIsDeleteSubscreen,
    setsubScreenId,
    setSubScreenName,
    subScreenId,
    subScreenName,

    handleOnClickSave,
  } = useHelp();

  console.log("editSubscreen", editSubscreen);
  function toggle() {
    setIsDelete(!isDelete);
  }

  function toggleSubscreen(data) {
    setIsDeleteSubscreen(!isDeleteSubScreen);
    setsubScreenId(data._id);
    setSubScreenName(data.subScreens);
  }
  const geiId = (id) => {
    setVideoId(id);
  };

  const deleteModal = () => {
    return (
      <Modal
        isOpen={isDelete}
        toggle={toggle}
        // className={props.className}
      >
        <ModalHeader toggle={toggle}>Are you sure to delete it?</ModalHeader>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => deleteIndivisualVideo(videoId)}
            // onClick={() => deleteData(_id)}
          >
            Delete
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  const deleteModalSubscreen = () => {
    return (
      <Modal
        isOpen={isDeleteSubScreen}
        toggle={toggleSubscreen}
        // className={props.className}
      >
        <ModalHeader toggle={toggleSubscreen}>
          Are you sure to delete it?
        </ModalHeader>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() =>
              handleOnClickDeleteSubscreen(subScreenName, subScreenId)
            }
            // onClick={() => deleteData(_id)}
          >
            Delete
          </Button>{" "}
          <Button color="secondary" onClick={toggleSubscreen}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  const handleDescription = (e, subscreen) => {
    const { value } = e.target;
    debouncedSave(value, subscreen);
  };

  return (
    <>
      {isDelete && deleteModal()}
      {isDeleteSubScreen && deleteModalSubscreen()}

      <MainComponent>
        <div className="_container_right _user_page">
          <div className="__proposal">
            <Row style={{ marginBottom: "5%" }}>
              <Col md="6">
                <Form.Group>
                  <Form.Label>Select Screen</Form.Label>
                  <Row>
                    <Col md="6">
                      {!addCategory ? (
                        <Form.Control
                          as="select"
                          name="pType"
                          value={selectCategory}
                          onChange={(e) => handleOnChangeCategory(e)}
                        >
                          <option value="">Choose...</option>
                          <option value="First Time Setup">
                            First Time Setup
                          </option>
                          {displaypTypeOptionsScreen()}
                        </Form.Control>
                      ) : (
                        <Form.Control
                          name="pType"
                          value={category}
                          onChange={(e) => setCategory(e.target.value)}
                        ></Form.Control>
                      )}
                    </Col>
                    <Col md="5">
                      {!addCategory ? (
                        <button
                          className="btn btn-primary"
                          onClick={() => setAddCategory(!addCategory)}
                          disabled={
                            !selectCategory ||
                            selectCategory === "First Time Setup"
                          }
                        >
                          New Category
                        </button>
                      ) : (
                        <button
                          className="btn btn-success"
                          onClick={addSubCategoeies}
                          disabled={!category}
                        >
                          Save
                        </button>
                      )}
                      {addCategory && (
                        // <Col md="1">
                        <button
                          className="btn btn-danger"
                          onClick={() => {
                            setAddCategory(false);
                            setCategory("");
                          }}
                          style={{ marginLeft: "2%" }}
                        >
                          Cancel
                        </button>
                        // </Col>
                      )}
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
              {selectCategory === "First Time Setup" && (
                <Col md="6">
                  <Form.Group>
                    <Form.Label>Select First Time Setup</Form.Label>
                    <Row>
                      <Col md="6">
                        {!addSetupCategory ? (
                          <Form.Control
                            as="select"
                            name="pType"
                            value={selectSetup}
                            onChange={(e) => setSelectSetup(e.target.value)}
                          >
                            <option value="">Choose...</option>
                            <option value="Application Settings">
                              Application Settings
                            </option>
                            <option value="Supplier Material & Labor">
                              Supplier Material & Labor
                            </option>
                          </Form.Control>
                        ) : (
                          <Form.Control
                            name="pType"
                            value={setupCategory}
                            onChange={(e) => setSetupCategory(e.target.value)}
                          ></Form.Control>
                        )}
                      </Col>
                      <Col md="5">
                        {!addSetupCategory ? (
                          <button
                            className="btn btn-primary"
                            onClick={() =>
                              setAddSetupCategory(!addSetupCategory)
                            }
                            disabled={!selectSetup}
                          >
                            New Category
                          </button>
                        ) : (
                          <button
                            className="btn btn-success"
                            onClick={addSubCategoeiesSetup}
                            disabled={!setupCategory}
                          >
                            Save
                          </button>
                        )}
                        {addSetupCategory && (
                          <button
                            className="btn btn-danger"
                            onClick={() => {
                              setAddSetupCategory(false);
                              setSetupCategory("");
                            }}
                            style={{ marginLeft: "2%" }}
                          >
                            Cancel
                          </button>
                        )}
                      </Col>
                    </Row>
                  </Form.Group>
                </Col>
              )}
            </Row>
            {selectCategory && progressBar > 0 && progressBar !== 100 && (
              <div className="progress" style={{ marginBottom: "3%" }}>
                <div
                  className="progress-bar"
                  role="progressbar"
                  aria-valuenow="70"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style={{ width: `${progressBar}%` }}
                >
                  <span className="sr-only">{progressBar}% Complete</span>
                </div>
              </div>
            )}

            {allVideo?.length > 0
              ? allVideo?.map((data) => {
                  return (
                    <Subscreen
                      key={randomKey}
                      data={data}
                      setIsDeleteSubscreen={setIsDeleteSubscreen}
                      isDeleteSubScreen={isDeleteSubScreen}
                      toggleSubscreen={toggleSubscreen}
                      handleOnClickSave={handleOnClickSave}
                      editSubscreen={editSubscreen}
                      setEditSubscreen={setEditSubscreen}
                      handleDescription={handleDescription}
                      UploadVideos={UploadVideos}
                      progressBar={progressBar}
                      setIsDelete={setIsDelete}
                      isDelete={isDelete}
                      toggle={toggle}
                      geiId={geiId}
                    />
                  );
                })
              : selectCategory && (
                  <h5 style={{ textAlign: "center" }}>No data Found</h5>
                )}
          </div>
        </div>
      </MainComponent>
    </>
  );
};

export default Help;
