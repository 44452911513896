import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { connect } from "react-redux";

import Login from "./screens/Login/Login";
import ForgotPass from "./screens/ForgotPassword/ForgotPass";

import PublicRoutes from "./auth/PublicRoutes";
import LoadingLoader from "components/LoadingLoader";
import { validateToken } from "redux/user/user.actions";
import Newlead from "screens/NewLead/Newlead";
import Subscription from "screens/Subscription/Subscription";
import Personel from "screens/Personel/Personel";
import MaterialPricing from "screens/MaterialPricing/MaterialPricing";
import CrewPricing from "screens/CrewPricing/CrewPricing";
import CartingPricing from "screens/Carting/CartingPricing";
import SchedulingModal from "screens/SchedulingModal/SchedulingModal";
import ProposalText from "screens/Proposal_Text/ProposalText";
import Notification from "screens/Notifications/Notification";
import Help from "screens/Help/Help";
import Setup from "screens/Setup/Setup";
import InformationPage from "screens/ORSubscriberAndBilling/InformationPage";
import IndivisualProfile from "screens/ORSubscriberAndBilling/IndivisualProfile";
import ClientEmailTemplate from "screens/ClientEmailTemplate/ClientEmailTemplate";
import Tax from "screens/Tax/Tax";
class AppRoute extends Component {
  state = {
    loading: true,
  };

  componentDidMount() {
    const here = this;
    this.props.dispatch(validateToken(this.disableLoading));
  }

  disableLoading = () => {
    this.setState({ loading: false });
  };


  componentDidUpdate() {
    console.log({ state: this.state });
  }
  
  render() {
    if (this.state.loading) {
      return <LoadingLoader />;
    }

    return (
      <div>
        <ToastContainer />
        <Router>
          <PublicRoutes path="/" component={Login} />
          <Route path="/forgotpassword/:email/:otp" component={ForgotPass} />
          <Route path="/indivisualprofile" component={IndivisualProfile}/>
          <Route path="/first-time-setup" component={Setup} />
          <Route path="/billing" component={InformationPage}/>
          <Route path="/newleads" component={Newlead} />
          <Route path="/subscription" component={Subscription} />
          <Route path="/supplier-personnel" component={Personel} />
          <Route path="/material-pricing" component={MaterialPricing} />
          <Route path="/sub-crew-pricing" component={CrewPricing} />
          <Route path="/carting-pricing" component={CartingPricing} />
          <Route path="/scheduling-modal" component={SchedulingModal} />
          <Route path="/proposal-text" component={ProposalText} />
          <Route path="/notification" component={Notification} />
          <Route path="/clientEmailTemplate" component={ClientEmailTemplate} />
          <Route path="/help" component={Help} />
          <Route path="/state-and-county-tax" component={Tax} />
        </Router>
      </div>
    );
  }
}




function mapStateToProps({ login: { isAuthenticated } }) {
  return {
    isAuthenticated,
  };
}

export default connect(mapStateToProps)(AppRoute);
