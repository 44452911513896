import { staticAllTypes } from "./static-all.types";

const INITIAL_STATE = {
  isLoading: false,
  data: null,
  errors: null,
};

export function staticAllReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case staticAllTypes.STATIC_ALL_START:
      return {
        ...state,
        isLoading: true,
      };

    case staticAllTypes.STATIC_ALL_SUCCESS:
      return {
        ...state,
        data: action.payload.result,
        isLoading: false,
        errors: null,
      };

    case staticAllTypes.STATIC_ALL_FAILURE:
      return {
        ...state,
        data: null,
        isLoading: false,
        errors: action.payload.message,
      };

    default:
      return state;
  }
}
