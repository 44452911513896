import { useEffect } from "react";
import { useState } from "react";
import {
  clearBroadcastNotification,
  postAdminNotification,
  retractAdminNotification,
} from "utils/notification/notification";
import { getEndPoints } from "api/getEndPoints";
import { toast } from "react-toastify";
import { axiosInstance as axios } from "services";
import {
  ADMIN_NOTIFICATION,
  ADMIN_NOTIFICATION_GET,
  GET_ALL_COMPANY_NAME,
} from "utils/utils";
import moment from "moment";
const url = getEndPoints(process.env.REACT_APP_MODE);

const useNotification = () => {
  /**
   * In this setTextMessage state the notification will set
   */
  const [textMessage, setTextMessage] = useState("");
  /**
   * In this setSelectSend state the you can select where you want to send subscription admin or broadcast
   */
  const [selectSend, setSelectSend] = useState("");
  /**
   * In this setNotificationHistory state you will gate all history of notification
   */
  const [sendType, setSendType] = useState("");
  const [retractMessage, setRetractMessage] = useState("");
  const [notificationHistory, setNotificationHistory] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openModalBroadCast, setOpenModalBroadcast] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [notification, setNotification] = useState("");
  const [allComapnyname, setAllComapnyname] = useState([]);
  const [selectComapanyName, setSelectComapnyName] = useState([]);

  const [month, setMonth] = useState(
    `${moment().year()}-${moment().month() + 1}`
  );
  const companyIds = selectComapanyName?.map((a) => a.value);

  /**
   * handleOnClicksendMesageInApp function represent the send notification to the app
   * If you select Subscription admin then the message only send to admin and if you select broad cast ot will be send to whole
   */
  const handleOnClicksendMesageInApp = (type) => {
    postAdminNotification(
      textMessage,
      "Send In App",
      getAllNotificationAdmin,
      setSelectComapnyName,
      type,
      companyIds,
      selectSend,
      setSelectSend
    );
  };
  /**
   * handleOnClicksendMesageInEmail function represent the send notification to the Email of the admin
   */
  const handleOnClicksendMesageInEmail = (type) => {
    postAdminNotification(
      textMessage,
      "Send In Email",
      getAllNotificationAdmin,
      setSelectComapnyName,
      type,
      companyIds
    );
  };
  /**
   *
   * In this get api you will gate all the hostory of notification sorted by month
   */

  const d = new Date();
  let monthh = month ? month.split("-")[1] : d.getMonth() + 1;
  let year = month ? month.split("-")[0] : d.getFullYear();

  useEffect(() => {
    getAllNotificationAdmin();
  }, [month]);
  const getAllNotificationAdmin = () => {
    setIsLoading(true);
    axios
      .get(`${url}${ADMIN_NOTIFICATION_GET}${monthh}/${year}`)
      .then((response) => {
        setIsLoading(false);

        setNotificationHistory(response.data.result);
        return { error: null, response };
      })
      .catch((error) => {
        if (error.response) {
          setIsLoading(false);

          toast.error(error.response);

          return { error: error.response };
        }
      });
  };
  /**
   * Here we get all comapny name
   */
  useEffect(() => {
    getAllCompanyName();
  }, []);
  const getAllCompanyName = () => {
    setIsLoading(true);
    axios
      .get(`${url}${GET_ALL_COMPANY_NAME}`)
      .then((response) => {
        setIsLoading(false);

        setAllComapnyname(response.data.result);
        return { error: null, response };
      })
      .catch((error) => {
        if (error.response) {
          setIsLoading(false);

          toast.error("Something went wrong");

          return { error: error.response };
        }
      });
  };

  /**
   *
   */

  const handleOnClickRetract = (notification, message, sendType) => {
    setNotification(notification);
    setRetractMessage(message);
    setSendType(sendType);
    setOpenModal(true);
  };
  const handleOnBroadCastNotification = () => {
    setOpenModalBroadcast(true);
  };
  const hideModal = () => {
    setOpenModal(false);
  };
  const clickOnRetract = () => {
    retractAdminNotification(
      notification,
      getAllNotificationAdmin,
      sendType,
      retractMessage
    );
    setOpenModal(false);
  };
  const handleOnClickClearBroadCastNotification = () => {
    clearBroadcastNotification(getAllNotificationAdmin, setOpenModalBroadcast);
  };

  const displaypTypeOptionsCompanyName = () => {
    return allComapnyname?.map((elem) => {
      // return <option value={elem?._id}>{elem?.companyId?.companyName}</option>
      return {
        label: elem?.companyId?.companyName,
        value: elem?.companyId?._id,
      };
    });
  };

  return {
    setTextMessage,
    textMessage,
    setSelectSend,
    handleOnClicksendMesageInApp,
    handleOnClicksendMesageInEmail,
    handleOnClickRetract,
    selectSend,
    handleOnBroadCastNotification,
    notificationHistory,
    openModal,
    hideModal,
    setOpenModal,
    clickOnRetract,
    openModalBroadCast,
    setOpenModalBroadcast,
    setMonth,
    month,
    handleOnClickClearBroadCastNotification,
    isLoading,
    displaypTypeOptionsCompanyName,
    setSelectComapnyName,
    selectComapanyName,
  };
};

export default useNotification;
