import { Header } from "components/Header/Header";
import SideBar from "components/SideBar/SideBar";
import React from "react";
import { Col, Row } from "react-bootstrap";

import "./Maincomponent.scss";

const MainComponent = ({ children }) => {
  return (
    <div>
      <Header />
      <div className="wrapper" style={{ margin: "20px 20px 0" }}>
        <h4>
          <span style={{ fontWeight: "bold" }}>
            Organized Roofer Application Admin
          </span>
        </h4>
        <Row style={{ marginRight: 0, marginTop: "10px" }}>
          <Col style={{ paddingRight: 0 }}>
            {/* <Row>
              <Col md="3"> */}
            <div className="_row">
              <div className="_menu_left">
                <SideBar />
              </div>
              <div className="_menu_right">{children}</div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default MainComponent;
