import React from "react";
import "./select-state.scss";
import { Form } from "react-bootstrap";
import RequiredText from "./required-text";

export default function SelectState({
  errors = {},
  errAt,
  name,
  label,
  required,
  ...props
}) {
  const STATES = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];
  const showOptions = (list) =>
    list.map((data) => (
      <option style={{ textTransform: "capitalize" }} value={data} key={data}>
        {data}
      </option>
    ));

  return (
    <Form.Group>
      <Form.Label>
        {label} {required && <RequiredText>*</RequiredText>}
      </Form.Label>

      <Form.Control
        // className={`form-control select-state ${
        //   (errAt === name || errors[name]) && "error"
        // }`}
        as="select"
        name={name}
        value={props.value}
        {...props}
      >
        <option value="">Choose...</option>
        {showOptions(STATES)}
      </Form.Control>
    </Form.Group>
  );
}
