import { Row, Col, Form, Button, Alert } from "react-bootstrap";
import { toast } from "react-toastify";
import InitialLoader from "components/InitilalLoader";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import React, { useState, useEffect, useCallback } from "react";
import SelectState from "./State";





function MangeTaxes({
    taxes,
    addSourceData,
    editSourceData,
    deleteSourceData,
    addTaxes,
    apiId,
  }) {
    const [addTax, setAddTax] = useState(false);
    const [editTax, setEditTax] = useState("");
    const [state, setState] = useState("");
    const [country, setCountry] = useState("");
    const [per, setPer] = useState("");
    const [tempState, setTempState] = useState("");
    const [tempCountry, setTempCountry] = useState("");
    const [tempPer, setTempPer] = useState("");
    const [processing, setProcessing] = useState("");
  
    const { dataList, gettingDDData, listErr } = useSelector(
      (state) => state.ddList
    );
  
    const dispatch = useDispatch();
  
    const loadData = () => {
      dispatch(getDDData(apiId));
    };
  
    useEffect(() => {
      loadData();
    }, [apiId]);
  
    useEffect(() => {
      if (dataList) {
        addTaxes(dataList);
      }
    }, [dataList]);
  
    const edit = (index, data) => {
      setEditTax(index);
      setTempState(data.state);
      setTempCountry(data.country);
      setTempPer(data.per);
    };
  
    const add = () => {
      setProcessing(newDataId);
      dispatch(
        addDDData(apiId, {
          state,
          country,
          per,
        })
      )
        .then((res) => res.data)
        .then((res) => {
          if (res.success) {
            addSourceData({
              _id: res.result,
              state,
              country,
              per,
              editable: true,
            });
            clearAll();
            setAddTax(false);
            toast.success("Added successfully!");
          } else {
            toast.error(sorryMsg);
          }
        })
        .catch((err) => {
          toast.error(err.meesage);
        })
        .finally(() => {
          setProcessing("");
        });
    };
  
    const clearAll = () => {
      setState("");
      setCountry("");
      setPer("");
    };
  
    const cancel = () => {
      setEditTax("");
      clearAll();
    };
    const handleEdit = () => {
      setProcessing(editTax);
      dispatch(
        updateDDData(apiId, editTax, {
          state: tempState,
          country: tempCountry,
          per: tempPer,
        })
      )
        .then((res) => res.data)
        .then((res) => {
          if (res.success) {
            editSourceData(editTax, {
              _id: editTax,
              editable: true,
              state: tempState,
              country: tempCountry,
              per: tempPer,
            });
            clearAll();
            setEditTax("");
            toast.success("Updated successfully!");
          } else {
            toast.error(sorryMsg);
          }
        })
        .catch((err) => {
          toast.error(err.meesage);
        })
        .finally(() => {
          setProcessing("");
        });
    };
  
    const deleteLead = (id) => {
      setProcessing(id);
      dispatch(deleteDDData(apiId, id))
        .then((res) => res.data)
        .then((res) => {
          if (res.success) {
            deleteSourceData(id);
            toast.success("Deleted successfully!");
          } else {
            toast.error(sorryMsg);
          }
        })
        .catch((err) => {
          toast.error(err.meesage);
        })
        .finally(() => {
          setProcessing("");
        });
    };
  
    const showTaxes = (taxes) => (
      <TransitionGroup className="list">
        {taxes.map((data) => (
          <CSSTransition key={data._id} timeout={500} classNames="list_item">
            <div className="_quote taxes" key={data._id}>
              {editTax === data._id ? (
                <Button onClick={() => cancel()} variant="danger">
                  Cancel
                </Button>
              ) : data.editable ? (
                <Button onClick={() => edit(data._id, data)}>Edit</Button>
              ) : null}
              {editTax === data._id ? (
                <Row>
                  <Col>
                    <Form.Group>
                      <SelectState
                        label="State"
                        onChange={(e) => setTempState(e.target.value)}
                        value={tempState}
                        errAt={false}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>County</Form.Label>
                      <Form.Control
                        type="text"
                        onChange={(e) => setTempCountry(e.target.value)}
                        value={tempCountry}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Percentage</Form.Label>
                      <Form.Control
                        type="number"
                        onChange={(e) => setTempPer(e.target.value)}
                        value={tempPer}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col>
                    <Form.Group>
                      <SelectState
                        disabled
                        label="State"
                        value={data.state}
                        errAt={false}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>County</Form.Label>
                      <Form.Control type="text" disabled value={data.country} />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Percentage</Form.Label>
                      <Form.Control type="text" disabled value={`${data.per}%`} />
                    </Form.Group>
                  </Col>
                </Row>
              )}
              {editTax === data._id ? (
                <Button
                  disabled={
                    !tempState ||
                    !tempCountry.trim() ||
                    !tempPer ||
                    isNaN(tempPer) ||
                    processing === data._id
                  }
                  onClick={() => handleEdit(data._id)}
                  variant="success"
                >
                  Save
                </Button>
              ) : (
                <Button
                  disabled={processing === data._id}
                  onClick={() => deleteLead(data._id)}
                  variant="danger"
                >
                  Delete
                </Button>
              )}
            </div>
          </CSSTransition>
        ))}
      </TransitionGroup>
    );
  
    if (gettingDDData || listErr) {
      return <InitialLoader error={listErr} onReload={loadData} />;
    }
  
    return (
      <div>
        <Button
          style={{ marginBottom: "1em" }}
          onClick={() => setAddTax(!addTax)}
        >
          Add
        </Button>
        <CSSTransition
          in={addTax}
          timeout={500}
          classNames="list_item"
          unmountOnExit
        >
          <div className="_quote taxes">
            <Button onClick={() => setAddTax(false)} variant="danger">
              Cancel
            </Button>
            <Row>
              <Col>
                <Form.Group>
                  <SelectState
                    autoFocus
                    label="State"
                    value={state}
                    onChange={(value) => setState(value.target.value)}
                    errAt={false}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>County</Form.Label>
                  <Form.Control
                    onChange={(e) => setCountry(e.target.value)}
                    type="text"
                    value={country}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Percentage</Form.Label>
                  <Form.Control
                    type="number"
                    onChange={(e) => setPer(e.target.value)}
                    value={per}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Button
              variant="success"
              disabled={
                !state ||
                !country.trim() ||
                !per ||
                isNaN(per) ||
                processing === newDataId
              }
              onClick={add}
            >
              Save
            </Button>
          </div>
        </CSSTransition>
        {Array.isArray(taxes) && showTaxes(taxes)}
      </div>
    );
}

export default MangeTaxes