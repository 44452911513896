import React from "react";
import Carousel from "react-multi-carousel";
import { Col, Form, Row } from "react-bootstrap";
import { useState } from "react";
import { useRef } from "react";
import { randomKey } from "../../utils/utils";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
function Subscreen(props) {
  const [isEdit, setIsEdit] = useState(false);
  const [description, setDesCription] = useState("");
  const [uploadVideo, setUploadVideo] = useState("");

  const {
    data,
    setIsDeleteSubscreen,
    isDeleteSubScreen,
    toggleSubscreen,
    handleOnClickSave,
    editSubscreen,
    setEditSubscreen,
    handleDescription,
    UploadVideos,
    progressBar,
    setIsDelete,
    isDelete,
    toggle,
    geiId,
  } = props;
  const handleOnClickEdit = (data) => {
    setEditSubscreen(data?.subScreens);
    setIsEdit(true);
  };
  const inputRef = useRef();
  console.log("inputRef", inputRef);
  return (
    <>
      <Row>
        <Col md="1">
          {isEdit ? (
            <button className="btn btn-danger" onClick={() => setIsEdit(false)}>
              Cancel
            </button>
          ) : (
            <button
              className="btn btn-danger"
              onClick={() => {
                setIsDeleteSubscreen(!isDeleteSubScreen);
                toggleSubscreen(data);
              }}
            >
              Delete
            </button>
          )}
        </Col>
        <Col md="1">
          {isEdit ? (
            <button
              className="btn btn-success"
              style={{ width: "70px" }}
              onClick={() => handleOnClickSave(data, setIsEdit)}
            >
              Save
            </button>
          ) : (
            <button
              className="btn btn-primary"
              style={{ width: "70px" }}
              onClick={() => handleOnClickEdit(data)}
            >
              Edit
            </button>
          )}
        </Col>

        <Col md="3">
          {isEdit ? (
            <Form.Group>
              <Form.Control
                name="pType"
                value={editSubscreen}
                onChange={(e) => setEditSubscreen(e.target.value)}
              ></Form.Control>
            </Form.Group>
          ) : (
            <h4>{data?.subScreens}</h4>
          )}
        </Col>
        <Col md="3">
          <input
            ref={inputRef}
            // value={uploadVideo[0]}
            className="VideoInput_input"
            type="file"
            onChange={(e) => setUploadVideo(e.target.files[0])}
            accept=".mp4"
          />
        </Col>
        <Col md="3">
          <Form.Group>
            <Form.Control
              id={data?.subScreens}
              name="pType"
              value={description}
              onChange={(e) => setDesCription(e.target.value)}
              placeholder="add descriptions"
            ></Form.Control>
          </Form.Group>
        </Col>
        <Col md="1">
          <button
            className="btn btn-primary"
            onClick={() =>
              UploadVideos(
                data?._id,
                data?.subScreens,
                description,
                setDesCription,
                setUploadVideo,
                uploadVideo,
                inputRef
              )
            }
            disabled={
              !description ||
              !uploadVideo ||
              (progressBar > 0 && progressBar !== 100)
            }
          >
            Upload
          </button>
        </Col>
        <Row
          style={
            data?.videoInfo?.length > 0
              ? { marginTop: "2%" }
              : { height: "230px", width: "100%" }
          }
        >
          <Carousel
            swipeable={false}
            draggable={false}
            // showDots={true}
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            // infinite={true}
            autoPlaySpeed={1000}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={500}
            containerclassName="carousel-container"
            // removeArrowOnDeviceType={["tablet", "mobile"]}
            deviceType="desktop "
            dotListclassName="custom-dot-list-style"
            itemclassName="carousel-item-padding-10-px"
          >
            {data?.videoInfo?.length > 0 ? (
              data?.videoInfo?.map((res) => {
                return (
                  <Col md="3" style={{ marginBottom: "10%" }} key={randomKey}>
                    <div className="owl-carousel owl-theme">
                      <div className="item">
                        <p style={{ marginTop: "10%" }}>{res?.descriptions}</p>

                        <video width="290" height="170" controls>
                          <source src={res?.url} type="video/mp4" />
                          <source src={res?.url} type="video/ogg" />
                        </video>

                        <div style={{ textAlign: "center" }}>
                          <button
                            className="btn btn-danger"
                            onClick={() => {
                              setIsDelete(!isDelete);
                              toggle();
                              geiId(res?.videoId);
                            }}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  </Col>
                );
              })
            ) : (
              <h5 style={{ textAlign: "center", marginTop: "7%" }}>
                No video added yet
              </h5>
            )}
          </Carousel>
        </Row>
      </Row>
    </>
  );
}

export default Subscreen;
