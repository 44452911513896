import { getEndPoints } from "api/getEndPoints";
import { toast } from "react-toastify";
import { axiosInstance as axios } from "services";
import {
  ADMIN_NOTIFICATION,
  ADMIN_NOTIFICATION_RETRACT,
  CLEAR_BROADCAST_NOTIFICATION,
} from "utils/utils";

const url = getEndPoints(process.env.REACT_APP_MODE);

/**
 * post notification in admin
 */
export const postAdminNotification = (
  textMessage,
  notificationType,
  getAllNotificationAdmin,
  setSelectComapnyName,
  type,
  companyIds,
  selectSend,
  setSelectSend
) => {
  const body = {
    notification: textMessage,
    notificationType: notificationType,
    receiverType: selectSend,
    companyId: companyIds,
  };

  axios
    .post(`${url}${ADMIN_NOTIFICATION}`, body)
    .then((response) => {
      toast.success("Notification send sucessfully.");
      getAllNotificationAdmin();
      if (type === "app") {
        setSelectSend("");
      }
      setSelectComapnyName([]);

      return { error: null, response };
    })
    .catch((error) => {
      if (error.response) {
        toast.error(error.response);
        getAllNotificationAdmin();

        return { error: error.response };
      }
    });
};
/**
 * retract notification in admin
 */
export const retractAdminNotification = (
  notification,
  getAllNotificationAdmin,
  selectSend,
  retractMessage
) => {
  const body = {
    type: selectSend,
    message: retractMessage,
  };
  axios
    .patch(`${url}${ADMIN_NOTIFICATION_RETRACT}/${notification}`, body)
    .then((response) => {
      console.log("response", response);
      if (response?.data?.success === true) {
        toast.success("Retracted message sucessfully.");
      }
      getAllNotificationAdmin();
      return { error: null, response };
    })
    .catch((error) => {
      if (error.response) {
        toast.error(error.response);
        getAllNotificationAdmin();

        return { error: error.response };
      }
    });
};
/**
 * retract notification in admin
 */
export const clearBroadcastNotification = (
  getAllNotificationAdmin,
  setOpenModalBroadcast
) => {
  axios
    .post(`${url}${CLEAR_BROADCAST_NOTIFICATION}`)
    .then((response) => {
      if (response?.data?.success === true) {
        toast.success("Broadcast Notification clear sucessfully.");
        setOpenModalBroadcast(false);
      }
      getAllNotificationAdmin();
      return { error: null, response };
    })
    .catch((error) => {
      if (error.response) {
        toast.error(error.response);
        getAllNotificationAdmin();

        return { error: error.response };
      }
    });
};
