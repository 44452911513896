import React,{useState, useEffect} from 'react'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
// import "bootstrap/dist/css/bootstrap.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import InitialLoader from 'components/InitilalLoader';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { ArchiveBook } from 'iconsax-react';
import "./UpcomingPaymentAccordion.css"
import { toast } from 'react-toastify';

const IndivisualProfileTable = (props) => {
  console.log({propsImagesss: props})
  const [mappedProducts, setMappedProducts] = useState()
  const [line, setLine] = useState()
  const [invoiceId, setInvoiceId] = useState()
  const [periodStart, setPeriodStart] = useState()
  const [periodEnd, setPeriodEnd] = useState()
  const [invoiceUrl, setInvoiceUrl] = useState()
  const [invoicePdf, setInvoicePdf] = useState()
  const [paymentShow, setPaymentShow] = useState(false)
  const [paymentAmt, setPaymentAmt] = useState()
  const [paymentType, setPaymentType] = useState()
  const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
  const {selected_product} = props
  const [
    {
      company: [
        {
          companyName
        }
      ]
    }
  ] = selected_product

  const customHeaderStyle = {
    // width: '150px', // Adjust this width as needed
    // whiteSpace: 'nowrap',
    // overflow: 'hidden',
    // textOverflow: 'ellipsis',
  };

  const handleStatusClose = () => {
    setPaymentShow(false);
  };

  useEffect(() => {
    if(selected_product && selected_product.length){
      let product = selected_product[0]?.history.map(val => {
        let {
          referenceId,
          paymentStatus,
          paymentPlanUser,
          paymentType,
          dateOfPayment,
          subscriptionId,
          paymentAmount,
          lines,
          invoice_id,
          period_start,
          period_end,
          invoice_url,
          invoice_pdf
        } = val
    
        return {
          id: referenceId ? referenceId : subscriptionId,
          companyName: companyName,
          paymentStatus: paymentStatus,
          paymentType: paymentType,
          paymentPlanUser: paymentPlanUser,
          paymentAmount: `$ ${paymentAmount}`,
          DateOfPayment: dateOfPayment,
          lines,
          invoice_id,
          period_start,
          period_end,
          invoice_url,
          invoice_pdf
        };
      })
    
      console.log({product})
  
      setMappedProducts(product)
    }
  }, [selected_product])

  const handleArchiveIconClick = (invoiceId) => {
    // Navigate to the invoice page using your routing logic
    // For example, using React Router:
    // history.push(`/invoice/${invoiceId}`);
    console.log({invoiceId})
    // return <a href={invoiceId} target='_blank'></a>
    invoiceId ? window.open(invoiceId, '_blank') : toast.error("No invoice available");
  };

  const ArchiveIconColumn = ({ onClick }) => (
    <div className="archive-button" onClick={onClick}>
      <ArchiveBook size="32" color="#0d6efd" />{" "}
    </div>
  );

  const buttonFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div className="cell-scrollable">
        <button
          className="btn btn-primary"
          onClick={() => formatExtraData.onClick(row)}
        >
          {formatExtraData.buttonText}
        </button>
      </div>
    );
  };

  const columns = [
    {
      dataField: "id",
      text: "Payment Id",
      headerStyle:{ width: "25%", position: 'center', top: 0, zIndex: 1, background: '#fff' },
      // classes: 'overflow-cell', 
      formatter: (cellContent) => <div className="cell-scrollable">{cellContent}</div>
    },
    // {
    //   dataField: "companyName",
    //   text: "Company Name",
    // },
    {
      dataField: "paymentStatus",
      text: "Status",
    },
    {
      dataField: "paymentType",
      text: "Payment Type",
      // classes: 'overflow-cell', 
      formatter: (cellContent) => <div className="cell-scrollable">{cellContent}</div>
    },
    {
      dataField: "paymentPlanUser",
      text: "Payment Plan (number of users)",
      // classes: 'overflow-cell', 
      formatter: (cellContent) => <div className="cell-scrollable">{cellContent}</div>,
    },
    {
      dataField: "paymentAmount",
      text: "Payment Amount",
      // classes: 'overflow-cell',
      formatter: (cellContent) => <div className="cell-scrollable">{cellContent}</div> 
    },
    {
      dataField: "DateOfPayment",
      text: "Date of Payment",
      // classes: 'overflow-cell', 
      formatter: (cellContent) => <div className="cell-scrollable">{cellContent}</div>
    },
    {
      dataField: 'id', // Replace with your data field
      text: 'Actions', // Column header
      formatter: buttonFormatter, // Custom button formatter
      headerStyle:{ width: "15%", position: 'center', top: 0, zIndex: 1, background: '#fff' },
      classes: 'overflow-cell', 
      formatExtraData: {
        onClick: (row) => {
          // Handle button click for the specific row
          setPeriodStart(row.paymentType === "Sign-up" ? row.DateOfPayment: row?.lines && row.lines.length && row.lines[0].data[0].period.start)
          setPeriodEnd(row?.lines && row.lines.length && row.lines[0].data[0].period.end)
          setPaymentShow(true)
          setPaymentType(row.paymentType)
          setLine(row?.lines && row.lines)
          setInvoiceUrl(row?.invoice_url && row.invoice_url)
          setPaymentAmt(row.paymentAmount.trim())
          console.log('Button clicked for row:', row);
        },
        buttonText: 'View Payment', // Text for the button
      },
    },
    {
      dataField: 'invoice_url',
      text: 'Invoice',
      classes: 'overflow-cell', 
      formatter: (cell, row) => {
        console.log({rowcheck: row})
        return (
        <ArchiveIconColumn
          onClick={() => handleArchiveIconClick(row.invoice_url)} // Replace with your onClick logic
        />
      )},
    },
    // {
    //   dataField: "loginTime",
    //   text: "Last Login Time",
    // },
  ];

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 5,
    lastPageText: '>>',
    firstPageText: '<<',
    nextPageText: '>',
    prePageText: '<',
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      console.log('page', page);
      console.log('sizePerPage', sizePerPage);
    },
    onSizePerPageChange: function (page, sizePerPage) {
      console.log('page', page);
      console.log('sizePerPage', sizePerPage);
    }
  });
  if (!mappedProducts) {
    return <InitialLoader />;
  }
  if(!mappedProducts.length){
    return "No record available"
  }

  console.log({line})
  return (
    <>
      <Modal show={paymentShow} onHide={handleStatusClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {periodStart
              ? `${
                  line &&
                  line.length &&
                  line[0].data &&
                  line[0].data.length &&
                  paymentType === "Monthly"
                    ? "Monthly"
                    : line &&
                      line.length &&
                      line[0].data &&
                      line[0].data.length &&
                      paymentType === "Users"
                    ? "Additional Users"
                    : "Sign-Up"
                } ${
                  line &&
                  line.length &&
                  line[0].data &&
                  line[0].data.length &&
                  paymentType === "Monthly"
                    ? "Payment For"
                    : ""
                } ${
                  line &&
                  line.length &&
                  line[0].data &&
                  line[0].data.length &&
                  paymentType === "Monthly"
                    ? month[new Date(periodStart * 1000).getUTCMonth()]
                    : ""
                }`
              : ""}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <strong>
              <p>Date: </p>
            </strong>{" "}
            <p>
              {periodStart && line && line.length ? new Date(periodStart * 1000).toDateString() : periodStart && line && !line.length ? Date(periodStart).split(" ").slice(0,4).join(" ") : ""}{" "}
              {periodEnd && paymentType === "Monthly" ? "-" : ""}{" "}
              {periodEnd && paymentType === "Monthly" ? new Date(periodEnd * 1000).toDateString() : ""}
            </p>
          </div>
          {/* <p style={{ wordWrap: "break-word" }}>
            <strong>invoice url: </strong>
            <a href={invoiceUrl && invoiceUrl} target="_blank" style={{textDecoration: "underline"}}>
              {invoiceUrl && invoiceUrl}
            </a>
          </p> */}
          {line && line.length && line[0].data && line[0].data.length
            ? line[0].data.map((val, index) => {
                return (
                  // {val.description}{":"} <strong>{val.amount}</strong>
                  <>
                    <ul className="list-unstyled">
                      <li
                        key={index}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span className="product-description">
                          <strong>
                            {val.description}
                            {" : "}
                          </strong>
                        </span>
                        <span className="product-amount">
                          ${(val.amount / 100).toFixed(2)}
                        </span>
                      </li>
                    </ul>
                    {/* <p className="total-amount-due">
                      <strong>Total Amount Due:</strong> $
                      {(val.amount / 100).toFixed(2)}
                    </p> */}
                  </>
                );
              })
            : ""}
          {
            <p
              className="total-amount-due"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <strong>Total Amount:</strong>{" "}
              {line && line.length && line[0].data && line[0].data.length
                ? "$"
                : ""}
              {line && line.length && line[0].data && line[0].data.length
                ? line[0].data
                    .reduce(
                      (accumulator, currentValue) =>
                        accumulator + currentValue.amount / 100,
                      0
                    )
                    .toFixed(2)
                : paymentAmt
                ? paymentAmt
                : 0}
            </p>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleStatusClose}>
            Close
          </Button>
          {/* <Button variant="primary" onClick={() => {}}>
            Update Status
          </Button> */}
        </Modal.Footer>
      </Modal>
      <div className="table-container">
        <div className="table-wrapper">
          {mappedProducts && mappedProducts.length && (
            <BootstrapTable
              keyField="id"
              data={mappedProducts}
              columns={columns}
              // rowEvents={{ onClick: (e, row) => handleRowClick(row) }}
              pagination={pagination}
              // filter={filterFactory()}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default IndivisualProfileTable