import InitialLoader from "components/InitilalLoader";
import MainComponent from "components/MainComponent";
import useAllMaterialData from "customHooks/PricingData/MaterialPricing/useAllMaterialData";

import React from "react";
import { useEffect } from "react";
import { useState } from "react";

import { Col, Form, Row } from "react-bootstrap";
import { Modal, ModalHeader, ModalFooter, Button } from "reactstrap";

import "../Subscription/Subscription.scss";
import CommonSeriesInput from "./CommonSeriesInput";
import { randomKey } from "../../utils/utils";

function MaterialPricing() {
  const [typeOfDeleteOp, setTypeOfDeleteOp] = useState(null);

  const {
    showOptions,
    categoryList,
    typesList,
    manufacturerList,
    subTypes,
    setSelectCatagory,
    setSelectManufacture,
    setSelectCatagpoyType,
    setSelectSeriesType,
    selectCatagory,
    selectCatagoryType,
    selectManufacture,
    selectSeriesType,
    handleChange,
    subName,
    materialSeriesData,
    isLoadingSeries,
    setIsLoadingSeries,
    setAllSeriesData,
    uomUnit,
    uomType,
    addUpdateSeries,
    allPreferedData,
    addCatagoryType,
    addManufacture,
    PreferedValue,
    isLoading,
    setAddManufacture,
    addManufacturer,
    manufactur,
    setManufactur,
    setAddCatagoryType,
    addCatagoryTypee,
    setCataGoryType,
    cataGoryType,
    setShowAddNew,
    showAddNew,
    delteMaterialSeries,
    saveData,
    editData,
    deleteData,
    handleOnAddNew,
    setAdd,
    add,
    addNewSeries,
    setAddNewSeries,
    allPrefered,
    setAllPrefered,
    setAllMaterialData,
    addSeriesType,
    seriesType,
    setSeriesType,
    addSeriesTypes,
    setAddseriesType,
    deleteManufacture,
    deleteSeriesType,
    deleteCatagoryType,
    showCatagoryTypeBtn,
    isDelete,
    setIsDelete,
    allSeriesUom,
    allUomType,
    setIsMisc,
  } = useAllMaterialData();
  function toggle() {
    setIsDelete(!isDelete);
  }
  const deleteModal = () => {
    return (
      <Modal isOpen={isDelete} toggle={toggle}>
        <ModalHeader toggle={toggle}>Are you sure to delete it?</ModalHeader>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              deleteRespectiveOptions();
            }}
          >
            Delete
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  // useEffect(() => {
  //   // setIsDelete(!isDelete);
  //   if (typeOfDeleteOp) {
  //     console.log("typeOfDeleteOp ", typeOfDeleteOp);
  //     setIsDelete(!isDelete);
  //   }
  // }, [typeOfDeleteOp]);
  const handleOnDeleteManufacture = () => {
    setTypeOfDeleteOp("manufacture");
    setIsDelete(!isDelete);
  };
  const handleOnDeleteCategoryType = () => {
    setTypeOfDeleteOp("ctype");
    setIsDelete(!isDelete);
  };
  const handleOnDeleteSeriesType = () => {
    setTypeOfDeleteOp("series");
    setIsDelete(!isDelete);
  };
  const deleteRespectiveOptions = () => {
    if (typeOfDeleteOp === "manufacture") deleteManufacture();
    if (typeOfDeleteOp === "series") {
      if (selectCatagory === "Shingles") {
        deleteSeriesType({
          category: selectCatagory,
          categoryType: selectCatagoryType,
          manufacturer: selectManufacture,
          subType: selectSeriesType,
        });
      } else
        deleteSeriesType({
          category: selectCatagory,
          categoryType: selectCatagoryType,
          subType: selectSeriesType,
        });
    }
    if (typeOfDeleteOp === "ctype") deleteCatagoryType();
    toggle();
  };
  const handleOnClickAddseriesType = () => {
    if (selectCatagory === "Shingles") {
      addSeriesType({
        category: selectCatagory,
        categoryType: selectCatagoryType,
        manufacturer: selectManufacture,
        subType: seriesType,
      });
    } else
      addSeriesType({
        category: selectCatagory,
        categoryType: selectCatagoryType,
        subType: seriesType,
      });
  };
  return (
    <MainComponent>
      {isDelete && deleteModal()}
      <div className="_container_right _user_page">
        <div className="__proposal">
          {isLoading ? (
            <InitialLoader />
          ) : (
            <div style={{ margin: "2% 2% 0" }}>
              <Row style={{ marginBottom: "2%" }}>
                <Col md="3">
                  <Form.Group>
                    <Form.Label>Category</Form.Label>
                    <Row>
                      <Col md="12">
                        <Form.Control
                          as="select"
                          name="pType"
                          onChange={(event) =>
                            handleChange(event, setSelectCatagory, "category")
                          }
                        >
                          <option value="">Choose...</option>

                          {showOptions(categoryList)}
                        </Form.Control>
                      </Col>
                    </Row>
                  </Form.Group>
                </Col>

                {selectCatagory && typesList.length > 0 && (
                  <Col md="3">
                    <Form.Group>
                      {showCatagoryTypeBtn() ? (
                        !addCatagoryTypee && !selectCatagoryType ? (
                          <button
                            className="btn-primary"
                            style={{
                              float: "right",
                              backgroundColor: "#4242f7",
                              color: "white",
                              borderRadius: "5px",
                              borderColor: "#4242f7",
                              fontSize: "0.8rem",
                            }}
                            onClick={() =>
                              setAddCatagoryType(!addCatagoryTypee)
                            }
                          >
                            Add New
                          </button>
                        ) : !selectCatagoryType ? (
                          <button
                            className="btn-primary"
                            style={{
                              float: "right",
                              backgroundColor: "green",
                              color: "white",
                              borderRadius: "5px",
                              borderColor: "green",
                              fontSize: "0.8rem",
                            }}
                            onClick={() => addCatagoryType(selectCatagory)}
                          >
                            Save
                          </button>
                        ) : (
                          <button
                            className="btn-primary"
                            style={{
                              float: "right",
                              backgroundColor: "red",
                              color: "white",
                              borderRadius: "5px",
                              borderColor: "red",
                              fontSize: "0.8rem",
                            }}
                            onClick={handleOnDeleteCategoryType}
                          >
                            Delete
                          </button>
                        )
                      ) : (
                        ""
                      )}
                      <Form.Label>{selectCatagory} Type</Form.Label>
                      <Row>
                        <Col md="12">
                          {!addCatagoryTypee ? (
                            <Form.Control
                              as="select"
                              name="pType"
                              onChange={(event) =>
                                handleChange(
                                  event,
                                  setSelectCatagpoyType,
                                  "ctype"
                                )
                              }
                            >
                              <option value="">Choose...</option>

                              {showOptions(typesList)}
                            </Form.Control>
                          ) : (
                            <Form.Control
                              name="pType"
                              value={cataGoryType}
                              onChange={(e) => setCataGoryType(e.target.value)}
                            ></Form.Control>
                          )}
                        </Col>
                      </Row>
                    </Form.Group>
                  </Col>
                )}

                {selectCatagoryType && manufacturerList?.length > 0 && (
                  <Col md="3">
                    <Form.Group>
                      <Form.Label>Manufacturer</Form.Label>
                      {!addManufacturer && !selectManufacture ? (
                        <button
                          className="btn-primary"
                          style={{
                            float: "right",
                            backgroundColor: "#4242f7",
                            color: "white",
                            borderRadius: "5px",
                            borderColor: "#4242f7",
                          }}
                          onClick={() => setAddManufacture(!addManufacturer)}
                        >
                          Add New
                        </button>
                      ) : !selectManufacture ? (
                        <button
                          className="btn-primary"
                          style={{
                            float: "right",
                            backgroundColor: "green",
                            color: "white",
                            borderRadius: "5px",
                            borderColor: "green",
                          }}
                          onClick={() =>
                            addManufacture(selectCatagory, selectCatagoryType)
                          }
                        >
                          Save
                        </button>
                      ) : (
                        <button
                          className="btn-primary"
                          style={{
                            float: "right",
                            backgroundColor: "red",
                            color: "white",
                            borderRadius: "5px",
                            borderColor: "red",
                          }}
                          onClick={handleOnDeleteManufacture}
                        >
                          Delete
                        </button>
                      )}

                      <Row>
                        <Col md="12">
                          {!addManufacturer ? (
                            <Form.Control
                              as="select"
                              name="pType"
                              value={selectManufacture}
                              onChange={(event) =>
                                handleChange(
                                  event,
                                  setSelectManufacture,
                                  "manufacturer"
                                )
                              }
                            >
                              <option value="">Choose...</option>

                              {showOptions(manufacturerList)}
                            </Form.Control>
                          ) : (
                            <Form.Control
                              name="pType"
                              value={manufactur}
                              onChange={(e) => setManufactur(e.target.value)}
                            ></Form.Control>
                          )}
                        </Col>
                      </Row>
                    </Form.Group>
                  </Col>
                )}
                {((subName && selectManufacture && subTypes.length > 0) ||
                  (selectManufacture &&
                    selectCatagory === "Shingles" &&
                    selectCatagoryType === "Roofing")) && (
                  <Col md="3">
                    <Form.Group>
                      <Form.Label>
                        {subName === "subType" ? "Type" : subName}
                      </Form.Label>
                      {!addSeriesTypes && !selectSeriesType ? (
                        <button
                          className="btn-primary"
                          style={{
                            float: "right",
                            backgroundColor: "#4242f7",
                            color: "white",
                            borderRadius: "5px",
                            borderColor: "#4242f7",
                          }}
                          onClick={() => setAddseriesType(!addSeriesTypes)}
                        >
                          Add New
                        </button>
                      ) : !selectSeriesType ? (
                        <button
                          className="btn-primary"
                          style={{
                            float: "right",
                            backgroundColor: "green",
                            color: "white",
                            borderRadius: "5px",
                            borderColor: "green",
                          }}
                          onClick={handleOnClickAddseriesType}
                        >
                          Save
                        </button>
                      ) : (
                        <button
                          className="btn-primary"
                          style={{
                            float: "right",
                            backgroundColor: "red",
                            color: "white",
                            borderRadius: "5px",
                            borderColor: "red",
                          }}
                          onClick={handleOnDeleteSeriesType}
                        >
                          Delete
                        </button>
                      )}
                      <Row>
                        <Col md="12">
                          {!addSeriesTypes ? (
                            <Form.Control
                              as="select"
                              name="pType"
                              value={selectSeriesType}
                              onChange={(event) =>
                                handleChange(event, setSelectSeriesType)
                              }
                            >
                              <option value="">Choose...</option>

                              {showOptions(subTypes)}
                            </Form.Control>
                          ) : (
                            <Form.Control
                              name="pType"
                              value={seriesType}
                              onChange={(e) => setSeriesType(e.target.value)}
                            ></Form.Control>
                          )}
                        </Col>
                      </Row>
                    </Form.Group>
                  </Col>
                )}
              </Row>

              {PreferedValue()}
              {materialSeriesData?.length > 0 &&
                !isLoadingSeries &&
                PreferedValue("series")}

              {isLoadingSeries ? (
                <InitialLoader />
              ) : (
                <>
                  {showAddNew && (
                    <Row
                      style={{
                        marginBottom: "4%",
                        float: "right",
                      }}
                    >
                      <Col md="12">
                        <button
                          className="btn btn-primary"
                          onClick={handleOnAddNew}
                        >
                          Add New
                        </button>
                      </Col>
                    </Row>
                  )}
                  {(materialSeriesData?.length > 0 || add) && (
                    <Row style={{ marginTop: "10%" }}>
                      <Col md="1">
                        <Form.Group style={{ textAlign: "center" }}>
                          <Form.Label>Preferred</Form.Label>
                        </Form.Group>
                      </Col>
                      <Col md="3">
                        <Form.Group style={{ textAlign: "center" }}>
                          <Form.Label>Series</Form.Label>
                        </Form.Group>
                      </Col>

                      <Col md="2">
                        <Form.Group style={{ textAlign: "center" }}>
                          <Form.Label>UOM</Form.Label>
                        </Form.Group>
                      </Col>
                      {uomType !== "N/A" &&
                        materialSeriesData[0].category !==
                          "Flat Roofing Primer" && (
                          <Col md="2">
                            <Form.Group style={{ textAlign: "center" }}>
                              <Form.Label>{allUomType}</Form.Label>
                            </Form.Group>
                          </Col>
                        )}

                      <Col md="2">
                        <Form.Group style={{ textAlign: "center" }}>
                          <Form.Label>Price Per UOM</Form.Label>
                        </Form.Group>
                      </Col>
                    </Row>
                  )}
                  {add && (
                    <CommonSeriesInput
                      saveData={saveData}
                      editData={true}
                      UOM={allSeriesUom}
                      uomType={allUomType}
                      setAddNewSeries={setAddNewSeries}
                      addNewSeries={addNewSeries}
                      category={selectCatagory}
                      manufacturer={selectManufacture}
                      categoryType={selectCatagoryType}
                      subType={selectSeriesType}
                      deleteData={deleteData}
                      allPrefered={allPrefered}
                      setAllPrefered={setAllPrefered}
                      setAdd={setAdd}
                      add={add}
                    />
                  )}
                  {materialSeriesData?.length > 0 ? (
                    materialSeriesData?.map((data) => (
                      <CommonSeriesInput
                        key={randomKey}
                        setAddNewSeries={setAddNewSeries}
                        saveData={editData}
                        deleteData={deleteData}
                        Preferred={data?.isPreferred}
                        Series={data?.series}
                        UOM={uomUnit}
                        uomType={allUomType}
                        categoryUomType={data.uomType}
                        BDLPerSq={data?.uomValue}
                        PricePerUom={data?.uomPrice}
                        _id={data._id}
                        setIsLoadingSeries={setIsLoadingSeries}
                        addNewSeries={addNewSeries}
                        category={selectCatagory}
                        manufacturer={selectManufacture}
                        categoryType={selectCatagoryType}
                        subType={selectSeriesType}
                        allPrefered={allPrefered}
                        setAllPrefered={setAllPrefered}
                        priceData={data}
                        materialSeriesData={materialSeriesData}
                        setAllSeriesData={setAllSeriesData}
                        setAdd={setAdd}
                        add={add}
                      />
                    ))
                  ) : (
                    <h6 style={{ textAlign: "center", marginTop: "10%" }}>
                      No Data Found
                    </h6>
                  )}
                  {materialSeriesData?.length > 0 && (
                    <Row style={{ marginTop: "5%", float: "right" }}>
                      <Col md="12">
                        <Row>
                          <Col md="6">
                            <button className="btn btn-danger">Cancel</button>
                          </Col>
                          <Col md="6">
                            <button
                              className="btn btn-success"
                              onClick={() =>
                                addUpdateSeries(setIsLoadingSeries)
                              }
                              //   disabled={!adNewSeries}
                              style={{ width: "70px" }}
                            >
                              Save
                            </button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </MainComponent>
  );
}

export default MaterialPricing;
