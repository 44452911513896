import React from "react";
import "./select-state.scss";
import { Form } from "react-bootstrap";
import RequiredText from "./required-text";

export default function SelectActive({
  errors = {},
  errAt,
  name,
  label,
  required,
  ...props
}) {
  const activeState = [
    "active",
    "in-active"
  ];
  const showOptions = (list) =>
    list.map((data) => (
      <option style={{ textTransform: "capitalize" }} value={data} key={data}>
        {data}
      </option>
    ));

  return (
    <Form.Group>
      <Form.Label>
        {label} {required && <RequiredText>*</RequiredText>}
      </Form.Label>

      <Form.Control
        // className={`form-control select-state ${
        //   (errAt === name || errors[name]) && "error"
        // }`}
        as="select"
        name={name}
        value={props.value}
        {...props}
      >
        <option value="">Choose...</option>
        {showOptions(activeState)}
      </Form.Control>
    </Form.Group>
  );
}
