import { axios, axiosInstance } from 'services';

import AuthService from 'services/auth-service';
import { getUserLocal } from 'lib/localbase';
import { UserActionTypes } from './user.types';

export const LoginFailure = (errors) => ({
  type: UserActionTypes.LOGIN_FAILURE,
  payload: errors,
});

export const LoginStart = () => ({
  type: UserActionTypes.LOGIN_START,
});

export const LoginSuccess = (data) => ({
  type: UserActionTypes.LOGIN_SUCCESS,
  payload: data,
});

export const registerUser = (user) => {
  const Url = '/signup';
  return axios.post(Url, { ...user });
};

export const userLogin = (user, rememberMe) => {
  const Url = '/admin/login';
  return (dispatch) => {
    dispatch(LoginStart());
    return axios
      .post(Url, { ...user })
      .then((res) => res.data)
      .then((data) => {
        console.log("login data ", data);
        if (data.success) {
          AuthService.saveToken(data.result, rememberMe);

          dispatch(LoginSuccess(data.result));
        } else {
          dispatch(LoginFailure(data));
        }
      })
      .catch((err) => dispatch(LoginFailure(err)));
  };
};

export const checkUserData = (onFinally) => (dispatch) => {
  if (AuthService.isAuthenticated()) {
    return axiosInstance
      .get('/me')
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          AuthService.saveToken(data.result.token);
          dispatch(LoginSuccess(data.result));
        }
      })
      .catch((err) => {})
      .finally(() => onFinally());
  }
  onFinally();
};

export const clearLoggedIn = () => ({
  type: UserActionTypes.SIGN_OUT,
});

export const signOut = () => {
  AuthService.invalidateUser();

  return (dispatch) => dispatch(clearLoggedIn());
};

export const forgetPassword = (email) => {
  const Url = '/forgotpassword';
  return axios.post(Url, { ...email });
};

export const resendVerifiation = (email) => {
  const Url = '/resendVerification';
  return axios.post(Url, { ...email });
};

export const getUsers = () => (dispatch) => {
  dispatch({ type: UserActionTypes.GET_USERS_START });

  return axiosInstance
    .get('/user')
    .then((res) => res.data)
    .then((data) => {
      if (data.success) {
        return dispatch({
          type: UserActionTypes.GET_USERS_SUCCESS,
          payload: data.result,
        });
      }
      return dispatch({
        type: UserActionTypes.GET_USERS_FAILURE,
        payload: data.error,
      });
    })
    .catch((errors) => dispatch({
      type: UserActionTypes.GET_USERS_FAILURE,
      payload: errors.message,
    }));
};

export const addUser = (data) => (dispatch) => axiosInstance.post('/user/', { ...data });

export const updateUser = (id, data) => (dispatch) => axiosInstance.put(`/user/${id}`, { ...data });

export const deleteUser = (id) => (dispatch) => axiosInstance.delete(`/user/${id}`);

export const toggleLock = (uid) => (dispatch) => axiosInstance.patch(`/user/${uid}/toggleLock`);

export const validateToken = (cb) => (dispatch) => {
  const hasRememberMeChecked = AuthService.getRememberMe();
  if (!hasRememberMeChecked) {
    const user = AuthService.getTempUser();
    if (!user) {
      dispatch(signOut());
    }
    if (user) {
      dispatch(LoginSuccess(user));
    }

    return cb();
  }

  return getUserLocal()
    .then((user) => {
      if (user) {
        const lastLoggedIn = user.time;
        const more29Days = 1000 * 60 * 60 * 24 * 29;
        const after29Days = lastLoggedIn + more29Days;
        const today = Date.now();

        if (after29Days > today) {
          dispatch(LoginSuccess(user));
        } else {
          dispatch(signOut());
        }
      }
    })
    .catch((err) => {
      dispatch(signOut());
    })
    .finally(() => cb());
};
