import React from "react";
import { Link, useHistory } from "react-router-dom";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import colors from "utils/color";
import Logo from "components/Logo";
import AuthService from "services/auth-service";

import "./header.css";
import { useDispatch } from "react-redux";

const styles = {
  navBar: {
    background: colors.primaryColor,
    padding: "0 15px",
    minHeight: "65px",
  },
};

export function Header({ signOut = "", userRole }) {
  const history = useHistory();

  const signOutAdmin = () => {
    AuthService.invalidateUser();
    window.location.reload(); 
  }
  
  const dispatch = useDispatch();
  return (
    <Navbar
      className="nav-bar"
      collapseOnSelect
      expand="lg"
      style={styles.navBar}
      variant="light"
    >
      <Link to="/" className="logo">
        <Logo
          height="45px"
          style={{
            height: "45px",
            width: "110px",
          }}
        />
      </Link>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
     
      <Navbar.Collapse
        id="responsive-navbar-nav"
        style={{ justifyContent: "flex-end" }}
      >
        <button onClick={signOutAdmin} className="nav-links">
          Sign Out
        </button>
      </Navbar.Collapse>
    </Navbar>
  );
}
