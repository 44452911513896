import React from 'react'

function NoData() {
    return (
        <div style={{ marginTop: '10rem', width: '100%', display: 'flex', justifyContent: 'center' }}>
            <p className='font-semibold'>
                No Record is available
            </p>
        </div>
    )
}

export default NoData
