import React from "react";
import { Link, useHistory } from "react-router-dom";

import "../MainComponent/Maincomponent.scss";

const SideBar = () => {
  const links = [
    {
      name: "OR Subscribers & Billing",
      link: "/billing",
    },
    {
      name: "State And County Sales Tax",
      link: "/state-and-county-tax",
    },
    {
      name: "New Lead",
      link: "/newleads",
    },
    {
      name: "Subscription / Users",
      link: "/subscription",
    },
    {
      name: "Proposal Text & Templates",
      link: "/proposal-text",
    },
    {
      name: "Supplier Personnel Position",
      link: "/supplier-personnel",
    },
    {
      name: "Material Pricing",
      link: "/material-pricing",
    },
    {
      name: "Sub/Crew Pricing",
      link: "/sub-crew-pricing",
    },
    {
      name: "Carting Pricing",
      link: "/carting-pricing",
    },
    {
      name: "Scheduling Modals",
      link: "/scheduling-modal",
    },
    {
      name: "Notification",
      link: "/notification",
    },
    {
      name: "Help",
      link: "/help",
    },
    {
      name: "Client Email Template",
      link: "/clientEmailTemplate",
    },
  ];
  const {
    location: { pathname },
  } = useHistory();

  return (
    <div>
      <ul>
        {links.map((link) => (
          <li key={link.name}>
            <Link
              className={pathname === link.link ? "active" : ""}
              to={link.link}
            >
              <span>{link.name}</span>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SideBar;
