import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const CommonCartingseries = (props) => {
  const {
    setAddNewSeries,
    _id = null,
    pricingTypes,
    sizes,
    addNewSeries,
    saveData,
    deleteData,
    category,
    pricePerUom,
    editData = false,
    setAdd,
  } = props;

  const [pricingType, setCartingType] = useState(pricingTypes);
  const [size, setSize] = useState(sizes);
  // const [uomPriceData, setPricePerUomData] = useState(pricePerUom);

  const [pricePerUOM, setPricePerUom] = useState(pricePerUom);

  const [edit, setEdit] = useState(editData);

  const [isDelete, setIsDelete] = useState(false);

  function toggle() {
    setIsDelete(!isDelete);
  }
  // useEffect(() => {
  //   if (uomPriceData) {
  //     setPricePerUom(uomPriceData?.toString().slice(1).replace(".00", ""));
  //   }
  // }, [uomPriceData]);

  const deleteModal = () => {
    return (
      <Modal isOpen={isDelete} toggle={toggle} className={props.className}>
        <ModalHeader toggle={toggle}>Are you sure to delete it?</ModalHeader>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              deleteData(_id);
              toggle();
            }}
          >
            Delete
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  const handleChange = (e, callback) => {
    callback(e.target.value);
  };

  const handleOnClickSave = () => {
    setAddNewSeries(false);
    setEdit(!edit);
    saveData({
      category,
      pricePerUOM,
      size,
      pricingType,
      _id,
    });
  };

  const handleOnClickCancel = () => {
    setAdd(false);
    setEdit(!edit);
  };

  return (
    <>
      {isDelete && deleteModal()}

      <Row style={{ marginBottom: "15px" }}>
        <Col md="3">
          <Form.Group>
            <Form.Control
              as="select"
              name="pType"
              value={pricingType}
              disabled={!edit}
              onChange={(event) => handleChange(event, setCartingType)}
              style={{ textAlign: "center", fontSize: "0.8rem" }}
            >
              <option value="">Choose...</option>
              <option value="Dumpsters">Dumpsters</option>
              <option value="Trucks">Trucks</option>
            </Form.Control>
          </Form.Group>
        </Col>

        <Col md="3">
          <Form.Group>
            <Form.Control
              type="number"
              name="pType"
              value={size}
              disabled={!edit}
              onChange={(event) => handleChange(event, setSize)}
              style={{ textAlign: "center", fontSize: "0.8rem" }}
            ></Form.Control>
          </Form.Group>
        </Col>

        <Col md="3">
          <Form.Group>
            <Form.Control
              type="number"
              name="pType"
              value={pricePerUOM}
              disabled={!edit}
              onChange={(event) => handleChange(event, setPricePerUom)}
              style={{ textAlign: "center", fontSize: "0.8rem" }}
            ></Form.Control>
          </Form.Group>
        </Col>

        {addNewSeries ? (
          <Col md="2">
            <Row>
              <Col md="6">
                <button
                  className="btn btn-success"
                  onClick={handleOnClickSave}
                  style={{ fontSize: "0.8rem", width: "60px" }}
                >
                  Save
                </button>
              </Col>
              <Col md="6">
                <button
                  className="btn btn-danger"
                  onClick={() => setAddNewSeries(false)}
                >
                  Cancel
                </button>
              </Col>
            </Row>
          </Col>
        ) : (
          <Col md="2">
            <Row>
              <Col md="6">
                {!edit ? (
                  <button
                    className="btn btn-primary"
                    style={{ fontSize: "0.8rem", width: "60px" }}
                    onClick={() => setEdit(!edit)}
                  >
                    Edit
                  </button>
                ) : (
                  <button
                    className="btn btn-success"
                    style={{ fontSize: "0.8rem", width: "60px" }}
                    onClick={handleOnClickSave}
                    disabled={!pricePerUOM || !size || !pricingType}
                  >
                    Save
                  </button>
                )}
              </Col>
              {!edit && (
                <Col md="6">
                  <button
                    className="btn btn-danger"
                    style={{ fontSize: "0.8rem" }}
                    onClick={() => setIsDelete(!isDelete)}
                  >
                    Delete
                  </button>
                </Col>
              )}

              {edit && (
                <Col md="6">
                  <button
                    className="btn btn-danger"
                    style={{ fontSize: "0.8rem" }}
                    onClick={handleOnClickCancel}
                  >
                    Cancel
                  </button>
                </Col>
              )}
            </Row>
          </Col>
        )}
      </Row>
    </>
  );
};

export default CommonCartingseries;
