import {
  addManuftureOrSubType,
  addUpdateSeriesAPI,
  deleteManufactureCatagoryType,
  getAllMaterialOptionsData,
  getAllPreferedData,
  getAllSeriesDatas,
  getAllUomData,
  materialSeriesDelete,
} from "api/api";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  getCategoryTypes,
  getManufactures,
  getSeriesType,
  removeElenentInArray,
} from "utils/material";

const useAllMaterialData = () => {
  const [isLoadingSeries, setIsLoadingSeries] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [add, setAdd] = useState(false);

  const [addNewSeries, setAddNewSeries] = useState(false);
  const [allUomType, setAllUomType] = useState("");
  const [selectCatagory, setSelectCatagory] = useState("");
  const [selectManufacture, setSelectManufacture] = useState("");
  const [selectCatagoryType, setSelectCatagpoyType] = useState("");
  const [selectSeriesType, setSelectSeriesType] = useState("");

  const [pricing, setPricing] = useState([]);
  const [matchData, setMatchData] = useState(null);
  const [matchType, setMatchType] = useState(null);
  const [categoryList, setCategoryList] = useState([]);
  const [typesList, setTypesList] = useState([]);
  const [manufacturerList, setManufacturerList] = useState([]);
  const [subTypes, setSubTypes] = useState([]);
  const [subName, setSubName] = useState("");

  const [isMisc, setIsMisc] = useState(false);
  const [hasPreferedOption, setHasPreferedOption] = useState(false);
  const [multiPreferedOption, setMultiPreferedOption] = useState(false);

  const [materialAllData, setAllMaterialData] = useState([]);
  const [materialSeriesData, setAllSeriesData] = useState([]);

  const [uomUnit, setUomUnit] = useState(null);
  const [uomType, setUomType] = useState(null);
  const [allPreferedData, setAllPreferedData] = useState([]);
  const [allPrefered, setAllPrefered] = useState([]);
  const [allSeriesUom, setAllSeriesUom] = useState("");

  const [manufactur, setManufactur] = useState("");
  const [addManufacturer, setAddManufacture] = useState(false);

  const [cataGoryType, setCataGoryType] = useState("");
  const [addCatagoryTypee, setAddCatagoryType] = useState(false);
  const [seriesType, setSeriesType] = useState("");
  const [addSeriesTypes, setAddseriesType] = useState(false);
  const [showAddNew, setShowAddNew] = useState(false);
  const [isDelete, setIsDelete] = useState(false);

  useEffect(() => {
    if (materialAllData) {
      setPricing(materialAllData);
    }
  }, [materialAllData]);

  useEffect(() => {
    setAllSeriesData([]);
    setAllPrefered([]);

    // if category type will change fetch series
    if (selectCatagoryType) {
      let result = getManufactures(selectCatagory, selectCatagoryType, pricing);
      if (result && result.manufacturers && !result.manufacturers.length) {
        getAllSeriesData(
          selectCatagory,
          selectCatagoryType,
          setIsLoadingSeries
        );
        getAllUom(selectCatagory, selectCatagoryType, setIsLoadingSeries);
      }
    }

    // if selectManufacture type will change fetch series
    if (selectManufacture) {
      let result = getSeriesType(
        selectCatagory,
        selectCatagoryType,
        selectManufacture,
        pricing
      );

      if (
        result &&
        result.subTypes &&
        !result.subTypes.length &&
        selectCatagoryType !== "Roofing"
      ) {
        getAllSeriesData(
          selectCatagory,
          selectCatagoryType,
          selectManufacture,
          setIsLoadingSeries
        );
        getAllUom(
          selectCatagory,
          selectCatagoryType,
          selectManufacture,
          setIsLoadingSeries
        );
      }
    }

    // if selectSeriesType type will change fetch series
    if (selectSeriesType) {
      setAdd(false);
      getAllSeriesData(
        selectCatagory,
        selectCatagoryType,
        selectManufacture,
        selectSeriesType,
        setIsLoadingSeries
      );
      getAllUom(
        selectCatagory,
        selectCatagoryType,
        selectManufacture,
        selectSeriesType,
        setIsLoadingSeries
      );
    }
  }, [selectCatagoryType, selectSeriesType, selectManufacture]);

  // If category will change set subcategory
  useEffect(() => {
    resetOptions();

    if (selectCatagory) {
      if (selectCatagory === "Miscellaneous") {
        setIsMisc(true);
      } else {
        setIsMisc(false);
      }
      let result = getCategoryTypes(selectCatagory, pricing);
      setTypesList(result);
      setAdd(false);
    }
  }, [selectCatagory]);

  // If sub category will change set manufacturers
  useEffect(() => {
    setManufacturerList([]);

    if (selectCatagoryType) {
      getAllPrefereddata(selectCatagory, selectCatagoryType);

      let result = getManufactures(selectCatagory, selectCatagoryType, pricing);
      if (result && result.manufacturers) {
        setManufacturerList(result.manufacturers);
        setSubName(result.subTypeName);
      }
      setAdd(false);
    }
  }, [selectCatagoryType]);

  // If sub manufacturers will change set subtypes/series types
  useEffect(() => {
    setSubTypes([]);

    if (selectManufacture) {
      let result = getSeriesType(
        selectCatagory,
        selectCatagoryType,
        selectManufacture,
        pricing
      );

      if (result) {
        let { subTypes } = result;
        setSubTypes(subTypes);
      }
      setAdd(false);
    }
  }, [selectManufacture]);

  useEffect(() => {
    const list = [];
    if (pricing?.length > 0) {
      pricing?.map((priceData) => list?.push(priceData.category));
    }
    setCategoryList(list);
  }, [pricing]);

  useEffect(() => {
    if (selectSeriesType) {
      getAllSeriesData(
        selectCatagory,
        selectCatagoryType,
        selectManufacture,
        selectSeriesType,
        setIsLoadingSeries
      );
      getAllUom(
        selectCatagory,
        selectCatagoryType,
        selectManufacture,
        selectSeriesType,
        setIsLoadingSeries
      );
    }
  }, [selectSeriesType]);

  useEffect(() => {
    if (isMisc) {
      getAllSeriesData(selectCatagory, setIsLoadingSeries);
      getAllUom(selectCatagory, setIsLoadingSeries);
    }
  }, [isMisc]);

  useEffect(() => {
    getAllCatagoryOptions();
  }, []);

  const getAllCatagoryOptions = () => {
    getAllMaterialOptionsData("/admin/materialOld/getMaterialPricingOptions")
      .then((response) => {
        const { data: { result = [] } = {} } = response;
        setAllMaterialData(result);
      })
      .catch((error) => {
        if (error.response) {
          return { error: error.response };
        }
      });
  };

  const resetOptions = () => {
    setSubTypes([]);
    setManufacturerList([]);
    setAllSeriesData([]);
    setTypesList([]);
    setSelectCatagpoyType("");
    setSelectManufacture("");
    setSelectSeriesType("");
    setAllPreferedData([]);
  };

  const addUpdateSeries = (setIsLoadingSeries) => {
    setIsLoadingSeries(true);
    let body = {
      series: JSON.stringify(materialSeriesData),
    };
    addUpdateSeriesAPI("/admin/materialOld/addSeriesToMasterInAdmin", body)
      .then((res) => {
        setIsLoadingSeries(false);
      })
      .catch((error) => {
        setIsLoadingSeries(false);
      });
  };

  /**
   * add new manufacture
   */

  const addManufacture = (selectCatagory, selectCatagoryType) => {
    // setIsLoading(true);
    const body = {
      category: selectCatagory,
      categoryType: selectCatagoryType,
      manufacturer: manufactur,
    };
    setAddManufacture(!addManufacturer);
    setManufactur("");
    addManuftureOrSubType("/admin/materialOld/addToMaterialOption", body)
      .then((response) => {
        const { data: { result = [] } = {} } = response;
        // getAllCatagoryOptions();
        getAllCatagoryOptions();
        setManufacturerList((preList) => [...preList, manufactur]);
        // setIsLoading(false);
      })
      .catch((error) => {
        // setIsLoading(false);

        if (error.response) {
          return { error: error.response };
        }
      });
  };

  /**
   * add new series type
   */

  const addSeriesType = (body) => {
    // setIsLoading(true);

    setAddseriesType(!addSeriesTypes);
    setSeriesType("");
    addManuftureOrSubType("/admin/materialOld/addToMaterialOption", body)
      .then((response) => {
        const { data: { result = [] } = {} } = response;
        // setIsLoading(false);
        getAllCatagoryOptions();

        setSubTypes((prevList) => [...prevList, seriesType]);
      })
      .catch((error) => {
        // setIsLoading(false);

        if (error.response) {
          return { error: error.response };
        }
      });
  };

  /**
   * delete new manufacture
   */

  const deleteManufacture = () => {
    // setIsLoading(true);
    // setIsDelete(terue);
    setSelectManufacture("");
    const body = {
      category: selectCatagory,
      categoryType: selectCatagoryType,
      manufacturer: selectManufacture,
    };
    setAddManufacture(addManufacturer);
    setManufactur("");
    // setSelectManufacture(!selectManufacture);

    deleteManufactureCatagoryType(
      "/admin/materialOld/deleteMaterialPricingOptions",
      body
    )
      .then((response) => {
        const { data: { result = [] } = {} } = response;
        // setIsLoading(false);
        getAllCatagoryOptions();

        let newManufactureres = removeElenentInArray(
          selectManufacture,
          manufacturerList
        );
        setManufacturerList(newManufactureres);
      })
      .catch((error) => {
        // setIsLoading(false);

        if (error.response) {
          return { error: error.response };
        }
      });
  };

  /**
   * delete new series type
   */

  const deleteSeriesType = (body) => {
    // setIsLoading(true);
    setSelectSeriesType("");

    setAddseriesType(addSeriesTypes);
    setSelectSeriesType(!selectSeriesType);
    deleteManufactureCatagoryType(
      "/admin/materialOld/deleteMaterialPricingOptions",
      body
    )
      .then((response) => {
        const { data: { result = [] } = {} } = response;
        // setIsLoading(false);
        getAllCatagoryOptions();

        let newSerieses = removeElenentInArray(selectSeriesType, subTypes);
        setSubTypes(newSerieses);
      })
      .catch((error) => {
        // setIsLoading(false);

        if (error.response) {
          return { error: error.response };
        }
      });
  };

  /**
   * delete new Catagory type
   */

  const deleteCatagoryType = () => {
    // setIsLoading(true);
    setSelectCatagpoyType("");
    const body = {
      category: selectCatagory,
      categoryType: selectCatagoryType,
    };
    setAddCatagoryType(addCatagoryTypee);
    // setSelectCatagpoyType(!selectCatagoryType);
    deleteManufactureCatagoryType(
      "/admin/materialOld/deleteMaterialPricingOptions",
      body
    )
      .then((response) => {
        const { data: { result = [] } = {} } = response;
        // setIsLoading(false);
        getAllCatagoryOptions();

        let newCatTypes = removeElenentInArray(selectCatagoryType, typesList);
        setTypesList(newCatTypes);
      })
      .catch((error) => {
        // setIsLoading(false);

        if (error.response) {
          return { error: error.response };
        }
      });
  };

  const showOptions = (list) =>
    list.length > 0 &&
    list.map((data) => (
      <option value={data} key={data}>
        {data}
      </option>
    ));

  const handleChange = (e, callback, type) => {
    callback(e.target.value);
    if (type === "ctype") {
      setSelectManufacture("");
      setSelectSeriesType("");
    }

    if (type === "manufacturer") {
      setSelectSeriesType("");
    }
  };

  const PreferedValue = (type = "cat") => {
    let allPreferedDatas = type === "cat" ? allPreferedData : allPrefered;
    if (allPreferedDatas && allPreferedDatas.at.length > 0) {
      return (
        <>
          {allPreferedDatas.length > 0 &&
            allPreferedDatas.map((preferredPricing) => (
              <p
                className="__msg"
                key={preferredPricing._id}
                style={{ fontWeight: "bold" }}
              >
                Preferred : {preferredPricing.manufacturer} -
                {preferredPricing.series}
              </p>
            ))}
        </>
      );
    }
  };

  const saveData = (data) => {
    setAllSeriesData([...materialSeriesData, data]);
    setAdd(false);
  };

  const editData = (data) => {
    const idx = materialSeriesData.findIndex((list) => list._id === data._id);
    materialSeriesData.splice(idx, 1, data);
    setAllSeriesData([...materialSeriesData]);
    setAdd(false);
  };

  const deleteData = (_id) => {
    delteMaterialSeries(_id);
    const idx = materialSeriesData.findIndex((list) => list._id === _id);
    materialSeriesData.splice(idx, 1);
    setAllSeriesData([...materialSeriesData]);
  };

  const handleOnAddNew = () => {
    setAdd(true);
  };
  const showCatagoryTypeBtn = () => {
    if (
      selectCatagory === "Drip Edging" ||
      selectCatagory === "Flashings" ||
      selectCatagory === "Fascia" ||
      selectCatagory === "Wood" ||
      selectCatagory === "Nails"
    ) {
      return true;
    } else return false;
  };

  /**
   * get all the prefered data acording to catagory and catagory type
   */

  const getAllPrefereddata = (selectCatagory, selectCatagoryType) => {
    const body = {
      q: {
        category: selectCatagory,
        categoryType: selectCatagoryType,
      },
    };

    getAllPreferedData("/admin/materialOld/preferredOldMaterial", body)
      .then((response) => {
        const { data: { result = [] } = {} } = response;
        setAllPreferedData(result?.materialPreferred);
      })
      .catch((error) => {
        if (error.response) {
          return { error: error.response };
        }
      });
  };

  /**
   * add new Catagory type
   */

  const addCatagoryType = (selectCatagory) => {
    // setIsLoading(true);
    const body = {
      category: selectCatagory,
      categoryType: cataGoryType,
    };
    setAddCatagoryType(!addCatagoryTypee);
    setCataGoryType("");
    addManuftureOrSubType("/admin/materialOld/addToMaterialOption", body)
      .then((response) => {
        const { data: { result = [] } = {} } = response;
        getAllCatagoryOptions();
        setTypesList((prevList) => [...prevList, cataGoryType]);
        // setIsLoading(false);
      })
      .catch((error) => {
        // setIsLoading(false);

        if (error.response) {
          return { error: error.response };
        }
      });
  };

  /*
   *
   * get all series data according to catagory ,catagory type ,manufacture ,series type
   */

  const getAllSeriesData = (
    selectCatagory,
    selectCatagoryType,
    selectManufacture,
    selectSeriesType
  ) => {
    const body = {
      q: {
        category: selectCatagory,
        categoryType: selectCatagoryType,
        subType: selectSeriesType,
        manufacturer: selectManufacture,
      },
    };
    setIsLoadingSeries(true);
    setShowAddNew(true);
    getAllSeriesDatas("/admin/materialOld/getAllMaterial", body)
      .then((response) => {
        const { data: { result = [] } = {} } = response;
        setAllSeriesData(result?.materialPricing);
        setAllPreferedData([]);
        setAllPrefered(result?.materialPreferred);
        setIsLoadingSeries(false);
        if (result?.materialPricing && result?.materialPricing?.length) {
          setUomUnit(result.materialPricing[0].uom);
          setUomType(result.materialPricing[0].uomType);
        }
      })
      .catch((error) => {
        if (error.response) {
          setIsLoadingSeries(false);

          return { error: error.response };
        }
      });
  };
  /*
   *
   * get all uom for catagory ,catagory type ,manufacture ,series type
   */

  const getAllUom = (
    selectCatagory,
    selectCatagoryType,
    selectManufacture,
    selectSeriesType
  ) => {
    const body = {
      q: {
        category: selectCatagory,
        categoryType: selectCatagoryType,
        subType: selectSeriesType,
        manufacturer: selectManufacture,
      },
    };
    getAllUomData("/admin/materialOld/getUom", body)
      .then((response) => {
        const { data: { result = [] } = {} } = response;
        setAllSeriesUom(result.uom);
        setAllUomType(result.uomType);
      })
      .catch((error) => {
        if (error.response) {
          return { error: error.response };
        }
      });
  };

  /*
   *
   * delete the selected series in material pricing
   */

  const delteMaterialSeries = (id) => {
    setIsLoadingSeries(true);
    materialSeriesDelete("/admin/materialOld/deleteAdminOldMaterial/", id)
      .then((response) => {
        setIsLoadingSeries(false);

        const { data: { result = [] } = {} } = response;
      })
      .catch((error) => {
        setIsLoadingSeries(false);

        if (error.response) {
          return { error: error.response };
        }
      });
  };

  return {
    showOptions,
    categoryList,
    typesList,
    manufacturerList,
    subTypes,
    setSelectCatagory,
    setSelectManufacture,
    setSelectCatagpoyType,
    setSelectSeriesType,
    selectCatagory,
    selectCatagoryType,
    selectManufacture,
    selectSeriesType,
    handleChange,
    subName,
    materialSeriesData,
    isLoadingSeries,
    setIsLoadingSeries,
    setAllSeriesData,
    uomUnit,
    uomType,
    addUpdateSeries,
    allPreferedData,
    addCatagoryType,
    addManufacture,
    PreferedValue,
    isLoading,
    setAddManufacture,
    addManufacturer,
    manufactur,
    setManufactur,
    setAddCatagoryType,
    addCatagoryTypee,
    setCataGoryType,
    cataGoryType,
    setShowAddNew,
    showAddNew,
    saveData,
    editData,
    deleteData,
    handleOnAddNew,
    setAdd,
    add,
    addNewSeries,
    setAddNewSeries,
    allPrefered,
    setAllPrefered,
    setAllMaterialData,
    addSeriesType,
    seriesType,
    setSeriesType,
    addSeriesTypes,
    setAddseriesType,
    deleteManufacture,
    deleteSeriesType,
    deleteCatagoryType,
    showCatagoryTypeBtn,
    isDelete,
    setIsDelete,
    allSeriesUom,
    allUomType,
    setIsMisc,
  };
};

export default useAllMaterialData;
