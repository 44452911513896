import React, { useEffect, useState } from "react";
import { Container, Button, Form, Row, Col, Alert } from "react-bootstrap";
import Logo from "components/Logo";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import {
  userLogin,
  forgetPassword,
  resendVerifiation,
} from "redux/user/user.actions";
import { useDispatch, useSelector } from "react-redux";
import SweetAlert from "components/SweetAlert";
import validator from "validator";

const newStyle = {
  fullHt: {
    minHeight: "100vh",
  },
  loginContent: {
    margin: "auto",
    paddingBottom: "15px",
    paddingTop: "15px",
    background: "white",
    borderRadius: "5px",
    boxShadow: "0 0 10px #88888863",
  },
  formStyle: {
    marginTop: "5%",
  },
  formInput: {
    marginBottom: "15px",
  },
  forgotButton: {
    paddingLeft: "0px",
  },
};

function Login() {
  const { isLogging, errors, isAuthenticated } = useSelector(
    (state) => state.login
  );
  const dispatch = useDispatch();
  const [errorData, setErrorData] = useState({
    msgTxt: "",
    msgType: "primary",
  });
  const [resendVerificationDisplay, setResendVerificationDisplay] =
    useState(false);
  const [forgotPassDisplay, setForgotPassDisplay] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(true);

  useEffect(() => {
    setErrorData({
      msgTxt: errors,
      msgType: "danger",
    });
  }, [errors]);
  const handleChange = (event, setValue) => {
    setValue(event.target.value);
  };
  const changeState = (setValue, value) => setValue(value);

  const changeRememberMe = (value) => changeState(setRememberMe, value);
  const changeForgotPassDisplay = (value) =>
    changeState(setForgotPassDisplay, value);
  const changeResendVerificationDisplay = (value) =>
    changeState(setResendVerificationDisplay, value);

  const onConfirmForgetPass = (response) => submitForgotPass(response);
  const onConfirmResendVerification = (response) =>
    submitResendVerification(response);

  const hideForgetPass = () => changeForgotPassDisplay(false);
  const hideResendVerification = () => changeResendVerificationDisplay(false);
  const displayResendVerification = () => changeResendVerificationDisplay(true);
  const displayForgetPass = () => changeForgotPassDisplay(true);
  const onChangeRemeberMe = (event) => {
    const value = event.target.checked;
    sessionStorage.setItem("rememberme", value);
    changeRememberMe(value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(userLogin({ email, password }, rememberMe));
  };

  const submitForgotPass = (email) => {
    changeForgotPassDisplay(false);

    return forgetPassword({ email })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          toast.success(data.result.message);
        } else {
          toast.error(data.error);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const submitResendVerification = (email) => {
    changeResendVerificationDisplay(false);

    return resendVerifiation({ email })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          toast.success(data.result.message);
        } else {
          toast.error(data.error);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const forgetPasswordModal = SweetAlert.bind(this, {
    children: "Please enter your email address here.",
    title: "Forgot password!",
    placeHolder: "Your email address.",
    autoComplete: "on",
    onConfirm: onConfirmForgetPass,
    onCancel: hideForgetPass,
    inputType: "email",
  });

  const resendVerificationModal = SweetAlert.bind(this, {
    children: "Please enter your email address here.",
    title: "Re-send Email Verification!",
    placeHolder: "Your email address.",
    autoComplete: "on",
    onConfirm: onConfirmResendVerification,
    onCancel: hideResendVerification,
    inputType: "email",
  });

  return (
    <Container>
      {forgotPassDisplay && forgetPasswordModal()}
      {resendVerificationDisplay && resendVerificationModal()}

      <Row className="justify-content-md-center" style={newStyle.fullHt}>
        <Col xs="12" md="6" lg="4" style={newStyle.loginContent}>
          <Logo />
          <Form onSubmit={onSubmit} style={newStyle.formStyle}>
            {errorData.msgTxt && (
              <Alert
                variant={errorData.msgType}
                style={
                  errorData.msgTxt ===
                  "This account has been locked. Please contact your subscription admin."
                    ? { fontWeight: "bold", color: "black" }
                    : {}
                }
              >
                {errorData.msgTxt}
              </Alert>
            )}
            <Form.Group style={newStyle.formInput}>
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                name="email"
                placeholder="Enter email"
                required="required"
                autoComplete="on"
                onChange={(event) => handleChange(event, setEmail)}
              />
            </Form.Group>

            <Form.Group style={newStyle.formInput}>
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                name="password"
                required="required"
                autoComplete="on"
                onChange={(event) => handleChange(event, setPassword)}
              />
            </Form.Group>

            <Form.Group style={newStyle.formInput}>
              <Form.Check
                type="checkbox"
                label="Remember Me."
                checked={rememberMe}
                name="rememberMe"
                onChange={onChangeRemeberMe}
              />
            </Form.Group>

            <Form.Group style={newStyle.formInput}>
              <Button
                type="submit"
                variant="success"
                disabled={
                  isLogging || !email || !password || !validator.isEmail(email)
                }
              >
                {isLogging ? "Logging In..." : "Submit"}
              </Button>
            </Form.Group>

            <div>
              <Button
                id="forgetPass"
                variant="link"
                style={newStyle.forgotButton}
                onClick={displayForgetPass}
              >
                Forgot Password?
              </Button>
            </div>

            {/* <div>
              <Button
                id="reSend"
                variant="link"
                style={newStyle.forgotButton}
                onClick={displayResendVerification}
              >
                Re-Send Verification to Email?
              </Button>
            </div>

            <div style={newStyle.formInput}>
              <Button id="signUp" variant="link" style={newStyle.forgotButton}>
                <Link to="/signup">Don't have an account? Sign up here.</Link>
              </Button>
            </div> */}
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default Login;
