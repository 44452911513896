import {
  addUpdateCartingSeries,
  deleteSeriesCarting,
  getAllSeriesCarting,
} from "api/api";
import { useEffect } from "react";
import { useState } from "react";

const useAllCartingData = () => {
  const [isLoadingSeries, setIsLoadingSeries] = useState(false);
  const [add, setAdd] = useState(false);
  const [addNewSeries, setAddNewSeries] = useState(false);
  const [selectCatagory, setSelectCatagory] = useState("");
  const [cartingSeriesData, setAllSeriesData] = useState([]);
  const [showAddNew, setShowAddNew] = useState(false);

  /**
   *Start All Api Call
   * All Apis
   *
   */

  /*
   *
   * get all series data of carting pricing
   */

  const getAllSeriesData = (data) => {
    const body = {
      q: data,
    };
    setIsLoadingSeries(true);
    setShowAddNew(true);
    getAllSeriesCarting("/admin/cartingOld/", body)
      .then((response) => {
        const { data: { result = [] } = {} } = response;
        setAllSeriesData(result);
        setIsLoadingSeries(false);
      })
      .catch((error) => {
        if (error.response) {
          setIsLoadingSeries(false);

          return { error: error.response };
        }
      });
  };

  /*
   *
   * delete the selected series in Carting pricing
   */

  const delteMaterialSeries = (id) => {
    setIsLoadingSeries(true);

    deleteSeriesCarting("/admin/cartingOld/deleteCartingSeries/", id)
      .then((response) => {
        setIsLoadingSeries(false);

        const { data: { result = [] } = {} } = response;
      })
      .catch((error) => {
        setIsLoadingSeries(false);

        if (error.response) {
          return { error: error.response };
        }
      });
  };

  /*
   *
   * add and update all series in Carting
   */
  const addUpdateSeries = (setIsLoadingSeries) => {
    setIsLoadingSeries(true);
    let body = {
      series: JSON.stringify(cartingSeriesData),
    };
    addUpdateCartingSeries("/admin/cartingOld/addUpdateCarting", body)
      .then((res) => {
        setIsLoadingSeries(false);
      })
      .catch((error) => {
        setIsLoadingSeries(false);
      });
  };

  /**
   *End All Api Call
   * All Apis
   *
   */

  useEffect(() => {
    if (selectCatagory) {
      getAllSeriesData({
        category: selectCatagory,
      });
    }
  }, [selectCatagory]);

  const handleChange = (e, callback) => {
    callback(e.target.value);
  };

  const saveData = (data) => {
    setAllSeriesData([...cartingSeriesData, data]);
    setAdd(false);
  };

  const editData = (data) => {
    setAdd(false);
    const idx = cartingSeriesData.findIndex((list) => list._id === data._id);
    cartingSeriesData.splice(idx, 1, data);
    setAllSeriesData([...cartingSeriesData]);
  };

  const deleteData = (_id) => {
    delteMaterialSeries(_id);

    const idx = cartingSeriesData.findIndex((list) => list._id === _id);
    cartingSeriesData.splice(idx, 1);
    setAllSeriesData([...cartingSeriesData]);
  };

  const handleOnAddNew = () => {
    setAdd(!add);
  };

  return {
    setSelectCatagory,
    selectCatagory,
    handleChange,
    cartingSeriesData,
    isLoadingSeries,
    setIsLoadingSeries,
    setAllSeriesData,
    setShowAddNew,
    showAddNew,
    delteMaterialSeries,
    saveData,
    editData,
    deleteData,
    handleOnAddNew,
    setAdd,
    add,
    addNewSeries,
    setAddNewSeries,
    addUpdateSeries,
  };
};

export default useAllCartingData;
