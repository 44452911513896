import axios from 'axios';
import AxiosInstance from 'services/axios-instance';
import AxiosInstanceForm from 'services/axios-instance-formdata';
import settings from 'config/baseSettings';

const axiosInstanceForm = AxiosInstanceForm.getInstance();
const axiosInstance = AxiosInstance.getInstance();
const axiosConfig = {
	baseURL: settings.baseUrl,
	timeout: settings.axiosTimeout,
	timeoutErrorMessage: 'Sorry! Unable to perform operation. Please try again.',
};

const axiosNoAuth = axios.create(axiosConfig);

export { axiosNoAuth as axios, axiosInstance, axiosInstanceForm };
