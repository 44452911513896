import useBilling from "customHooks/OrSubscriberAndBillingsHook/useBilling";
import React, { useEffect, useState } from "react";
import TableAdmin from "./TableAdmin";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import InitialLoader from "components/InitilalLoader";
import NoData from "components/NoData/NoData";

const SubscriberTable = () => {
  const [product, setProduct] = useState();
  const [gotNotes, setGotNotes] = useState(false);
  const [updateStatus, setUpdateStatus] = useState(false);
  const { isLoading, data, getSubscriberData, isError } = useBilling();
  const [isLocalLoading, setIsLocalLoading] = useState(false);
  // console.log({data})

  useEffect(() => {
    getSubscribers();
  }, [updateStatus]);

  const getSubscribers = async () => {
    setIsLocalLoading(true);
    let data = await getSubscriberData();
    console.log({data});
    setProduct(data);
    setIsLocalLoading(false);
  };

  if (isLoading || isLocalLoading) {
    return <InitialLoader error={isError} />;
  }

  return (
    <>
      {(product && product.length) ? (
        <TableAdmin
          products={product}
          setGotNotes={setGotNotes}
          gotNotes={gotNotes}
          setUpdateStatus={setUpdateStatus}
          updateStatus={updateStatus}
        />
      ) : (
        <NoData />
      )}
    </>
  );
};

export default SubscriberTable;
