import React, { useState, useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { YearSelector } from "./components/YearSelector";
import { axiosInstance } from "services";

const EconomyNexus = () => {
  const QUARTERS = [
    { id: 0, name: "Year" },
    { id: 1, name: "Qrt 1" },
    { id: 2, name: "Qrt 2" },
    { id: 3, name: "Qrt 3" },
    { id: 4, name: "Qrt 4" },
  ];

  const [year, setYear] = useState(new Date().getFullYear());
  const [quarter, setQuarter] = useState(QUARTERS[0].id);
  const [totalSales, setTotalSales] = useState(0);
  const [totalTransaction, setTotalTransaction] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      const response = await axiosInstance.post(
        "admin/stateAndCountyTax/economyNexus",
        {
          year,
          quarterId: quarter,
        }
      );

      if (response.data.success && response.status) {
        const result = response.data.result;
        setTotalSales(result.sales);
        setTotalTransaction(result.transactions);
      } else {
        console.error("economy nexus failed", response.data?.message);
      }
      setIsLoading(false);
    };

    if (quarter || (quarter === 0 && year)) {
      try {
        loadData();
      } catch (err) {
        console.log("economy nexus failed!", err);
        setIsLoading(false);
      }
    }
  }, [quarter, year]);
  const showOptions = (list) =>
    list.map((data) => (
      <option
        style={{ textTransform: "capitalize" }}
        value={data.id}
        key={data.id}
      >
        {data.name}
      </option>
    ));

  return (
    <div style={{ padding: "1rem" }}>
      <h3>Economy Nexus</h3>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <YearSelector value={year} onChange={(data) => setYear(data)} />
        <Form.Group>
          <Form.Label style={{ margin: 0 }}>{"Select Quarter"}</Form.Label>
          <Form.Control
            as="select"
            name={"Select Quarter"}
            value={quarter}
            onChange={(e) => {
              setQuarter(e.target.value);
            }}
          >
            <option value="">Choose...</option>
            {showOptions(QUARTERS)}
          </Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Label style={{ margin: 0 }}>{"Total Sales"}</Form.Label>
          <Form.Control
            type="text"
            disabled={true}
            value={parseFloat(totalSales).toFixed(2)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label style={{ margin: 0 }}>{"Total Transaction"}</Form.Label>
          <Form.Control type="text" disabled={true} value={totalTransaction} />
        </Form.Group>
        {isLoading && (
          <div
            style={{
              marginTop: "1rem",
              marginLeft: "10px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "4px solid #0051ff",
              borderTopColor: "rgba(172, 172, 172, 0.192)",
              animation: "spinner 0.8s infinite linear",
            }}
          />
        )}
      </div>
    </div>
  );
};

export default EconomyNexus;
