import {
  AddPermisions,
  AddScreen,
  AddUserRoles,
  AdminPermisions,
  basePlanUser,
  getAllAdminPermisions,
  getAllOnBoardingCharge,
  getAllPermisions,
  getAllPermisionsDelete,
  getAllPlans,
  getAllScreen,
  getAllScreenDelete,
  getAllUserRoles,
  getAllUserRolesDelete,
  update_onboarding_charge,
} from "api/api";
import InitialLoader from "components/InitilalLoader";
import MainComponent from "components/MainComponent";
import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { toast } from "react-toastify";

import "./Subscription.scss";
import MangeTaxes from "./StateTax";
const Subscription = () => {
  const [additionalUser, setAdditionalUser] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [editBasePlan, setEditBasePlan] = useState("");
  const [basePlan, setBasePlan] = useState("");
  const [oneTimeCharge, setOneTimeCharge] = useState(0);
  const [allBasePlan, setAllBasePlan] = useState([]);
  const [pricePerAdditionalUser, setPricePerAdditionalUser] = useState("");
  const [adminPermission, setAdminPermission] = useState([]);
  const [permissionId, setPermissionId] = useState([]);
  const [onBoardingCharge, setOnBoardingCharge] = useState();
  const [editOnBoarding, setEditOnBoarding] = useState(false);
  const [taxes, setTaxes] = useState([]);

  /**
   * user role state
   */
  const [selectUserRole, setSelectUserRole] = useState("");
  const [addUserRoles, setAddUserRoles] = useState("");
  const [userRole, setUserRole] = useState("");
  const [allUserRole, setAllUserRole] = useState([]);

  /**
   *user permisions state
   */
  const [selectPermision, setSelectPermision] = useState("");
  const [addPermision, setAddPermision] = useState("");
  const [permision, setPermision] = useState("");
  const [allPermision, setAllPermision] = useState([]);

  /**
   *user screen state
   */
  const [selectScreen, setSelectScreen] = useState("");
  const [addScreen, setAddScreen] = useState("");
  const [screen, setScreen] = useState("");
  const [allScreen, setAllScreen] = useState([]);

  useEffect(() => {
    setAdditionalUser(allBasePlan?.baseplanUsers);
    setBasePlan(allBasePlan?.baseplanPrice);
    setOneTimeCharge(allBasePlan?.oneTimeSetipPrice);
    setPricePerAdditionalUser(allBasePlan?.pricePerAdditionalUser);
  }, [allBasePlan]);

  useEffect(() => {
    getAllPlans("/admin/baseplanuser")
      .then((response) => {
        setAllBasePlan(response?.data?.result);
        console.log("response", response);
        setIsLoading(false);
        return { error: null, response };
      })
      .catch((error) => {
        setIsLoading(false);

        if (error.response) {
          return { error: error.response };
        }
      });
  }, []);

  useEffect(() => {
    getAllOnBoardingCharge("/admin/baseplanuser/getOnBoardingCharge")
      .then((response) => {
        console.log("responseOnBoarding", response);

        setOnBoardingCharge(response?.data?.result);
        setIsLoading(false);
        return { error: null, response };
      })
      .catch((error) => {
        setIsLoading(false);
        console.log({ error });
        if (error.response) {
          return { error: error.response };
        }
      });
  }, []);

  const handleOnClickSave = () => {
    setIsLoading(true);
    const body = {
      // companyId: "0",
      baseplanUsers: additionalUser,
      baseplanPrice: basePlan,
      pricePerAdditionalUser: +pricePerAdditionalUser,
      oneTimeSetipPrice: oneTimeCharge,
    };
    setEditBasePlan(!editBasePlan);
    basePlanUser("/admin/baseplanuser", body)
      .then((response) => {
        setIsLoading(false);
        return { error: null, response };
      })
      .catch((error) => {
        setIsLoading(false);

        if (error.response) {
          return { error: error.response };
        }
      });
  };

  /**
   * user role
   */
  const handleOnClickSaveUserRole = () => {
    setIsLoading(true);
    const body = {
      roleName: userRole,
    };
    setAddUserRoles(!addUserRoles);
    setUserRole("");
    AddUserRoles("/admin/permission/addrole", body)
      .then((response) => {
        setIsLoading(false);
        return { error: null, response };
      })
      .catch((error) => {
        setIsLoading(false);

        if (error.response) {
          return { error: error.response };
        }
      });
  };
  useEffect(() => {
    getAllUserRoles("/admin/permission/role")
      .then((response) => {
        setAllUserRole(response?.data?.result);

        return { error: null, response };
      })
      .catch((error) => {
        if (error.response) {
          return { error: error.response };
        }
      });
  }, [isLoading]);

  const handleOnClickDeleteUserRole = () => {
    setIsLoading(true);

    const body = {
      isDeleted: true,
    };
    setAddUserRoles(addUserRoles);
    setSelectUserRole(!selectUserRole);
    getAllUserRolesDelete("/admin/permission/deleterole/", selectUserRole, body)
      .then((response) => {
        setIsLoading(false);

        return { error: null, response };
      })
      .catch((error) => {
        setIsLoading(false);

        if (error.response) {
          return { error: error.response };
        }
      });
  };

  const displaypTypeOptionsUserRoles = () => {
    const userRoleAllData = allUserRole?.filter((elem) => {
      return elem.isDeleted !== true;
    });

    return userRoleAllData?.map((elem, id) => {
      return (
        <option key={id} value={elem._id}>
          {elem.roleName}
        </option>
      );
    });
  };

  /**
   *user permisions
   */
  const handleOnClickSavePemision = () => {
    setIsLoading(true);
    const body = {
      permissionName: permision,
    };
    setAddPermision(!addPermision);
    setPermision("");
    AddPermisions("/admin/permission/addpermission", body)
      .then((response) => {
        setIsLoading(false);
        return { error: null, response };
      })
      .catch((error) => {
        setIsLoading(false);

        if (error.response) {
          return { error: error.response };
        }
      });
  };
  useEffect(() => {
    getAllPermisions("/admin/permission/permission")
      .then((response) => {
        setAllPermision(response?.data?.result);

        return { error: null, response };
      })
      .catch((error) => {
        if (error.response) {
          return { error: error.response };
        }
      });
  }, [isLoading]);

  const handleOnClickDeletePermision = () => {
    setIsLoading(true);

    const body = {
      isDeleted: true,
    };
    setAddPermision(addPermision);
    setSelectPermision(!selectPermision);
    getAllPermisionsDelete(
      "/admin/permission/deletepermission/",
      selectPermision,
      body
    )
      .then((response) => {
        setIsLoading(false);

        console.log("response", response);
        return { error: null, response };
      })
      .catch((error) => {
        setIsLoading(false);

        if (error.response) {
          return { error: error.response };
        }
      });
  };

  const displaypTypeOptionsPermision = (allPermision) => {
    const PermisionAllData = allPermision?.filter((elem) => {
      return elem.isDeleted !== true;
    });

    return PermisionAllData?.map((elem, id) => {
      return (
        <option
          key={id}
          value={elem._id}
          selected={elem._id == permissionId[0]}
        >
          {elem.permissionName}
        </option>
      );
    });
  };
  /**
   *user screen
   */

  const handleOnClickSaveScreen = () => {
    setIsLoading(true);
    const body = {
      screenName: screen,
    };
    setAddScreen(!addScreen);
    setScreen("");
    AddScreen("/admin/permission/addscreen", body)
      .then((response) => {
        setIsLoading(false);
        return { error: null, response };
      })
      .catch((error) => {
        setIsLoading(false);

        if (error.response) {
          return { error: error.response };
        }
      });
  };
  useEffect(() => {
    getAllScreen("/admin/permission/screen")
      .then((response) => {
        setAllScreen(response?.data?.result);

        return { error: null, response };
      })
      .catch((error) => {
        if (error.response) {
          return { error: error.response };
        }
      });
  }, [isLoading]);

  const handleOnClickDeleteScreen = () => {
    setIsLoading(true);

    const body = {
      isDeleted: true,
    };
    setAddScreen(addScreen);
    setSelectScreen(!selectScreen);
    getAllScreenDelete("/admin/permission/deletescreen/", selectScreen, body)
      .then((response) => {
        setIsLoading(false);

        console.log("response", response);
        return { error: null, response };
      })
      .catch((error) => {
        setIsLoading(false);

        if (error.response) {
          return { error: error.response };
        }
      });
  };

  const displaypTypeOptionsScreen = () => {
    const screenAllData = allScreen?.filter((elem) => {
      return elem.isDeleted !== true;
    });

    return screenAllData?.map((elem, id) => {
      return (
        <option key={id} value={elem._id}>
          {elem.screenName}
        </option>
      );
    });
  };

  const increaseUser = (increase = true) => {
    let user = additionalUser;
    if (increase) {
      user += 1;
    } else {
      user -= 1;
    }
    setAdditionalUser(user);
  };

  const handleOnClickSaveAdminPermision = () => {
    setIsLoading(true);
    const body = {
      screen: selectScreen,
      permission: [selectPermision],
      role: selectUserRole,
      // companyId: "0",
    };
    setSelectScreen(!selectScreen);
    setSelectUserRole(!selectUserRole);
    setSelectPermision(!selectPermision);

    AdminPermisions("/admin/permission/update", body)
      .then((response) => {
        toast.success("Permission updated successfully!");

        setIsLoading(false);
        return { error: null, response };
      })
      .catch((error) => {
        setIsLoading(false);

        if (error.response) {
          toast.success(error.response);

          return { error: error.response };
        }
      });
  };
  useEffect(() => {
    if (selectScreen) {
      getAllAdminPermisions(
        `/admin/permission?role=${selectUserRole}&screen=${selectScreen}`
      )
        .then((response) => {
          setAdminPermission(response.data.result);
          return { error: null, response };
        })
        .catch((error) => {
          if (error.response) {
            return { error: error.response };
          }
        });
    }
  }, [isLoading, selectScreen, selectUserRole]);
  useEffect(() => {
    const permissionData = adminPermission?.map((result) => {
      return result.permission;
    });
    const pemision = permissionData?.map((permision) => {
      return permision[0]._id;
    });

    setPermissionId(pemision);
  }, [adminPermission]);
  console.log("permissionId", permissionId[0]);
  useEffect(() => {
    setSelectPermision(permissionId[0]);
  }, [permissionId]);

  const getIdx = (list, id) => list.findIndex((data) => data._id === id);

  const addTax = (data) => {
    setTaxes([...taxes, data]);
  };

  const editTax = (idx, data) => {
    taxes.splice(getIdx(taxes, idx), 1, data);
    setTaxes([...taxes]);
  };

  const deleteTax = (idx) => {
    taxes.splice(getIdx(taxes, idx), 1);
    setTaxes([...taxes]);
  };

  return (
    <MainComponent>
      <div className="_container_right _user_page">
        <div className="__proposal">
          {isLoading ? (
            <InitialLoader />
          ) : (
            <div style={{ margin: "2% 2% 0" }}>
              <Row style={{ marginBottom: "5%" }}>
                <Col md="3">
                  <Row>
                    <Col md="6">
                      <h6>Base Plan Per Month</h6>
                    </Col>
                    <Col md="5">
                      <Form.Group
                      // style={styles.formInput}
                      >
                        <Form.Control
                          name="pType"
                          type="number"
                          onChange={(e) => setBasePlan(e.target.value)}
                          value={basePlan}
                          disabled={!editBasePlan}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    {/* <Col md="3">
                    <button className="btn btn-primary">Edit</button>
                  </Col> */}
                  </Row>
                </Col>
                <Col md="4">
                  <Row>
                    <Col md="5">
                      <h6>Number of users included in base plan</h6>
                    </Col>
                    <Col md="3">
                      <Form.Group
                      // style={styles.formInput}
                      >
                        <Form.Control
                          name="pType"
                          type="number"
                          value={additionalUser}
                          disabled
                          onChange={(e) => setAdditionalUser(+e.target.value)}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col md="2">
                      <button
                        disabled={additionalUser === 1 || !editBasePlan}
                        type="button"
                        className="btn btn-outline-dark"
                        style={{ margin: "0px 10px" }}
                        onClick={() => increaseUser(false)}
                      >
                        -
                      </button>
                    </Col>
                    <Col md="2">
                      <button
                        type="button"
                        className="btn btn-outline-dark"
                        onClick={() => increaseUser()}
                        disabled={!editBasePlan}
                      >
                        +
                      </button>
                    </Col>
                  </Row>
                </Col>
                <Col md="5">
                  <Row>
                    <Col md="5">
                      <h6>Price per additional user per month</h6>
                    </Col>
                    <Col md="2">
                      <Form.Group
                      // style={styles.formInput}
                      >
                        <Form.Control
                          name="pType"
                          type="number"
                          value={pricePerAdditionalUser}
                          disabled={!editBasePlan}
                          onChange={(e) =>
                            setPricePerAdditionalUser(e.target.value)
                          }
                        ></Form.Control>
                      </Form.Group>
                    </Col>

                    <Col md="3">
                      {!editBasePlan ? (
                        <button
                          className="btn btn-primary"
                          onClick={() => setEditBasePlan(!editBasePlan)}
                        >
                          Edit
                        </button>
                      ) : (
                        <button
                          className="btn btn-success"
                          onClick={handleOnClickSave}
                        >
                          Save
                        </button>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row style={{ marginBottom: "5%" }}>
                <Col md="3">
                  <Row>
                    <Col md="6">
                      <h6>Onboarding Charge / One-time Setup</h6>
                    </Col>
                    <Col md="5">
                      <Form.Group
                      // style={styles.formInput}
                      >
                        <Form.Control
                          name="pType"
                          type="number"
                          onChange={(e) => setOneTimeCharge(e.target.value)}
                          value={oneTimeCharge}
                          disabled={!editOnBoarding}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col md="1">
                      {!editOnBoarding ? (
                        <button
                          className="btn btn-primary"
                          onClick={() => setEditOnBoarding(!editOnBoarding)}
                        >
                          Edit
                        </button>
                      ) : (
                        <button
                          className="btn btn-success"
                          onClick={handleOnClickSave}
                        >
                          Save
                        </button>
                      )}
                    </Col>
                    {/* <Col md="3">
                    <button className="btn btn-primary">Edit</button>
                  </Col> */}
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col md="4">
                  <Form.Group
                  // style={styles.formInput}
                  >
                    <Form.Label>User roles</Form.Label>
                    <Row>
                      <Col md="8">
                        {!addUserRoles ? (
                          <Form.Control
                            as="select"
                            name="pType"
                            // value={pType}
                            // isInvalid={errAt === "pType"}
                            onChange={(e) => setSelectUserRole(e.target.value)}
                          >
                            <option value="">Choose...</option>

                            {displaypTypeOptionsUserRoles()}
                          </Form.Control>
                        ) : (
                          <Form.Control
                            name="pType"
                            value={userRole}
                            onChange={(e) => setUserRole(e.target.value)}
                          ></Form.Control>
                        )}
                      </Col>

                      <Col md="4">
                        {!addUserRoles && !selectUserRole ? (
                          <button
                            className="btn btn-primary"
                            onClick={() => setAddUserRoles(!addUserRoles)}
                          >
                            Add
                          </button>
                        ) : !selectUserRole ? (
                          <button
                            className="btn btn-success"
                            onClick={handleOnClickSaveUserRole}
                          >
                            Save
                          </button>
                        ) : (
                          <button
                            className="btn btn-danger"
                            onClick={handleOnClickDeleteUserRole}
                          >
                            Delete
                          </button>
                        )}
                      </Col>
                    </Row>
                  </Form.Group>
                </Col>
                <Col md="4">
                  <Form.Group
                  // style={styles.formInput}
                  >
                    <Form.Label>Select Screen</Form.Label>
                    <Row>
                      <Col md="8">
                        {!addScreen ? (
                          <Form.Control
                            as="select"
                            name="pType"
                            // value={pType}
                            // isInvalid={errAt === "pType"}
                            onChange={(e) => setSelectScreen(e.target.value)}
                            disabled={!selectUserRole}
                          >
                            <option value="">Choose...</option>

                            {displaypTypeOptionsScreen()}
                          </Form.Control>
                        ) : (
                          <Form.Control
                            name="pType"
                            value={screen}
                            onChange={(e) => setScreen(e.target.value)}
                          ></Form.Control>
                        )}
                      </Col>

                      <Col md="4">
                        {/* {!addScreen &&
                        !selectScreen ? //   onClick={() => setAddScreen(!addScreen)} //   className="btn btn-primary" // <button
                        //   disabled={!selectUserRole}
                        // >
                        //   Add
                        // </button>
                        null : !selectScreen ? (
                          <button
                            className="btn btn-success"
                            onClick={handleOnClickSaveScreen}
                          >
                            Save
                          </button>
                        ) : (
                          <button
                            className="btn btn-danger"
                            onClick={handleOnClickDeleteScreen}
                          >
                            Delete
                          </button>
                        )} */}
                      </Col>
                    </Row>
                  </Form.Group>
                </Col>
                <Col md="4">
                  <Form.Group
                  // style={styles.formInput}
                  >
                    <Form.Label>Select Permision</Form.Label>
                    <Row>
                      <Col md="8">
                        {!addPermision ? (
                          <Form.Control
                            as="select"
                            name="pType"
                            // value={pType}
                            // isInvalid={errAt === "pType"}
                            onChange={(e) => setSelectPermision(e.target.value)}
                            disabled={!selectScreen}
                          >
                            <option value="">Choose...</option>

                            {displaypTypeOptionsPermision(allPermision)}
                          </Form.Control>
                        ) : (
                          <Form.Control
                            name="pType"
                            value={permision}
                            onChange={(e) => setPermision(e.target.value)}
                          ></Form.Control>
                        )}
                      </Col>

                      <Col md="4">
                        {/* {!addPermision && !selectPermision ? // <button
                        //   className="btn btn-primary"
                        //   onClick={() => setAddPermision(!permision)}
                        //   disabled={!selectScreen}
                        // >
                        //   Add
                        // </button>
                        null : !selectPermision ? (
                          <button
                            className="btn btn-success"
                            onClick={handleOnClickSavePemision}
                          >
                            Save
                          </button>
                        ) : (
                          <button
                            className="btn btn-danger"
                            onClick={handleOnClickDeletePermision}
                          >
                            Delete
                          </button>
                        )} */}
                      </Col>
                    </Row>
                  </Form.Group>
                </Col>
              </Row>
              <button
                style={{ float: "right", marginTop: "10%" }}
                className="btn btn-success"
                onClick={handleOnClickSaveAdminPermision}
                disabled={!selectUserRole || !selectPermision || !selectScreen}
              >
                Save
              </button>
            </div>
          )}
        </div>
      </div>
    </MainComponent>
  );
};

export default Subscription;
