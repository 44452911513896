import React from 'react'
import {useState, useEffect} from 'react'
import axios from 'axios'
import { addCartingPersonel, getCarting } from 'api/api';

const useCartingPersonelHook = (isLoading) => {
    const [cartingData,setCartingData] = useState([])
//const [isLoading, setIsLoading] = useState(false);
    
    useEffect(() => {
      getCartingPersonnels();
    }, [isLoading]);


    const getCartingPersonnels = () => {
      getCarting("/supplier/cartingPersonel")
      .then((response) => {
        const {data: {result = []} = {}} = response;
        setCartingData(result)
      })
      .catch((error) => {
        if (error.response) {
          return { error: error.response };
        }
      });
    }

    const addPersonelsCarting = (property,setProperty,addProperty,setAddProperty,setIsLoading) => {
      setIsLoading(true);
          const body = {
            personelPosition: property,
          };
          setAddProperty(!addProperty);
          setProperty("");
          addCartingPersonel("/supplier/addCartingPersonel", body)
            .then((response) => {
              setIsLoading(false);
              return { error: null, response };
            })
            .catch((error) => {
              setIsLoading(false);
      
              if (error.response) {
                return { error: error.response };
              }
            });
    }
    return { cartingData, addPersonelsCarting, isLoading }
}

export default useCartingPersonelHook