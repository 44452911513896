import InitialLoader from "components/InitilalLoader";
import MainComponent from "components/MainComponent";
import useAllSubsCrewData from "customHooks/PricingData/CrewPricing/useAllSubsCrewData";

import React from "react";
import { useEffect } from "react";
import { useState } from "react";

import { Col, Form, Row } from "react-bootstrap";
import { Button, Modal, ModalFooter, ModalHeader } from "reactstrap";

import "../Subscription/Subscription.scss";
import CommonCewSeriesInput from "./CommonCrewSeriesInput";
import { randomKey } from "../../utils/utils";

function CrewPricing() {
  const [typeOfDeleteOp, setTypeOfDeleteOp] = useState(null);
  const [isDelete, setIsDelete] = useState(false);

  const {
    showOptions,
    categoryList,
    typesList,

    setSelectCatagory,
    setSelectCatagpryType,
    selectCatagory,
    selectCatagoryType,
    selectManufacture,
    handleChange,
    subName,
    loborSeriesData,
    isLoadingSeries,
    setIsLoadingSeries,
    setAllSeriesData,

    addUpdateSeries,

    isLoading,
    setAdd,
    showAddNew,
    saveData,
    editData,
    deleteData,
    handleOnAddNew,
    add,
    addNewSeries,
    setAddNewSeries,
    allPrefered,
    setAllPrefered,
    roofPitchList,
    subTypeList,
    styleList,
    setSelectSubType,
    setSelectRoofPitch,
    setSelectStyle,
    selectRoofPitch,
    selectSubType,
    selectStyle,
    addRoofPitch,
    setAddRoofPitch,
    addSubType,
    setAddSubType,
    addMStyle,
    setAddStyle,
    addRoofPitchs,
    subType,
    addStyle,
    setSubType,
    roofPitch,
    setRoofPitch,
    style,
    setStyle,
    addSubTypes,
    deleteSubTypes,
    deleteRoofPitchs,
    deleteStyles,
  } = useAllSubsCrewData();

  function toggle() {
    setIsDelete(!isDelete);
  }

  const deleteModal = () => {
    return (
      <Modal isOpen={isDelete} toggle={toggle}>
        <ModalHeader toggle={toggle}>Are you sure to delete it?</ModalHeader>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              deleteRespectiveOptions();
            }}
          >
            Delete
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  useEffect(() => {
    if (typeOfDeleteOp) {
      setIsDelete(!isDelete);
    }
  }, [typeOfDeleteOp]);

  const deleteRespectiveOptions = () => {
    if (typeOfDeleteOp === "subtype")
      deleteSubTypes(
        selectCatagory === "Gutters/Leaders"
          ? {
              category: selectCatagory,
              categoryType: selectCatagoryType,
              subType: selectSubType,
            }
          : {
              category: selectCatagory,
              categoryType: selectCatagory,
              subType: selectSubType,
            }
      );
    if (typeOfDeleteOp === "style")
      deleteStyles({
        category: selectCatagory,
        categoryType: selectCatagoryType,
        style: selectStyle,
      });
    if (typeOfDeleteOp === "roofpitch")
      deleteRoofPitchs({
        category: selectCatagory,
        categoryType: selectCatagory,
        roofPitch: selectRoofPitch,
      });
    toggle();
  };

  return (
    <MainComponent>
      {isDelete && deleteModal()}

      <div className="_container_right _user_page">
        <div className="__proposal">
          {isLoading ? (
            <InitialLoader />
          ) : (
            <div style={{ margin: "2% 2% 0" }}>
              <Row style={{ marginBottom: "2%" }}>
                <Col md="3">
                  <Form.Group>
                    <Form.Label>Category</Form.Label>
                    <Row>
                      <Col md="12">
                        <Form.Control
                          as="select"
                          name="pType"
                          value={selectCatagory}
                          onChange={(event) =>
                            handleChange(event, setSelectCatagory, "category")
                          }
                        >
                          <option value="">Choose...</option>

                          {showOptions(categoryList)}
                        </Form.Control>
                      </Col>
                    </Row>
                  </Form.Group>
                </Col>

                {selectCatagory === "Gutters/Leaders" && typesList.length > 0 && (
                  <Col md="3">
                    <Form.Group>
                      <Form.Label>{selectCatagory} Type</Form.Label>
                      <Row>
                        <Col md="12">
                          <Form.Control
                            as="select"
                            name="pType"
                            value={selectCatagoryType}
                            onChange={(event) =>
                              handleChange(
                                event,
                                setSelectCatagpryType,
                                "ctype"
                              )
                            }
                          >
                            <option value="">Choose...</option>

                            {showOptions(typesList)}
                          </Form.Control>
                        </Col>
                      </Row>
                    </Form.Group>
                  </Col>
                )}

                {(selectCatagory || selectCatagoryType) &&
                  subTypeList.length > 0 && (
                    <Col md="3">
                      <Form.Group>
                        <Form.Label>{subName}</Form.Label>
                        {!addSubType && !selectSubType ? (
                          <button
                            className="btn-primary"
                            style={{
                              float: "right",
                              backgroundColor: "#4242f7",
                              color: "white",
                              borderRadius: "5px",
                              borderColor: "#4242f7",
                            }}
                            onClick={() => setAddSubType(!addSubType)}
                          >
                            Add New
                          </button>
                        ) : !selectSubType ? (
                          <button
                            className="btn-primary"
                            style={{
                              float: "right",
                              backgroundColor: "green",
                              color: "white",
                              borderRadius: "5px",
                              borderColor: "green",
                            }}
                            onClick={() =>
                              addSubTypes(
                                selectCatagory === "Gutters/Leaders"
                                  ? {
                                      category: selectCatagory,
                                      categoryType: selectCatagoryType,
                                      subType: subType,
                                    }
                                  : {
                                      category: selectCatagory,
                                      categoryType: selectCatagory,
                                      subType: subType,
                                    }
                              )
                            }
                          >
                            Save
                          </button>
                        ) : (
                          <button
                            className="btn-primary"
                            style={{
                              float: "right",
                              backgroundColor: "red",
                              color: "white",
                              borderRadius: "5px",
                              borderColor: "red",
                            }}
                            onClick={() => setTypeOfDeleteOp("subtype")}
                          >
                            Delete
                          </button>
                        )}

                        <Row>
                          <Col md="12">
                            {!addSubType ? (
                              <Form.Control
                                as="select"
                                name="pType"
                                value={selectSubType}
                                onChange={(event) =>
                                  handleChange(
                                    event,
                                    setSelectSubType,
                                    "subtype"
                                  )
                                }
                              >
                                <option value="">Choose...</option>

                                {showOptions(subTypeList)}
                              </Form.Control>
                            ) : (
                              <Form.Control
                                name="pType"
                                value={subType}
                                onChange={(e) => setSubType(e.target.value)}
                              ></Form.Control>
                            )}
                          </Col>
                        </Row>
                      </Form.Group>
                    </Col>
                  )}
                {selectSubType && styleList.length > 0 && (
                  <Col md="3">
                    <Form.Group>
                      <Form.Label>Style</Form.Label>
                      {!addMStyle && !selectStyle ? (
                        <button
                          className="btn-primary"
                          style={{
                            float: "right",
                            backgroundColor: "#4242f7",
                            color: "white",
                            borderRadius: "5px",
                            borderColor: "#4242f7",
                          }}
                          onClick={() => setAddStyle(!addMStyle)}
                        >
                          Add New
                        </button>
                      ) : !selectStyle ? (
                        <button
                          className="btn-primary"
                          style={{
                            float: "right",
                            backgroundColor: "green",
                            color: "white",
                            borderRadius: "5px",
                            borderColor: "green",
                          }}
                          onClick={() =>
                            addStyle({
                              category: selectCatagory,
                              categoryType: selectCatagoryType,
                              style: style,
                            })
                          }
                        >
                          Save
                        </button>
                      ) : (
                        <button
                          className="btn-primary"
                          style={{
                            float: "right",
                            backgroundColor: "red",
                            color: "white",
                            borderRadius: "5px",
                            borderColor: "red",
                          }}
                          onClick={() => setTypeOfDeleteOp("style")}
                        >
                          Delete
                        </button>
                      )}
                      <Row>
                        <Col md="12">
                          {!addMStyle ? (
                            <Form.Control
                              as="select"
                              name="pType"
                              value={selectStyle}
                              onChange={(event) =>
                                handleChange(event, setSelectStyle, "series")
                              }
                            >
                              <option value="">Choose...</option>

                              {showOptions(styleList)}
                            </Form.Control>
                          ) : (
                            <Form.Control
                              name="pType"
                              value={style}
                              onChange={(e) => setStyle(e.target.value)}
                            ></Form.Control>
                          )}
                        </Col>
                      </Row>
                    </Form.Group>
                  </Col>
                )}

                {setSelectSubType && roofPitchList.length > 0 && (
                  <Col md="3">
                    <Form.Group>
                      <Form.Label>Roof Pitch</Form.Label>
                      {!addRoofPitch && !selectRoofPitch ? (
                        <button
                          className="btn-primary"
                          style={{
                            float: "right",
                            backgroundColor: "#4242f7",
                            color: "white",
                            borderRadius: "5px",
                            borderColor: "#4242f7",
                          }}
                          onClick={() => setAddRoofPitch(!addRoofPitch)}
                        >
                          Add New
                        </button>
                      ) : !selectRoofPitch ? (
                        <button
                          className="btn-primary"
                          style={{
                            float: "right",
                            backgroundColor: "green",
                            color: "white",
                            borderRadius: "5px",
                            borderColor: "green",
                          }}
                          onClick={() =>
                            addRoofPitchs({
                              category: selectCatagory,
                              categoryType: selectCatagory,
                              roofPitch: roofPitch,
                            })
                          }
                        >
                          Save
                        </button>
                      ) : (
                        <button
                          className="btn-primary"
                          style={{
                            float: "right",
                            backgroundColor: "red",
                            color: "white",
                            borderRadius: "5px",
                            borderColor: "red",
                          }}
                          onClick={() => setTypeOfDeleteOp("roofpitch")}
                        >
                          Delete
                        </button>
                      )}
                      <Row>
                        <Col md="12">
                          {!addRoofPitch ? (
                            <Form.Control
                              as="select"
                              name="pType"
                              value={selectRoofPitch}
                              onChange={(event) =>
                                handleChange(
                                  event,
                                  setSelectRoofPitch,
                                  "series"
                                )
                              }
                            >
                              <option value="">Choose...</option>

                              {showOptions(roofPitchList)}
                            </Form.Control>
                          ) : (
                            <Form.Control
                              name="pType"
                              value={roofPitch}
                              onChange={(e) => setRoofPitch(e.target.value)}
                            ></Form.Control>
                          )}
                        </Col>
                      </Row>
                    </Form.Group>
                  </Col>
                )}
              </Row>

              {isLoadingSeries ? (
                <InitialLoader />
              ) : (
                <>
                  {showAddNew && (
                    <Row
                      style={{
                        marginBottom: "4%",
                        float: "right",
                      }}
                    >
                      <Col md="12">
                        <button
                          className="btn btn-primary"
                          onClick={handleOnAddNew}
                        >
                          Add New
                        </button>
                      </Col>
                    </Row>
                  )}
                  {loborSeriesData?.length > 0 && (
                    <Row style={{ marginTop: "10%" }}>
                      {loborSeriesData[0].accessory &&
                        loborSeriesData[0].accessory !== "N/A" && (
                          <Col md="2">
                            <Form.Group style={{ textAlign: "center" }}>
                              <Form.Label>Accessory</Form.Label>
                            </Form.Group>
                          </Col>
                        )}
                      {loborSeriesData[0].roofPitch &&
                        loborSeriesData[0].roofPitch !== "N/A" && (
                          <Col md="2">
                            <Form.Group style={{ textAlign: "center" }}>
                              <Form.Label>Roof Pitch</Form.Label>
                            </Form.Group>
                          </Col>
                        )}
                      {loborSeriesData[0].workType &&
                        loborSeriesData[0].workType !== "N/A" && (
                          <Col md="2">
                            <Form.Group style={{ textAlign: "center" }}>
                              <Form.Label>Work Type</Form.Label>
                            </Form.Group>
                          </Col>
                        )}
                      {loborSeriesData[0].subType &&
                        loborSeriesData[0].subType !== "N/A" && (
                          <Col md="2">
                            <Form.Group style={{ textAlign: "center" }}>
                              <Form.Label>{subName}</Form.Label>
                            </Form.Group>
                          </Col>
                        )}
                      {loborSeriesData[0].style &&
                        loborSeriesData[0].style !== "N/A" && (
                          <Col md="2">
                            <Form.Group style={{ textAlign: "center" }}>
                              <Form.Label>Style</Form.Label>
                            </Form.Group>
                          </Col>
                        )}

                      {loborSeriesData[0].color &&
                        loborSeriesData[0].color !== "N/A" && (
                          <Col md="1">
                            <Form.Group style={{ textAlign: "center" }}>
                              <Form.Label>Color</Form.Label>
                            </Form.Group>
                          </Col>
                        )}
                      {loborSeriesData[0].size &&
                        loborSeriesData[0].size !== "N/A" && (
                          <Col md="1">
                            <Form.Group style={{ textAlign: "center" }}>
                              <Form.Label>Size</Form.Label>
                            </Form.Group>
                          </Col>
                        )}
                      {loborSeriesData[0].uom &&
                        loborSeriesData[0].uom !== "N/A" && (
                          <Col md="2">
                            <Form.Group style={{ textAlign: "center" }}>
                              <Form.Label>UOM</Form.Label>
                            </Form.Group>
                          </Col>
                        )}
                      {loborSeriesData[0].uomPrice &&
                        loborSeriesData[0].uomPrice !== "N/A" && (
                          <Col md="2">
                            <Form.Group style={{ textAlign: "center" }}>
                              <Form.Label>Price Per UOM</Form.Label>
                            </Form.Group>
                          </Col>
                        )}
                    </Row>
                  )}

                  {add && loborSeriesData?.length > 0 && (
                    <CommonCewSeriesInput
                      saveData={saveData}
                      editData={true}
                      isAcessory={loborSeriesData[0].accessory}
                      isColors={loborSeriesData[0].color}
                      isRoofPitchs={loborSeriesData[0].roofPitch}
                      isSizes={loborSeriesData[0].size}
                      isStyles={loborSeriesData[0].style}
                      isSubTypes={loborSeriesData[0].subType}
                      isUOM={loborSeriesData[0].uom}
                      UOM={loborSeriesData[0].uom}
                      isWorkTypes={loborSeriesData[0].workType}
                      isPricepweruom={loborSeriesData[0].uomPrice}
                      setAddNewSeries={setAddNewSeries}
                      addNewSeries={addNewSeries}
                      category={selectCatagory}
                      style={selectStyle}
                      manufacturer={selectManufacture}
                      categoryType={
                        selectCatagory === "Gutters/Leaders"
                          ? selectCatagoryType
                          : selectCatagory
                      }
                      subType={selectSubType}
                      roofPitch={
                        selectCatagory === "Flat Roofing"
                          ? "Flat"
                          : selectRoofPitch
                      }
                      add={add}
                      deleteData={deleteData}
                      setAdd={setAdd}
                    />
                  )}
                  {loborSeriesData?.length > 0
                    ? loborSeriesData?.map((data) => (
                        <CommonCewSeriesInput
                          key={randomKey}
                          setAddNewSeries={setAddNewSeries}
                          saveData={editData}
                          deleteData={deleteData}
                          acessory={data.accessory}
                          colors={data.color}
                          roofPitch={data.roofPitch}
                          sizes={data.size}
                          style={data.style}
                          subType={data.subType}
                          UOM={data.uom}
                          workTypes={data.workType}
                          _id={data._id}
                          pricepweruom={data.uomPrice}
                          setIsLoadingSeries={setIsLoadingSeries}
                          addNewSeries={addNewSeries}
                          category={data.category}
                          manufacturer={selectManufacture}
                          categoryType={data.categoryType}
                          allPrefered={allPrefered}
                          setAllPrefered={setAllPrefered}
                          priceData={data}
                          loborSeriesData={loborSeriesData}
                          setAllSeriesData={setAllSeriesData}
                          setAdd={setAdd}
                        />
                      ))
                    : showAddNew && (
                        <h6 style={{ textAlign: "center", marginTop: "10%" }}>
                          No Data Found
                        </h6>
                      )}
                  {loborSeriesData?.length > 0 && (
                    <Row style={{ marginTop: "5%", float: "right" }}>
                      <Col md="12">
                        <Row>
                          <Col md="6">
                            <button className="btn btn-danger">Cancel</button>
                          </Col>
                          <Col md="6">
                            <button
                              className="btn btn-success"
                              style={{ width: "70px" }}
                              onClick={() =>
                                addUpdateSeries(setIsLoadingSeries)
                              }
                              //   disabled={!adNewSeries}
                            >
                              Save
                            </button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </MainComponent>
  );
}

export default CrewPricing;
