import InitialLoader from "components/InitilalLoader";
import MainComponent from "components/MainComponent";
import useAllCartingData from "customHooks/CartingHooks/useAllCartingData";

import React from "react";

import { Col, Form, Row } from "react-bootstrap";

import "../Subscription/Subscription.scss";
import CommonCartingseries from "./CommonCartingSeries";
import { randomKey } from "../../utils/utils";

function CartingPricing() {
  const {
    setSelectCatagory,

    selectCatagory,

    handleChange,
    cartingSeriesData,
    isLoadingSeries,
    setIsLoadingSeries,
    setAllSeriesData,

    setShowAddNew,
    showAddNew,
    delteMaterialSeries,
    saveData,
    editData,
    deleteData,
    handleOnAddNew,
    setAdd,
    add,
    addNewSeries,
    setAddNewSeries,
    addUpdateSeries,
  } = useAllCartingData();

  return (
    <MainComponent>
      <div className="_container_right _user_page">
        <div className="__proposal">
          <div style={{ margin: "2% 2% 0" }}>
            <Row style={{ marginBottom: "2%" }}>
              <Col md="3">
                <Form.Group>
                  <Form.Label>Category</Form.Label>
                  <Row>
                    <Col md="12">
                      <Form.Control
                        as="select"
                        name="pType"
                        value={selectCatagory}
                        onChange={(event) =>
                          handleChange(event, setSelectCatagory, "category")
                        }
                      >
                        <option value="">Choose...</option>
                        <option value="Carting">Carting</option>
                      </Form.Control>
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
            </Row>

            {isLoadingSeries ? (
              <InitialLoader />
            ) : (
              <>
                {showAddNew && (
                  <Row
                    style={{
                      marginBottom: "4%",
                      float: "right",
                    }}
                  >
                    <Col md="12">
                      <button
                        className="btn btn-primary"
                        onClick={handleOnAddNew}
                      >
                        Add New
                      </button>
                    </Col>
                  </Row>
                )}
                {cartingSeriesData?.length > 0 && (
                  <Row style={{ marginTop: "10%" }}>
                    <Col md="3">
                      <Form.Group style={{ textAlign: "center" }}>
                        <Form.Label>Carting Type</Form.Label>
                      </Form.Group>
                    </Col>

                    <Col md="3">
                      <Form.Group style={{ textAlign: "center" }}>
                        <Form.Label>Size</Form.Label>
                      </Form.Group>
                    </Col>
                    <Col md="3">
                      <Form.Group style={{ textAlign: "center" }}>
                        <Form.Label>Price Per UOM</Form.Label>
                      </Form.Group>
                    </Col>
                  </Row>
                )}

                {add && (
                  <CommonCartingseries
                    saveData={saveData}
                    editData={true}
                    setAddNewSeries={setAddNewSeries}
                    addNewSeries={addNewSeries}
                    category={selectCatagory}
                    add={add}
                    deleteData={deleteData}
                    setAdd={setAdd}
                  />
                )}
                {cartingSeriesData?.length > 0
                  ? cartingSeriesData?.map((data) => (
                      <CommonCartingseries
                        key={randomKey}
                        setAddNewSeries={setAddNewSeries}
                        saveData={editData}
                        deleteData={deleteData}
                        setIsLoadingSeries={setIsLoadingSeries}
                        addNewSeries={addNewSeries}
                        category={data.category}
                        pricePerUom={data.pricePerUOM}
                        pricingTypes={data.pricingType}
                        sizes={data.size}
                        _id={data._id}
                        priceData={data}
                        cartingSeriesData={cartingSeriesData}
                        setAllSeriesData={setAllSeriesData}
                        setAdd={setAdd}
                      />
                    ))
                  : showAddNew && (
                      <h6 style={{ textAlign: "center", marginTop: "10%" }}>
                        No Data Found
                      </h6>
                    )}
                {cartingSeriesData?.length > 0 && (
                  <Row style={{ marginTop: "5%", float: "right" }}>
                    <Col md="12">
                      <Row>
                        <Col md="6">
                          <button className="btn btn-danger">Cancel</button>
                        </Col>
                        <Col md="6">
                          <button
                            className="btn btn-success"
                            style={{ width: "70px" }}
                            onClick={() => addUpdateSeries(setIsLoadingSeries)}
                            //   disabled={!adNewSeries}
                          >
                            Save
                          </button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </MainComponent>
  );
}

export default CartingPricing;
