import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
// import InputMask from "react-input-mask";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const CommonCewSeriesInput = (props) => {
  const {
    isAcessory,
    isColors,
    isRoofPitchs,
    isSizes,
    isStyles,
    isSubTypes,
    isUOM,
    isWorkTypes,
    isPricepweruom,
    setAddNewSeries,
    UOM = "",
    acessory = "",
    colors = "",
    roofPitchs = "",
    sizes = "",
    style = "",
    uomType = "",
    _id = null,
    workTypes = "",
    pricepweruom = "",
    addNewSeries,
    saveData,
    deleteData,
    category,
    manufacturer,
    categoryType,
    subTypes,
    allPrefered,
    priceData,
    setAllPrefered,
    materialSeriesData,
    setAllSeriesData,
    add,
    subType,
    roofPitch,
    editData = false,
    setAdd,
  } = props;

  const [accessory, setAccessory] = useState(acessory);
  const [uom, setUom] = useState(UOM);
  // const [uomPriceData, setPricePerUomData] = useState(pricepweruom);

  const [uomPrice, setPricePerUom] = useState(pricepweruom);
  const [workType, setWorkType] = useState(workTypes);
  const [color, setColor] = useState(colors);
  const [size, setSize] = useState(sizes);

  const [edit, setEdit] = useState(editData);

  const [isDelete, setIsDelete] = useState(false);

  function toggle() {
    setIsDelete(!isDelete);
  }
  // useEffect(() => {
  //   if (uomPriceData) {
  //     setPricePerUom(uomPriceData?.toString().slice(1).replace(".00", ""));
  //   }
  // }, [uomPriceData]);

  const deleteModal = () => {
    return (
      <Modal isOpen={isDelete} toggle={toggle} className={props.className}>
        <ModalHeader toggle={toggle}>Are you sure to delete it?</ModalHeader>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              deleteData(_id);
              toggle();
            }}
          >
            Delete
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  const handleChange = (e, callback) => {
    callback(e.target.value);
  };

  const handleOnClickSave = () => {
    setAddNewSeries(false);
    setEdit(!edit);
    saveData({
      category,
      categoryType,
      subType,
      size,
      color,
      style,
      accessory,
      roofPitch,
      uomPrice,
      workType,
      uom,
      _id,
    });
  };

  const handleOnClickCancel = () => {
    setAdd(false);
    setEdit(!edit);
  };
  const MultiRow = () => {
    if (
      (subType === "Aluminum" && categoryType === "Gutters") ||
      (subType === "Aluminum" && categoryType === "Leaders")
    ) {
      return true;
    } else {
      return false;
    }
  };
  const DisableSave = () => {
    if (category === "Shingle Roofing" || category === "Flat Roofing") {
      return !workType || !uomPrice;
    } else if (category === "Skylights" || category === "Roofing Accessories") {
      return !accessory || !uomPrice;
    } else if (category === "Capping" || category === "Fascia") {
      return !uomPrice;
    } else if (
      categoryType === "Gutters" ||
      category === "Leaders" ||
      category === "Screens"
    ) {
      return !size || !color || !uomPrice;
    } else return !color || !uomPrice;
  };

  return (
    <>
      {isDelete && deleteModal()}

      <Row style={{ marginBottom: "15px" }}>
        {((isAcessory && isAcessory !== "N/A") ||
          (acessory && acessory !== "N/A")) && (
          <Col md="2">
            <Form.Group>
              <Form.Control
                name="pType"
                value={accessory}
                disabled={!edit}
                onChange={(event) => handleChange(event, setAccessory)}
                style={{ textAlign: "center", fontSize: "0.8rem" }}
              ></Form.Control>
            </Form.Group>
          </Col>
        )}
        {((isRoofPitchs && isRoofPitchs !== "N/A") ||
          (roofPitch && roofPitch !== "N/A")) && (
          <Col md="2">
            <Form.Group>
              <Form.Control
                name="pType"
                value={roofPitch}
                disabled
                // onChange={(event) => handleChange(event, setRoofPitch)}
                style={{ textAlign: "center", fontSize: "0.8rem" }}
              ></Form.Control>
            </Form.Group>
          </Col>
        )}
        {((isWorkTypes && isWorkTypes !== "N/A") ||
          (workTypes && workTypes !== "N/A")) && (
          <Col md="2">
            <Form.Group>
              <Form.Control
                name="pType"
                value={workType}
                disabled={!edit}
                onChange={(event) => handleChange(event, setWorkType)}
                style={{ textAlign: "center", fontSize: "0.8rem" }}
              ></Form.Control>
            </Form.Group>
          </Col>
        )}
        {((isSubTypes && isSubTypes !== "N/A") ||
          (subType && subType !== "N/A")) && (
          <Col md="2">
            <Form.Group>
              <Form.Control
                name="pType"
                value={subType}
                disabled
                // onChange={(event) => handleChange(event, setSubType)}
                style={{ textAlign: "center", fontSize: "0.8rem" }}
              ></Form.Control>
            </Form.Group>
          </Col>
        )}
        {((isStyles && isStyles !== "N/A") || (style && style !== "N/A")) && (
          <Col md="2 ">
            <Form.Group>
              <Form.Control
                name="pType"
                value={style}
                disabled
                // onChange={(event) => handleChange(event, setStyle)}
                style={{ textAlign: "center", fontSize: "0.8rem" }}
              ></Form.Control>
            </Form.Group>
          </Col>
        )}

        {((isColors && isColors !== "N/A") || (colors && colors !== "N/A")) && (
          <Col md="1">
            <Form.Group>
              <Form.Control
                as="select"
                name="pType"
                value={color}
                disabled={!edit}
                onChange={(event) => handleChange(event, setColor)}
                style={{ textAlign: "center", fontSize: "0.8rem" }}
              >
                <option value="">Choose...</option>
                <option value="White">White</option>
                <option value="Brown">Brown</option>
                <option value="Other">Other</option>
              </Form.Control>
            </Form.Group>
          </Col>
        )}

        {((isSizes && isSizes !== "N/A") || (sizes && sizes !== "N/A")) && (
          <Col md="1">
            <Form.Group>
              <Form.Control
                name="pType"
                value={size}
                disabled={!edit}
                onChange={(event) => handleChange(event, setSize)}
                style={{ textAlign: "center", fontSize: "0.8rem" }}
              ></Form.Control>
            </Form.Group>
          </Col>
        )}

        {((isUOM && isUOM !== "N/A") || (UOM && UOM !== "N/A")) && (
          <Col md="2">
            <Form.Group>
              {category === "Roofing Accessories" ? (
                <Form.Control
                  name="pType"
                  as="select"
                  value={uom}
                  disabled={!edit}
                  onChange={(event) => handleChange(event, setUom)}
                  style={{ textAlign: "center", fontSize: "0.8rem" }}
                >
                  <option value="">Choose...</option>
                  <option value="Foot">Foot</option>
                  <option value="Per">Per</option>
                </Form.Control>
              ) : (
                <Form.Control
                  name="pType"
                  value={uom}
                  disabled
                  onChange={(event) => handleChange(event, setUom)}
                  style={{ textAlign: "center", fontSize: "0.8rem" }}
                ></Form.Control>
              )}
            </Form.Group>
          </Col>
        )}

        {((isPricepweruom && isPricepweruom !== "N/A") ||
          (pricepweruom && pricepweruom !== "N/A")) && (
          <Col md="2">
            <Form.Group>
              <Form.Control
                type="number"
                value={uomPrice}
                disabled={!edit}
                onChange={(event) => handleChange(event, setPricePerUom)}
                style={{ textAlign: "center", fontSize: "0.8rem" }}
              ></Form.Control>
              {/* <Form.Group>
                <InputMask
                  className="inputmask"
                  mask="$9.00"
                  value={uomPriceData}
                  disabled={!edit}
                  onChange={(event) => handleChange(event, setPricePerUomData)}
                  style={{
                    display: "block",
                    width: "100%",
                    padding: " 0.375rem 0.75rem",
                    fontSize: "1rem",
                    fontWeight: "400",
                    lineHeight: "1.5",
                    color: "#212529",
                    backgroundColor: "#fff",
                    backgroundClip: "padding-box",
                    border: "1px solid #ced4da",
                    appearance: "none",
                    borderRadius: "0.375rem",
                    transition:
                      "border-color .15s ease-in-out,box-shadow .15s ease-in-out",
                  }}
                ></InputMask>
              </Form.Group> */}
            </Form.Group>
          </Col>
        )}

        {MultiRow() ? (
          addNewSeries ? (
            <Col md="2">
              <Row
              // style={
              //   MultiRow()
              //     ? {
              //         marginTop: "10px",
              //         display: "flex",
              //         justifyContent: "flex-end",
              //       }
              //     : {}
              // }
              >
                <Col md="6">
                  <button
                    className="btn btn-success"
                    onClick={handleOnClickSave}
                    disabled={DisableSave()}
                  >
                    Save
                  </button>
                </Col>
                <Col md="6">
                  <button
                    className="btn btn-danger"
                    onClick={() => setAddNewSeries(false)}
                  >
                    Cancel
                  </button>
                </Col>
              </Row>
            </Col>
          ) : (
            <Col md="2">
              <Row
              // style={
              //   MultiRow()
              //     ? {
              //         marginTop: "10px",
              //         display: "flex",
              //         justifyContent: "flex-end",
              //       }
              //     : {}
              // }
              >
                <Col md="6">
                  {!edit ? (
                    <button
                      className="btn btn-primary"
                      style={{ fontSize: "0.8rem", width: "60px" }}
                      onClick={() => setEdit(!edit)}
                    >
                      Edit
                    </button>
                  ) : (
                    <button
                      className="btn btn-success"
                      style={{ fontSize: "0.8rem", width: "60px" }}
                      onClick={handleOnClickSave}
                      disabled={DisableSave()}
                    >
                      Save
                    </button>
                  )}
                </Col>
                {!edit && (
                  <Col md="6">
                    <button
                      className="btn btn-danger"
                      style={{ fontSize: "0.8rem" }}
                      onClick={() => setIsDelete(!isDelete)}
                    >
                      Delete
                    </button>
                  </Col>
                )}

                {edit && (
                  <Col md="1">
                    <button
                      className="btn btn-danger"
                      style={{ fontSize: "0.8rem" }}
                      onClick={handleOnClickCancel}
                    >
                      Cancel
                    </button>
                  </Col>
                )}
              </Row>
            </Col>
          )
        ) : addNewSeries ? (
          <Col md="2">
            <Row>
              <Col md="6">
                <button
                  className="btn btn-success"
                  onClick={handleOnClickSave}
                  style={{ fontSize: "0.8rem", width: "60px" }}
                  disabled={DisableSave()}
                >
                  Save
                </button>
              </Col>
              <Col md="6">
                <button
                  className="btn btn-danger"
                  onClick={() => setAddNewSeries(false)}
                >
                  Cancel
                </button>
              </Col>
            </Row>
          </Col>
        ) : (
          <Col md="2">
            <Row>
              <Col md="6">
                {!edit ? (
                  <button
                    className="btn btn-primary"
                    style={{ fontSize: "0.8rem", width: "60px" }}
                    onClick={() => setEdit(!edit)}
                  >
                    Edit
                  </button>
                ) : (
                  <button
                    className="btn btn-success"
                    style={{ fontSize: "0.8rem", width: "60px" }}
                    onClick={handleOnClickSave}
                    disabled={DisableSave()}
                  >
                    Save
                  </button>
                )}
              </Col>
              {!edit && (
                <Col md="6">
                  <button
                    className="btn btn-danger"
                    style={{ fontSize: "0.8rem" }}
                    onClick={() => setIsDelete(!isDelete)}
                  >
                    Delete
                  </button>
                </Col>
              )}

              {edit && (
                <Col md="6">
                  <button
                    className="btn btn-danger"
                    style={{ fontSize: "0.8rem" }}
                    onClick={handleOnClickCancel}
                  >
                    Cancel
                  </button>
                </Col>
              )}
            </Row>
          </Col>
        )}
      </Row>
    </>
  );
};

export default CommonCewSeriesInput;
