import {UserActionTypes} from './user.types';

const LOGIN_INITIAL_STATE = {
	isLogging: false,
	errors: null,
	user: null,
};

export function LoginReducer(state = LOGIN_INITIAL_STATE, action) {
	switch (action.type) {
		case UserActionTypes.LOGIN_START:
			return {
				...state,
				isLogging: true,
				isAuthenticated: false,
				user: null,
			};

		case UserActionTypes.LOGIN_SUCCESS:
			return {
				...state,
				isLogging: false,
				errors: null,
				user: action.payload,
			};

		case UserActionTypes.LOGIN_FAILURE:
			return {
				...state,
				isLogging: false,
				errors: action.payload.error,
				user: null,
			};

		case UserActionTypes.SIGN_OUT:
			return {
				...state,
				isLogging: false,
				errors: null,
				user: null,
			};

		default:
			return state;
	}
}

const INITIAL_STATE_USERS = {
	gettingUsers: false,
	userList: null,
	listErr: null,
};

export const GetUsersReducer = (state = INITIAL_STATE_USERS, action) => {
	switch (action.type) {
		case UserActionTypes.GET_USERS_START:
			return {
				...state,
				gettingUsers: true,
				userList: null,
				listErr: null,
			};

		case UserActionTypes.GET_USERS_SUCCESS:
			return {
				...state,
				gettingUsers: false,
				userList: action.payload.data,
				listErr: null,
			};

		case UserActionTypes.GET_USERS_FAILURE:
			return {
				...state,
				gettingUsers: false,
				userList: null,
				listErr: action.payload,
			};

		default:
			return state;
	}
};
