import { addUserLocal, deleteUserLocal, getUserLocal } from 'lib/localbase';

class AuthService {
	token = 'auth_token';
	tempUser = 'temp_user';

	rememberMe = 'remember_me';

	saveRememberMe(value) {
		localStorage.setItem(this.rememberMe, JSON.stringify(value));
	}

	getRememberMe() {
		return JSON.parse(localStorage.getItem(this.rememberMe));
	}

	saveTempUser(tempUser) {
		return sessionStorage.setItem(this.tempUser, JSON.stringify(tempUser));
	}

	getTempUser() {
		return JSON.parse(sessionStorage.getItem(this.tempUser));
	}

	removeTempUser() {
		return sessionStorage.removeItem(this.tempUser);
	}

	saveToken(userData, rememberMe) {
		this.saveRememberMe(rememberMe);
		if (!rememberMe) {
			this.saveTempUser(userData);
			return;
		}
		return addUserLocal(userData);
	}

	getToken() {
		const hasRememberMeChecked = this.getRememberMe();

		if (!hasRememberMeChecked) {
			return this.getTempUser().token;
		}

		return getUserLocal()
			.then((user) => {
				if (user) {
					return user.token;
				} else return null;
			})
			.catch(() => null);
	}

	invalidateUser() {
		const hasRememberMeChecked = this.getRememberMe();

		if (!hasRememberMeChecked) {
			return this.removeTempUser();
		}
		deleteUserLocal();
	}

	isValid(token) {
		if (token) return true;
		return false;
	}

	isAuthenticated() {
		const token = this.getToken();

		return token && this.isValid(token) ? true : false;
	}
}

export default new AuthService();
