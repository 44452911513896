import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
// import InputMask from "react-input-mask";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const CommonSeriesInput = (props) => {
  const {
    setAddNewSeries,
    Preferred = false,
    Series = "",
    UOM = "",
    BDLPerSq = "",
    PricePerUom = "",
    editData = false,
    uomType,
    _id = null,

    addNewSeries,
    saveData,
    deleteData,
    category,
    manufacturer,
    categoryType,
    subType,
    allPrefered,
    priceData,
    setAllPrefered,
    materialSeriesData,
    setAllSeriesData,
    setAdd = false,
    add,
    currency = "$",
    categoryUomType,
  } = props;

  const [isPreferred, setIsPrefered] = useState(Preferred);
  const [series, setSeriesName] = useState(Series);
  const [uom, setUOM] = useState(UOM);
  const [uomValue, setUomValue] = useState(BDLPerSq);
  const [uomPrice, setPricePerUom] = useState(PricePerUom);
  // const [uomPriceData, setPricePerUomData] = useState(PricePerUom);

  const [edit, setEdit] = useState(editData);

  const [isDelete, setIsDelete] = useState(false);

  function toggle() {
    setIsDelete(!isDelete);
  }
  // useEffect(() => {
  //   if (uomPriceData) {
  //     setPricePerUom(uomPriceData?.toString().slice(1).replace(".00", ""));
  //   }
  // }, [uomPriceData]);
  const deleteModal = () => {
    return (
      <Modal isOpen={isDelete} toggle={toggle} className={props.className}>
        <ModalHeader toggle={toggle}>Are you sure to delete it?</ModalHeader>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              deleteData(_id);
              toggle();
            }}
          >
            Delete
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  const handleChange = (e, callback) => {
    callback(e.target.value);
  };

  const handleOnClickSave = () => {
    setAddNewSeries(false);
    setEdit(!edit);
    saveData({
      category,
      manufacturer,
      categoryType,
      subType,
      uomType,
      isPreferred,
      series,
      uom,
      uomValue,
      uomPrice,
      _id,
    });
  };

  const handleOnClickCancel = () => {
    setAdd(false);
    setEdit(!edit);
  };
  const checkPreferred = (list, id) => list.some((data) => data._id === id);

  const showCheckBox =
    allPrefered.length === 0 ||
    (allPrefered.length > 0 && checkPreferred(allPrefered, _id));

  const handleOnChangeCheckBox = () => {
    setIsPrefered(!isPreferred);

    let newMaterialSeries = materialSeriesData.map((doc) => {
      if (_id == doc._id) {
        return { ...doc, isPreferred: !doc.isPreferred };
      }
      return { ...doc, isPreferred: false };
    });

    if (checkPreferred(allPrefered, _id)) {
      setAllPrefered([]);
    } else {
      setAllPrefered([priceData]);
    }
    setAllSeriesData(newMaterialSeries);
    console.log("newMaterialSeries ", newMaterialSeries);
  };

  return (
    <>
      {isDelete && deleteModal()}
      <Row style={{ marginBottom: "15px" }}>
        <Col md="1">
          {showCheckBox && (
            <Form.Group>
              <Form.Check
                name="pType"
                checked={checkPreferred(allPrefered, _id)}
                onChange={handleOnChangeCheckBox}
                style={{ textAlign: "center" }}
              ></Form.Check>
            </Form.Group>
          )}
        </Col>
        <Col md="3">
          <Form.Group>
            <Form.Control
              name="pType"
              value={series}
              disabled={!edit}
              onChange={(event) => handleChange(event, setSeriesName)}
              style={{ textAlign: "center", fontSize: "0.8rem" }}
            ></Form.Control>
          </Form.Group>
        </Col>
        <Col md="2">
          <Form.Group>
            <Form.Control
              as="select"
              name="pType"
              value={uom}
              disabled
              onChange={(event) => handleChange(event, setUOM)}
              style={{ textAlign: "center", fontSize: "0.8rem" }}
            >
              <option value="">Choose...</option>
              <option value={UOM} selected>
                {UOM}
              </option>
            </Form.Control>
          </Form.Group>
        </Col>

        {categoryUomType !== "N/A" && category !== "Flat Roofing Primer" && (
          <Col md="2">
            <Form.Group>
              <Form.Control
                type="number"
                value={uomValue}
                disabled={!edit}
                onChange={(event) => handleChange(event, setUomValue)}
                style={{ textAlign: "center", fontSize: "0.8rem" }}
              ></Form.Control>
            </Form.Group>
          </Col>
        )}

        <Col md="2">
          <Form.Group>
            <Form.Control
              type="number"
              value={uomPrice}
              disabled={!edit}
              onChange={(event) => handleChange(event, setPricePerUom)}
              style={{ textAlign: "center", fontSize: "0.8rem" }}
            ></Form.Control>
          </Form.Group>
          {/* <Form.Group>
            <InputMask
              className="inputmask"
              mask="$9.00"
              value={uomPriceData}
              disabled={!edit}
              onChange={(event) => handleChange(event, setPricePerUomData)}
              style={{
                display: "block",
                width: "100%",
                padding: " 0.375rem 0.75rem",
                fontSize: "1rem",
                fontWeight: "400",
                lineHeight: "1.5",
                color: "#212529",
                backgroundColor: "#fff",
                backgroundClip: "padding-box",
                border: "1px solid #ced4da",
                appearance: "none",
                borderRadius: "0.375rem",
                transition:
                  "border-color .15s ease-in-out,box-shadow .15s ease-in-out",
              }}
            ></InputMask>
          </Form.Group> */}
        </Col>
        {addNewSeries ? (
          <Col md="2">
            <Row>
              <Col md="6">
                <button
                  className="btn btn-success"
                  style={{ fontSize: "0.8rem", width: "60px" }}
                  onClick={handleOnClickSave}
                >
                  Save
                </button>
              </Col>
              <Col md="6">
                <button
                  className="btn btn-danger"
                  onClick={() => setAdd(false)}
                >
                  Cancel
                </button>
              </Col>
            </Row>
          </Col>
        ) : (
          <Col md="2">
            <Row>
              <Col md="6">
                {!edit ? (
                  <button
                    className="btn btn-primary"
                    style={{ fontSize: "0.8rem", width: "60px" }}
                    onClick={() => setEdit(!edit)}
                  >
                    Edit
                  </button>
                ) : (
                  <button
                    className="btn btn-success"
                    style={{ fontSize: "0.8rem", width: "60px" }}
                    onClick={handleOnClickSave}
                    disabled={!series || !uomValue || !uomPrice}
                  >
                    Save
                  </button>
                )}
              </Col>
              {!edit && (
                <Col md="6">
                  <button
                    className="btn btn-danger"
                    style={{ fontSize: "0.8rem" }}
                    onClick={() => {
                      setIsDelete(!isDelete);
                      toggle();
                    }}
                  >
                    Delete
                  </button>
                </Col>
              )}

              {edit && (
                <Col md="6">
                  <button
                    className="btn btn-danger"
                    style={{ fontSize: "0.8rem" }}
                    onClick={handleOnClickCancel}
                  >
                    Cancel
                  </button>
                </Col>
              )}
            </Row>
          </Col>
        )}
      </Row>
    </>
  );
};

export default CommonSeriesInput;
