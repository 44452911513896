import InitialLoader from "components/InitilalLoader";
import MainComponent from "components/MainComponent";
import useSchedulingModal from "customHooks/SchedulingModal/useSchedulingModal";
import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import "../Subscription/Subscription.scss";

const SchedulingModal = () => {
  const {
    increaseProjectManager,
    increaseSales,
    increaseRepair,
    setAdditionalRepairtime,
    additionalRepairTime,
    setAdditionalSalesTime,
    additionalSalesTime,
    setAdditionalProjectManagementTime,
    additionalProjectmanagementTime,
    setIsLoading,
    isLoading,
    showRepairSave,
    setShowRepairSave,
    showProjectSave,
    setShowProjectSave,
    showSalesSave,
    setShowSalesSave,
    handleOnClickSalesSave,
    handleOnClickRepairSave,
    handleOnClickProjectSave,

    increaseSalesGap,
    increaseRepairGap,
    handleOnClickRepairGapSave,
    handleOnClickSalesGapSave,
    setShowSalesGapSave,
    setShowRepairGapSave,
    showSalesGapSave,
    showRepairGapSave,
    setAdditionalRepairGapTime,
    setAdditionalSalesGapTime,
    additionalRepairGapTime,
    additionalSalesGapTime,
  } = useSchedulingModal();
  // kjiijjki
  return (
    <MainComponent>
      <div className="_container_right _user_page">
        <div className="__proposal">
          {isLoading ? (
            <InitialLoader />
          ) : (
            <div style={{ margin: "2% 2% 0" }}>
              <Row style={{ marginBottom: "10%" }}>
                <Col md="4">
                  <Col md="12">
                    <h6>Sales Appointments Window Time</h6>
                  </Col>
                  <Row>
                    <Col md="5">
                      <Form.Group
                      // style={styles.formInput}
                      >
                        <Form.Control
                          name="pType"
                          type="number"
                          value={additionalSalesTime}
                          disabled
                          onChange={(e) =>
                            setAdditionalSalesTime(+e.target.value)
                          }
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col md="2">
                      <button
                        type="button"
                        className="btn btn-outline-dark"
                        style={{ margin: "0px 10px" }}
                        onClick={() => increaseSales(false)}
                        disabled={!showSalesSave}
                      >
                        -
                      </button>
                    </Col>
                    <Col md="2">
                      <button
                        type="button"
                        className="btn btn-outline-dark"
                        onClick={() => increaseSales()}
                        disabled={!showSalesSave}
                      >
                        +
                      </button>
                    </Col>
                    <Col md="3">
                      {showSalesSave ? (
                        <button
                          type="button"
                          className="btn btn-success"
                          onClick={handleOnClickSalesSave}
                        >
                          Save
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => setShowSalesSave(true)}
                        >
                          Edit
                        </button>
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col md="4">
                  <Col md="12">
                    <h6>Repair Appointments Window Time</h6>
                  </Col>
                  <Row>
                    <Col md="5">
                      <Form.Group
                      // style={styles.formInput}
                      >
                        <Form.Control
                          name="pType"
                          type="number"
                          value={additionalRepairTime}
                          disabled
                          onChange={(e) =>
                            setAdditionalRepairtime(+e.target.value)
                          }
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col md="2">
                      <button
                        type="button"
                        className="btn btn-outline-dark"
                        style={{ margin: "0px 10px" }}
                        onClick={() => increaseRepair(false)}
                        disabled={!showRepairSave}
                      >
                        -
                      </button>
                    </Col>
                    <Col md="2">
                      <button
                        type="button"
                        className="btn btn-outline-dark"
                        onClick={() => increaseRepair()}
                        disabled={!showRepairSave}
                      >
                        +
                      </button>
                    </Col>
                    <Col md="3">
                      {showRepairSave ? (
                        <button
                          type="button"
                          className="btn btn-success"
                          onClick={handleOnClickRepairSave}
                        >
                          Save
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => setShowRepairSave(true)}
                        >
                          Edit
                        </button>
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col md="4">
                  <Col md="12">
                    <h6>Time Between Project Management Start Times</h6>
                  </Col>
                  <Row>
                    <Col md="5">
                      <Form.Group
                      // style={styles.formInput}
                      >
                        <Form.Control
                          name="pType"
                          type="number"
                          value={additionalProjectmanagementTime}
                          disabled
                          onChange={(e) =>
                            setAdditionalProjectManagementTime(+e.target.value)
                          }
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col md="2">
                      <button
                        type="button"
                        className="btn btn-outline-dark"
                        style={{ margin: "0px 10px" }}
                        onClick={() => increaseProjectManager(false)}
                        disabled={!showProjectSave}
                      >
                        -
                      </button>
                    </Col>
                    <Col md="2">
                      <button
                        type="button"
                        className="btn btn-outline-dark"
                        onClick={() => increaseProjectManager()}
                        disabled={!showProjectSave}
                      >
                        +
                      </button>
                    </Col>
                    <Col md="3">
                      {showProjectSave ? (
                        <button
                          type="button"
                          className="btn btn-success"
                          onClick={handleOnClickProjectSave}
                        >
                          Save
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => setShowProjectSave(true)}
                        >
                          Edit
                        </button>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row style={{ marginBottom: "10%" }}>
                <Col md="4">
                  <Col md="12">
                    <h6>Sales Minimum Gap Time Between Appointments</h6>
                  </Col>
                  <Row>
                    <Col md="5">
                      <Form.Group
                      // style={styles.formInput}
                      >
                        <Form.Control
                          name="pType"
                          type="number"
                          value={additionalSalesGapTime}
                          disabled
                          onChange={(e) =>
                            setAdditionalSalesGapTime(+e.target.value)
                          }
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col md="2">
                      <button
                        type="button"
                        className="btn btn-outline-dark"
                        style={{ margin: "0px 10px" }}
                        onClick={() => increaseSalesGap(false)}
                        disabled={!showSalesGapSave}
                      >
                        -
                      </button>
                    </Col>
                    <Col md="2">
                      <button
                        type="button"
                        className="btn btn-outline-dark"
                        onClick={() => increaseSalesGap()}
                        disabled={!showSalesGapSave}
                      >
                        +
                      </button>
                    </Col>
                    <Col md="3">
                      {showSalesGapSave ? (
                        <button
                          type="button"
                          className="btn btn-success"
                          onClick={handleOnClickSalesGapSave}
                        >
                          Save
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => setShowSalesGapSave(true)}
                        >
                          Edit
                        </button>
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col md="4">
                  <Col md="12">
                    <h6>Repair Minimum Gap Time Between Appointments</h6>
                  </Col>
                  <Row>
                    <Col md="5">
                      <Form.Group
                      // style={styles.formInput}
                      >
                        <Form.Control
                          name="pType"
                          type="number"
                          value={additionalRepairGapTime}
                          disabled
                          onChange={(e) =>
                            setAdditionalRepairGapTime(+e.target.value)
                          }
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col md="2">
                      <button
                        type="button"
                        className="btn btn-outline-dark"
                        style={{ margin: "0px 10px" }}
                        onClick={() => increaseRepairGap(false)}
                        disabled={!showRepairGapSave}
                      >
                        -
                      </button>
                    </Col>
                    <Col md="2">
                      <button
                        type="button"
                        className="btn btn-outline-dark"
                        onClick={() => increaseRepairGap()}
                        disabled={!showRepairGapSave}
                      >
                        +
                      </button>
                    </Col>
                    <Col md="3">
                      {showRepairGapSave ? (
                        <button
                          type="button"
                          className="btn btn-success"
                          onClick={handleOnClickRepairGapSave}
                        >
                          Save
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => setShowRepairGapSave(true)}
                        >
                          Edit
                        </button>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          )}
        </div>
      </div>
    </MainComponent>
  );
};

export default SchedulingModal;
