import MainComponent from "components/MainComponent";
import React, { useState } from "react";
import { Col, FloatingLabel, Form, Row } from "react-bootstrap";
import Select from 'react-select';
import RichTextEditor, { EditorValue } from "react-rte";
import './textEditor.scss'
import useClientEmail from "customHooks/ClientEmail/useClientEmail";

let options = [
  { value: 'on_boarding', label: 'On Boarding' },
  { value: 'thirty_days_past_due', label: 'Thirty days passed due' },
  { value: 'sixty_days_past_due', label: 'Sixty Days Past due' },
  { value: 'subscription_payment_unsuccessful', label: 'Subscription Payment Failed on first of the month' },
  { value: 'additional_user_payment_unsuccessful', label: 'Additional users added payment failed' },
  { value: 'additional_user_payment_successful', label: 'Additional users payment successful' },
  { value: 'subscription_payment_successful', label: 'Subscription payment successful' },
  // { value: 'fifteen_days_past_due', label: ' Fifteen (15) Days Past Due' },
  { value: 'ninety_days_past_due', label: 'Ninety Days Past Due Deactivation' },
  { value: 'testing', label: 'Testing' },
]

const ClientEmailTemplate = () => {
  const initialHtml = "<p></p>";
  const [editSubject, setEditSubject] = useState(false);
  const [editBody, setEditBody] = useState(false);
  const [isClearable, setIsClearable] = useState(true);
  const [key, setKey] = useState()
  const [start, setStart] = useState()
  const [end, setend] = useState()
  const [editorValue, setEditorValue] = useState(
    RichTextEditor.createValueFromString(initialHtml, "html")
  );
  const [editorSubject, setEditorSubject] = useState()

  const { emailTemplate, isError, isLoading, edit_email } = useClientEmail()
  console.log({ emailTemplate })
  const handle_email_dropdown_change = (e) => {
    console.log({ e: e.value })
    setKey(e.value)
    let template_start = emailTemplate.filter(val => val.email_key === e.value)[0].email_value.split("<body>")
    let template_end = template_start[1].split("</body>")
    console.log({ template_start, template_end })
    setStart(template_start[0])
    setend(template_end[1])
    setEditorValue(RichTextEditor.createValueFromString(template_end[0], "html"))
    setEditorSubject(emailTemplate.filter(val => val.email_key === e.value)[0].email_subject)
  }

  const handleChange = (value) => {
    console.log({ value: value.toString("html") });
    setEditorValue(value);
  };

  const save_email_body_changes = () => {
    console.log(editorValue.toString("html"), key)
    edit_email({ email_key: key, email_value: `${start}<body>\n${editorValue.toString("html")}\n</body>${end}`, email_subject: editorSubject })
    setEditBody(!editBody)
  }

  const save_email_subject_changes = () => {
    edit_email({ email_key: key, email_subject: editorSubject })
    setEditSubject(!editSubject)
  }

  const handle_email_subject_change = (e) => {
    setEditorSubject(e.target.value)
  }




  return (
    <MainComponent>
      <div className="_container_right _user_page">
        <div className="__proposal">
          <Row>
            <Col sm={8} style={{ marginBottom: "1rem" }}>
              <h5>Select Email Template</h5>
              <Select options={options} isClearable={isClearable} onChange={(e) => handle_email_dropdown_change(e)} />
            </Col>
          </Row>
          <Row style={{ marginTop: "7rem" }}>
            <Col sm={8} style={{ marginBottom: "1rem" }}>
              <FloatingLabel controlId="floatingTextarea2" label="Email Subject">
                <Form.Control
                  as="textarea"
                  placeholder="Leave a comment here"
                  style={{ height: "10vh" }}
                  value={editorSubject ? editorSubject : ""}
                  onChange={handle_email_subject_change}
                  disabled={!editSubject}
                />
              </FloatingLabel>
            </Col>
            <Col sm={4} style={{ marginBottom: "1rem" }}>
              <Col>
                {!editSubject ? (
                  <Row style={{ width: "15rem" }}>
                    <Col>
                      <button
                        className="btn btn-primary"
                        onClick={() => setEditSubject(!editSubject)}
                      // disabled={!key}
                      >
                        Edit
                      </button>
                    </Col>
                    {/* <Col>
                      <button
                        className="btn btn-primary"
                        onClick={() => setEditSubject(!editSubject)}
                      >
                        Add
                      </button>
                    </Col> */}
                  </Row>
                ) : (
                  <Row style={{ width: "15rem" }}>
                    <Col>
                      <button
                        className="btn btn-primary"
                        onClick={save_email_subject_changes}
                      >
                        Save
                      </button>
                    </Col>
                    <Col>
                      <button
                        className="btn btn-danger"
                        onClick={() => setEditSubject(!editSubject)}
                      >
                        Cancel
                      </button>
                    </Col>
                  </Row>
                )}
              </Col>
            </Col>
          </Row>
          <Row style={{ marginTop: "5rem" }}>
            <Col sm={8} style={{ marginBottom: "1rem" }}>
              <RichTextEditor value={editorValue} onChange={handleChange} className="rich-text-editor" readOnly={!editBody} />
            </Col>
            <Col sm={4}>
              <Col>
                {!editBody ? (
                  <Row style={{ width: "15rem" }}>
                    <Col>
                      <button
                        className="btn btn-primary"
                        onClick={() => setEditBody(!editBody)}
                      // disabled={!key}
                      >
                        Edit
                      </button>
                    </Col>
                    {/* <Col>
                      <button
                        className="btn btn-primary"
                        onClick={() => setEditBody(!editBody)}
                      >
                        Add
                      </button>
                    </Col> */}
                  </Row>
                ) : (
                  <Row style={{ width: "15rem" }}>
                    <Col>
                      <button
                        className="btn btn-primary"
                        onClick={save_email_body_changes}
                      >
                        Save
                      </button>
                    </Col>
                    <Col>
                      <button
                        className="btn btn-danger"
                        onClick={() => setEditBody(!editBody)}
                      >
                        Cancel
                      </button>
                    </Col>
                  </Row>
                )}
              </Col>
            </Col>
          </Row>
        </div>
      </div>
    </MainComponent>
  );
};

export default ClientEmailTemplate;
