import {
  AddEmail,
  getAllEmail,
  getAllEmailDelete,
  getAllPhoneDelete,
  getAllPropertyDelete,
  getAllRelationshipDelete,
  getAllPhone,
  getAllProperty,
  getAllRelationship,
  AddProperty,
} from "api/api";
import InitialLoader from "components/InitilalLoader";
import MainComponent from "components/MainComponent";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import "../Subscription/Subscription.scss";
const Newlead = () => {
  const [add, setAdd] = useState("");
  const [email, setEmail] = useState("");
  const [selectEmail, setSelectEmail] = useState("");
  const [allEmail, setAllEmail] = useState([]);
  const [addPhone, setAddPhone] = useState("");
  const [phone, setPhone] = useState("");
  const [selectPhone, setSelectPhone] = useState("");
  const [allPhone, setAllPhone] = useState([]);
  const [addProperty, setAddProperty] = useState("");
  const [property, setProperty] = useState("");
  const [selectProperty, setSelectProperty] = useState("");
  const [allproperty, setAllProperty] = useState([]);
  const [addRelation, setAddRelation] = useState("");
  const [relation, setRelation] = useState("");
  const [selectRelation, setSelectRelation] = useState("");
  const [allrelation, setAllRelation] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const ClickOnEmail = () => {
    setAdd(!add);
  };
  const handleOnSelectEmail = (e) => {
    setSelectEmail(e.target.value);
  };

  const ClickOnPhone = () => {
    setAddPhone(!addPhone);
  };
  const handleOnSelectPhone = (e) => {
    setSelectPhone(e.target.value);
  };
  const ClickOnProperty = () => {
    setAddProperty(!addProperty);
  };
  const handleOnSelectProperty = (e) => {
    setSelectProperty(e.target.value);
  };
  const ClickOnRelation = () => {
    setAddRelation(!addRelation);
  };
  const handleOnSelectRelation = (e) => {
    setSelectRelation(e.target.value);
  };

  const handleOnClickSave = () => {
    setIsLoading(true);
    const body = {
      roleCategory: "email",
      roleType: email,
    };
    setAdd(!add);
    setEmail("");
    AddEmail("/admin/userrole/addemail", body)
      .then((response) => {
        setIsLoading(false);
        return { error: null, response };
      })
      .catch((error) => {
        setIsLoading(false);

        if (error.response) {
          return { error: error.response };
        }
      });
  };
  const handleOnClickSavePhone = () => {
    setIsLoading(true);

    const body = {
      roleCategory: "addphone",
      roleType: phone,
    };
    setAddPhone(!addPhone);
    setPhone("");
    AddEmail("/admin/userrole/addphone", body)
      .then((response) => {
        setIsLoading(false);

        return { error: null, response };
      })
      .catch((error) => {
        setIsLoading(false);

        if (error.response) {
          return { error: error.response };
        }
      });
  };
  const handleOnClickSaveProperty = () => {
    setIsLoading(true);

    const body = {
      roleCategory: "property",
      roleType: property,
    };
    setAddProperty(!AddProperty);
    setProperty("");

    AddEmail("/admin/userrole/addproperty", body)
      .then((response) => {
        setIsLoading(false);

        return { error: null, response };
      })
      .catch((error) => {
        setIsLoading(false);

        if (error.response) {
          return { error: error.response };
        }
      });
  };
  const handleOnClickSaveRelationship = () => {
    setIsLoading(true);

    const body = {
      roleCategory: "relationship",
      roleType: relation,
    };
    setAddRelation(!addRelation);
    setRelation("");
    AddEmail("/admin/userrole/addrelationship", body)
      .then((response) => {
        setIsLoading(false);

        return { error: null, response };
      })
      .catch((error) => {
        setIsLoading(false);

        if (error.response) {
          return { error: error.response };
        }
      });
  };
  useEffect(() => {
    getAllEmail("/admin/userrole/email")
      .then((response) => {
        setAllEmail(response?.data?.result);
        return { error: null, response };
      })
      .catch((error) => {
        if (error.response) {
          return { error: error.response };
        }
      });
  }, [isLoading]);

  useEffect(() => {
    getAllPhone("/admin/userrole/phone")
      .then((response) => {
        setAllPhone(response?.data.result);
        return { error: null, response };
      })
      .catch((error) => {
        if (error.response) {
          return { error: error.response };
        }
      });
  }, [isLoading]);
  useEffect(() => {
    getAllProperty("/admin/userrole/property")
      .then((response) => {
        setAllProperty(response?.data.result);
        return { error: null, response };
      })
      .catch((error) => {
        if (error.response) {
          return { error: error.response };
        }
      });
  }, [isLoading]);

  useEffect(() => {
    getAllRelationship("/admin/userrole/relationship")
      .then((response) => {
        setAllRelation(response?.data.result);
        return { error: null, response };
      })
      .catch((error) => {
        if (error.response) {
          return { error: error.response };
        }
      });
  }, [isLoading]);

  const handleOnClickDeleteEmail = () => {
    setIsLoading(true);

    const body = {
      isDeleted: true,
    };
    setAdd(add);
    setSelectEmail(!selectEmail);
    setAdd(false);
    getAllEmailDelete("/admin/userrole/deleteemail/", selectEmail, body)
      .then((response) => {
        setIsLoading(false);

        console.log("response", response);
        return { error: null, response };
      })
      .catch((error) => {
        setIsLoading(false);

        if (error.response) {
          return { error: error.response };
        }
      });
  };

  const handleOnClickDeletePhone = () => {
    setIsLoading(true);

    const body = {
      isDeleted: true,
    };
    setAddPhone(addPhone);
    setSelectPhone(!selectPhone);
    getAllPhoneDelete("/admin/userrole/deletephone/", selectPhone, body)
      .then((response) => {
        setIsLoading(false);

        return { error: null, response };
      })
      .catch((error) => {
        setIsLoading(false);

        if (error.response) {
          return { error: error.response };
        }
      });
  };
  const handleOnClickDeleteProperty = () => {
    setIsLoading(true);

    const body = {
      isDeleted: true,
    };
    setAddProperty(addProperty);
    setSelectProperty(!selectProperty);
    getAllPropertyDelete(
      "/admin/userrole/deleteproperty/",
      selectProperty,
      body
    )
      .then((response) => {
        setIsLoading(false);

        return { error: null, response };
      })
      .catch((error) => {
        setIsLoading(false);

        if (error.response) {
          return { error: error.response };
        }
      });
  };
  const handleOnClickDeleteRelationship = () => {
    setIsLoading(true);

    const body = {
      isDeleted: true,
    };
    setAddRelation(addRelation);
    setSelectRelation(!selectRelation);

    getAllRelationshipDelete(
      "/admin/userrole/deleterelationship/",
      selectRelation,
      body
    )
      .then((response) => {
        setIsLoading(false);

        return { error: null, response };
      })
      .catch((error) => {
        setIsLoading(false);

        if (error.response) {
          return { error: error.response };
        }
      });
  };
  const displaypTypeOptionsEmail = () => {
    const emailAllData = allEmail.filter((elem) => {
      return elem.isDeleted !== true;
    });
    return emailAllData?.map((elem, id) => {
      return (
        <option key={id} value={elem._id}>
          {elem.roleType}
        </option>
      );
    });
  };

  const displaypTypeOptionsPhone = () => {
    const phoneAllData = allPhone.filter((elem) => {
      return elem.isDeleted !== true;
    });
    return phoneAllData?.map((elem, id) => {
      return (
        <option key={id} value={elem._id}>
          {elem.roleType}
        </option>
      );
    });
  };

  const displaypTypeOptionsProperty = () => {
    const propertyeAllData = allproperty.filter((elem) => {
      return elem.isDeleted !== true;
    });
    return propertyeAllData?.map((elem, id) => {
      return (
        <option key={id} value={elem._id}>
          {elem.roleType}
        </option>
      );
    });
  };

  const displaypTypeOptionsRelation = () => {
    const relationAllData = allrelation.filter((elem) => {
      return elem.isDeleted !== true;
    });
    return relationAllData?.map((elem, id) => {
      return (
        <option key={id} value={elem._id}>
          {elem.roleType}
        </option>
      );
    });
  };

  return (
    <MainComponent>
      <div className="_container_right _user_page">
        <div className="__proposal">
          {isLoading ? (
            <InitialLoader />
          ) : (
            <div style={{ margin: "2% 10% 0" }}>
              <Row style={{ marginBottom: "10%" }}>
                <Col>
                  <Form.Group
                  // style={styles.formInput}
                  >
                    <Form.Label>Phone Type</Form.Label>
                    <Row>
                      <Col md="10">
                        {!addPhone ? (
                          <Form.Control
                            as="select"
                            name="pType"
                            // value={pType}
                            // isInvalid={errAt === "pType"}
                            onChange={handleOnSelectPhone}
                          >
                            <option value="">Choose...</option>

                            {displaypTypeOptionsPhone()}
                          </Form.Control>
                        ) : (
                          <Form.Control
                            name="pType"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                          ></Form.Control>
                        )}
                      </Col>
                      <Col md="2">
                        {!addPhone && !selectPhone ? (
                          <button
                            className="btn btn-primary"
                            onClick={ClickOnPhone}
                          >
                            Add
                          </button>
                        ) : !selectPhone ? (
                          <button
                            className="btn btn-success"
                            onClick={handleOnClickSavePhone}
                          >
                            Save
                          </button>
                        ) : (
                          <button
                            className="btn btn-danger"
                            onClick={handleOnClickDeletePhone}
                          >
                            Delete
                          </button>
                        )}
                      </Col>
                    </Row>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                  // style={styles.formInput}
                  >
                    <Form.Label>Email Type</Form.Label>
                    <Row>
                      <Col md="10">
                        {!add ? (
                          <Form.Control
                            as="select"
                            name="pType"
                            // value={pType}
                            // isInvalid={errAt === "pType"}
                            onChange={handleOnSelectEmail}
                          >
                            <option value="">Choose...</option>
                            {displaypTypeOptionsEmail()}
                          </Form.Control>
                        ) : (
                          <Form.Control
                            name="pType"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          ></Form.Control>
                        )}
                      </Col>
                      <Col md="2">
                        {!add && !selectEmail ? (
                          <button
                            className="btn btn-primary"
                            onClick={ClickOnEmail}
                          >
                            Add
                          </button>
                        ) : !selectEmail ? (
                          <button
                            className="btn btn-success"
                            onClick={handleOnClickSave}
                          >
                            Save
                          </button>
                        ) : (
                          <button
                            className="btn btn-danger"
                            onClick={handleOnClickDeleteEmail}
                          >
                            Delete
                          </button>
                        )}
                      </Col>
                    </Row>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                  // style={styles.formInput}
                  >
                    <Form.Label>Property Type</Form.Label>
                    <Row>
                      <Col md="10">
                        {!addProperty ? (
                          <Form.Control
                            as="select"
                            name="pType"
                            // value={pType}
                            // isInvalid={errAt === "pType"}
                            onChange={handleOnSelectProperty}
                          >
                            <option value="">Choose...</option>

                            {displaypTypeOptionsProperty()}
                          </Form.Control>
                        ) : (
                          <Form.Control
                            name="pType"
                            value={property}
                            onChange={(e) => setProperty(e.target.value)}
                          ></Form.Control>
                        )}
                      </Col>

                      <Col md="2">
                        {!addProperty && !selectProperty ? (
                          <button
                            className="btn btn-primary"
                            onClick={ClickOnProperty}
                          >
                            Add
                          </button>
                        ) : !selectProperty ? (
                          <button
                            className="btn btn-success"
                            onClick={handleOnClickSaveProperty}
                          >
                            Save
                          </button>
                        ) : (
                          <button
                            className="btn btn-danger"
                            onClick={handleOnClickDeleteProperty}
                          >
                            Delete
                          </button>
                        )}
                      </Col>
                    </Row>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                  // style={styles.formInput}
                  >
                    <Form.Label>Relationship</Form.Label>
                    <Row>
                      <Col md="10">
                        {!addRelation ? (
                          <Form.Control
                            as="select"
                            name="pType"
                            // value={pType}
                            // isInvalid={errAt === "pType"}
                            onChange={handleOnSelectRelation}
                          >
                            <option value="">Choose...</option>

                            {displaypTypeOptionsRelation()}
                          </Form.Control>
                        ) : (
                          <Form.Control
                            name="pType"
                            value={relation}
                            onChange={(e) => setRelation(e.target.value)}
                          ></Form.Control>
                        )}
                      </Col>
                      <Col md="2">
                        {!addRelation && !selectRelation ? (
                          <button
                            className="btn btn-primary"
                            onClick={ClickOnRelation}
                          >
                            Add
                          </button>
                        ) : !selectRelation ? (
                          <button
                            className="btn btn-success"
                            onClick={handleOnClickSaveRelationship}
                          >
                            Save
                          </button>
                        ) : (
                          <button
                            className="btn btn-danger"
                            onClick={handleOnClickDeleteRelationship}
                          >
                            Delete
                          </button>
                        )}
                      </Col>
                    </Row>
                  </Form.Group>
                </Col>
              </Row>
            </div>
          )}
        </div>
      </div>
    </MainComponent>
  );
};

export default Newlead;
