import MainComponent from "components/MainComponent";
import React, { useState, useEffect } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Col, Container, Row } from "react-bootstrap";
import { Form, ControlLabel } from "react-bootstrap";
import useAdminNotes from "customHooks/AdminNotes/useAdminNotes";
import AdminNotes from "./AdminNotes";
import IndivisualProfileTable from "./IndivisualProfileTable";
import useBilling from "customHooks/OrSubscriberAndBillingsHook/useBilling";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Dropdown } from "react-bootstrap";
import Card from 'react-bootstrap/Card';
import './UpcomingPaymentAccordion.css';
import Offcanvas from 'react-bootstrap/Offcanvas';
import InitialLoader from "components/InitilalLoader";

const styles = {
  container: {
    boxShadow: "0 0 10px #99999996",
    background: "#fff",
    fontWeight: "bold",
    // padding: "2em 1em",
  },
  heading: {
    flex: 1,
  },
  header: {
    padding: "25px 0",
  },
  formInput: {
    marginBottom: "25px",
  },
  formStyle: {
    // marginTop: "5%"
  },
};

const IndivisualProfile = (props) => {
  const {
    location: {
      state: {
        selected_product: [
          {
            _id,
            companyId,
            activate,
            paymentStatus,
            stripeCustomerId,
            company: [
              {
                companyName,
                address1,
                address2,
                city,
                phone1,
                state,
                companyEmail,
                createdOn,
                fax,
              },
            ],
            user: [
              {
                email
              }
            ],
          },
        ],
      },
    },
  } = props;
  console.log({ propsImage: props });
  const [delNotes, setDelNotes] = useState(false);
  const [addNotes, setAddNotes] = useState(false);
  const [note, setNote] = useState();
  const [notes, setNotes] = useState();
  const [onChangeNotes, setOnChangeNotes] = useState();
  const [active, setActive] = useState();
  const [deActivate, setDeActivate] = useState();
  const [show, setShow] = useState(false);
  const [statusShow, setStatusShow] = useState(false);
  const [payStatus, setPayStatus] = useState();
  const [upcomingInvoice, setUpcomingInvoice] = useState();
  const [showNotes, setShowNotes] = useState(false);
  const [totalLineAmount, setTotalLineAmount] = useState()
  const [totalLineAmountDate, setTotalLineAmountDate] = useState()  
  const [totalUser, setTotalUser] = useState()


  const handleCloseNotes = () => setShowNotes(false);
  const handleShowNotes = () => setShowNotes(true);
  const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];

  const { isLoading, isError, update_subscription_status, update_activate_status } = useBilling(companyId);
  console.log({isLoading})

  const handleClose = () => {
    setShow(false);
  };

  const handleStatusClose = () => {
    setStatusShow(false);
  };

  const handle_save_changes = () => {
    // update_subscription_status({
    //   companyId: companyId,
    //   paymentStatus: paymentStatus
    // })
    // setUpdateStatus(!updateStatus)
    update_activate_status({ companyId: companyId, activate: active }).then(res => {
      setShow(false);
    });
  };

  const handle_status_save_changes = () => {
    // update_subscription_status({
    //   companyId: companyId,
    //   paymentStatus: paymentStatus
    // })
    // setUpdateStatus(!updateStatus)
    // update_activate_status({companyId: companyId, activate: active})
    update_subscription_status({
      companyId: companyId,
      paymentStatus: payStatus,
    });
    setStatusShow(false);
  };
  const { post_admin_data, get_particular_company_details, data, loading } =
    useAdminNotes({ subscriber_id: _id });

  const { get_upcoming_invoice, historyData, companyBasePlan } = useBilling(companyId);

  const options = {
    weekday: "long",
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  useEffect(() => {
    let get_particular_data = async () => {
      console.log({ _id });
      let data = await get_particular_company_details({ subscriber_id: _id });
      setNotes(data);
      console.log({ dataImp: data });
    };
    get_particular_data();
  }, [addNotes]);

  useEffect(() => {
    get_upcoming_invoice(stripeCustomerId)
      .then((res) => {
        setUpcomingInvoice(res);
      })
      .catch((err) => {
        setUpcomingInvoice(null);
      });
  }, [stripeCustomerId, props, show ]);

  useEffect(() => {
    let total = 0
    upcomingInvoice && setTotalLineAmountDate(new Date(upcomingInvoice.period_start*1000).toDateString())
    upcomingInvoice &&  upcomingInvoice.lines.data.map((val,index) => {
      if(index !== upcomingInvoice.lines.data.length -1){
        total = total +  (Number(val.amount))
      }else{
        setTotalUser((val.amount/100 - 40) / 30);
      }
    })
    setTotalLineAmount(total)
  }, [historyData, upcomingInvoice])

console.log("historyData ", historyData);
  const handleDropdownSelect = (rowId, eventKey, row) => {
    // Perform any desired action based on the selected dropdown item here.
    setStatusShow(!show);
    setPayStatus(eventKey);
    // console.log({rowId,eventKey,row})
    // setUpdateStatus(!updateStatus)
  };

  if (isLoading) {
    return <InitialLoader />;
  }

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Company Activate Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to update the status</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handle_save_changes}>
            Update Status
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={statusShow} onHide={handleStatusClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Company Payment Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to update the status</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleStatusClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handle_status_save_changes}>
            Update Status
          </Button>
        </Modal.Footer>
      </Modal>
      <MainComponent>
        <Row>
          <div style={{ width: "100%", height: "100vh" }}>
            <div
              className="indivisual-profile-page"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div
                className="indivisual-profile-page-child1"
                style={{
                  width: "100%",
                  // border: "solid",
                  // height: "50vh",
                  borderBottom: "5px solid #E6E4F0",
                  // borderRadius: "10px",
                  marginTop: "1rem",
                  marginRight: "1rem",
                  marginLeft: "1rem",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  overflow: "scroll",
                  display: "flex",
                  flexDirection: "column",
                  // background: "#EEF0F2",
                  // boxShadow: "10px 10px lightblue"
                }}
              >
                <Button
                  variant="primary"
                  onClick={handleShowNotes}
                  style={{ width: "10rem", marginBottom: "1rem" }}
                >
                  Show Notes
                </Button>
                <div
                  style={{
                    width: "100%",
                    height: "20vh",
                    background: "#EEF0F2",
                    borderRadius: "5px",
                    boxShadow: "0px 4px 8px 0px #06031514",
                    overflow: "scroll",
                  }}
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div
                      style={{
                        paddingTop: "7rem",
                        paddingLeft: "2rem",
                      }}
                    >
                      <h3>{companyName}</h3>
                      <h6>{payStatus ? payStatus : paymentStatus}</h6>
                    </div>
                    <div
                      style={{
                        paddingTop: "7rem",
                        paddingRight: "2rem",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "14px",
                          fontWeight: "600",
                          lineHeight: "18px",
                          opacity: "0.7",
                        }}
                      >
                        Member Since
                      </p>
                      <p style={{}}>
                        {new Date(createdOn).toLocaleDateString(
                          "en-us",
                          options
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    marginTop: "1rem",
                    width: "100%",
                    height: "20vh",
                    boxShadow: "0px 4px 8px 0px #06031514",
                    position: "relative",
                    // display: "flex",
                    // justifyContent: "space-between"
                    overflow: "scroll",
                  }}
                >
                  <div>
                    <div style={{ paddingLeft: "2rem", position: "relative" }}>
                      <span
                        style={{
                          fontWeight: "500",
                          fontSize: "20px",
                          opacity: "0.7",
                        }}
                      >
                        Address:
                      </span>
                      <span
                        style={{
                          paddingLeft: "3rem",
                          color: "#BBBBBE",
                          fontSize: "20px",
                          position: "absolute",
                          left: "10rem",
                        }}
                      >
                        {address1} {address2}
                      </span>
                    </div>
                    <div style={{ paddingLeft: "2rem", position: "relative" }}>
                      <span
                        style={{
                          fontWeight: "500",
                          fontSize: "20px",
                          opacity: "0.7",
                        }}
                      >
                        City:
                      </span>
                      <span
                        style={{
                          paddingLeft: "3rem",
                          color: "#BBBBBE",
                          fontSize: "20px",
                          position: "absolute",
                          left: "10rem",
                        }}
                      >
                        {city}
                      </span>
                    </div>
                    <div style={{ paddingLeft: "2rem", position: "relative" }}>
                      <span
                        style={{
                          fontWeight: "500",
                          fontSize: "20px",
                          opacity: "0.7",
                        }}
                      >
                        State:
                      </span>
                      <span
                        style={{
                          marginLeft: "3rem",
                          color: "#BBBBBE",
                          fontSize: "20px",
                          position: "absolute",
                          left: "10rem",
                        }}
                      >
                        {state}
                      </span>
                    </div>
                    <div style={{ paddingLeft: "2rem", position: "relative" }}>
                      <span
                        style={{
                          fontWeight: "500",
                          fontSize: "20px",
                          opacity: "0.7",
                        }}
                      >
                        Phone:
                      </span>
                      <span
                        style={{
                          marginLeft: "3rem",
                          color: "#BBBBBE",
                          fontSize: "20px",
                          position: "absolute",
                          left: "10rem",
                        }}
                      >
                        {phone1}
                      </span>
                    </div>
                    <div style={{ paddingLeft: "2rem", position: "relative" }}>
                      <span
                        style={{
                          fontWeight: "500",
                          fontSize: "20px",
                          opacity: "0.7",
                        }}
                      >
                        Email:
                      </span>
                      <span
                        style={{
                          marginLeft: "3rem",
                          color: "#BBBBBE",
                          fontSize: "20px",
                          position: "absolute",
                          left: "10rem",
                        }}
                      >
                        {email ? email : "No email provided"}
                      </span>
                    </div>
                    <div style={{ paddingLeft: "2rem", position: "relative" }}>
                      <span
                        style={{
                          fontWeight: "500",
                          fontSize: "20px",
                          opacity: "0.7",
                        }}
                      >
                        Fax:
                      </span>
                      <span
                        style={{
                          marginLeft: "3rem",
                          color: "#BBBBBE",
                          fontSize: "20px",
                          position: "absolute",
                          left: "10rem",
                        }}
                      >
                        {phone1}
                      </span>
                    </div>
                  </div>
                  <div
                    style={{
                      float: "right",
                      position: "absolute",
                      top: "0",
                      right: "0",
                      margin: "10px",
                    }}
                  >
                    <div style={{ float: "right", marginRight: "5px" }}>
                      <div key="checkbox" className="mb-3">
                        <Form.Check
                          type="checkbox"
                          id={`check-api-$"checkbox"`}
                        >
                          <Form.Check.Input
                            type="checkbox"
                            checked={
                              deActivate == true
                                ? false
                                : active && active == true
                                ? true
                                : activate
                                ? true
                                : false
                            }
                            onChange={(e) => {
                              setActive(e.target.checked);
                              setDeActivate(e.target.checked ? false : true);
                              // update_activate_status({companyId: companyId, activate: e.target.checked})
                              setShow(true);
                            }}
                          />
                          <Form.Check.Label>{`Activate Subscription`}</Form.Check.Label>
                        </Form.Check>
                      </div>

                      <div key="checkbox" className="mb-3">
                        <Form.Check
                          type="checkbox"
                          id={`check-api-$"checkbox"`}
                        >
                          <Form.Check.Input
                            type="checkbox"
                            checked={
                              active == true
                                ? false
                                : deActivate && deActivate == true
                                ? true
                                : !activate
                                ? true
                                : false
                            }
                            onChange={(e) => {
                              setDeActivate(e.target.checked);
                              setActive(e.target.checked ? false : true);
                              setShow(true);
                              // update_activate_status({companyId: companyId, activate: false})
                            }}
                          />
                          <Form.Check.Label>{`De-activate Subscription`}</Form.Check.Label>
                        </Form.Check>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    marginTop: "1rem",
                    marginBottom: "1rem",
                    width: "100%",
                    height: "fit-content",
                    background: "#EEF0F2",
                    borderRadius: "5px",
                    boxShadow: "0px 4px 8px 0px #06031514",
                    padding: "1rem",
                  }}
                >
                  <div>
                    <h4 className="card-title" style={{ marginBottom: "1rem" }}>
                      {upcomingInvoice
                        ? `Upcoming Payment For ${
                            month[
                              new Date(
                                upcomingInvoice.created * 1000
                              ).getMonth()
                            ]
                          }`
                        : "No Upcoming Invoice"}
                    </h4>
                  </div>
                  <div className="card-body">
                    {upcomingInvoice ? (
                      <>
                        <p
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <strong>Period:</strong>{" "}
                          {`${new Date(
                            upcomingInvoice.lines.data[0].period.start * 1000
                          ).toDateString()} - ${new Date(
                            upcomingInvoice.lines.data[0].period.end * 1000
                          ).toDateString()}`}
                        </p>
                        {upcomingInvoice.lines?.data && (
                          <>
                            <ul className="list-unstyled">
                            <li
                                className="product-item"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <span className="product-description">
                                  <strong>
                                    {`Base Plan`}
                                    {" : "}
                                  </strong>
                                </span>
                                <span className="product-amount">
                                  ${companyBasePlan && companyBasePlan.baseplanPrice}
                                </span>
                              </li>

                              <li
                                className="product-item"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <span className="product-description">
                                  <strong>
                                    {`Additional Users`}
                                    {" : "}
                                  </strong>
                                </span>
                                <span className="product-amount">
                                  {companyBasePlan && companyBasePlan.additionalUser}
                                </span>
                              </li>

                              <li
                                className="product-item"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <span className="product-description">
                                  <strong>
                                    {`Additional Per User`}
                                    {" : "}
                                  </strong>
                                </span>
                                <span className="product-amount">
                                  ${companyBasePlan &&
                                    companyBasePlan.additionalUser *
                                      companyBasePlan.pricePerAdditionalUser}
                                </span>
                              </li>

                              <li
                                className="product-item"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <span className="product-description">
                                  <strong>
                                    {`Tax`}
                                    {" : "}
                                  </strong>
                                </span>
                                <span className="product-amount">
                                  ${`${
                                    companyBasePlan &&
                                    companyBasePlan.baseplanPrice &&
                                    historyData &&
                                    historyData.length &&
                                    historyData[0].total_tax &&
                                    ((companyBasePlan.baseplanPrice+companyBasePlan.additionalUser*companyBasePlan.pricePerAdditionalUser)*historyData[0].total_tax/100).toFixed(2)
                                  }`}
                                </span>
                              </li>

                              {/* {upcomingInvoice.lines.data.map((line, index) => {
                                if (
                                  index ===
                                  upcomingInvoice.lines.data.length - 1
                                ) {
                                  return (
                                    <li
                                      key={index}
                                      className="product-item"
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <span className="product-description">
                                        <strong>
                                          {line.description}
                                          {" : "}
                                        </strong>
                                      </span>
                                      <span className="product-amount">
                                        ${(line.amount / 100).toFixed(2)}
                                      </span>
                                    </li>
                                  );
                                }
                              })} */}

                            </ul>
                            <p
                              className="total-amount-due"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <strong>Total Amount Due:</strong>{" "}
                              <span className="total-amount">
                                ${(upcomingInvoice.amount_due / 100).toFixed(2)}
                              </span>
                            </p>
                          </>
                        )}
                      </>
                    ) : (
                      <p className="empty-message">
                        No upcoming invoice available.
                      </p>
                    )}
                  </div>
                </div>
                <div
                  style={{
                    marginTop: "1rem",
                    width: "100%",
                    boxShadow: "0px 4px 8px 0px #06031514",
                  }}
                >
                  <div
                    style={{
                      marginBottom: "15px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      <h4>Previous Payments</h4>
                    </div>
                    <div style={{ display: "flex" }}>
                      <h5 className="me-2">OR STATUS:</h5>
                      <Dropdown
                        onSelect={(eventKey, event) =>
                          handleDropdownSelect(event, eventKey)
                        }
                      >
                        <Dropdown.Toggle
                          // id={`dropdown-${row.companyId}`}
                          style={{ backgroundColor: "#0d6efd" }}
                        >
                          {payStatus ? payStatus : paymentStatus}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item eventKey="Paid">Paid</Dropdown.Item>
                          <Dropdown.Item eventKey="Unpaid">
                            Unpaid
                          </Dropdown.Item>
                          <Dropdown.Item eventKey="Waived/Comp">
                            Waived/Comp
                          </Dropdown.Item>
                          <Dropdown.Item eventKey="Frozen 1">
                            Frozen 1
                          </Dropdown.Item>
                          <Dropdown.Item eventKey="Frozen 2">
                            Frozen 2
                          </Dropdown.Item>
                          {/* <Dropdown.Item eventKey="Deactivated/Unsubscribed">
                            Deactivated/Unsubscribed
                          </Dropdown.Item> */}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                  <IndivisualProfileTable
                    selected_product={props?.location?.state?.selected_product}
                  />
                </div>
              </div>
              <Offcanvas
                show={showNotes}
                onHide={handleCloseNotes}
                placement={"end"}
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title>Notes Section</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <div
                    style={{
                      width: "100%",
                      // border: "10",
                      // borderStyle: "black",
                      height: "100vh",
                      // borderRadius: "10px",
                      // marginTop: "0",
                      marginRight: "1rem",
                      padding: "20px",
                      overflow: "scroll",
                      borderLeft: "5px solid #E6E4F0",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        borderBottom: "3px solid #E6E4F0",
                      }}
                    >
                      <h5
                        style={{
                          margin: "5px",
                          fontSize: "1.5rem",
                          opacity: "0.7",
                        }}
                      >
                        Notes
                      </h5>
                      <button
                        // style={{ margin: "5px" }}
                        className="btn btn-primary"
                        style={{ margin: "5px" }}
                        onClick={() => {
                          setAddNotes(true);
                        }}
                      >
                        Add Notes
                      </button>
                    </div>
                    <CSSTransition
                      in={addNotes}
                      timeout={300}
                      // classNames="list_item"
                      unmountOnExit
                    >
                      <>
                        <Row>
                          <Col md="12" sm="12" style={{ padding: "20" }}>
                            <Form.Group style={styles.formInput}>
                              <Form.Label></Form.Label>
                              <Form.Control
                                as="textarea"
                                name={""}
                                onChange={(e) => {
                                  setNote(e.target.value);
                                }}
                              ></Form.Control>
                            </Form.Group>
                          </Col>

                          <Col
                            md="12"
                            sm="12"
                            gap="2"
                            style={{ display: "flex", justifyContent: "end" }}
                          >
                            <button
                              type="button"
                              className="btn btn-primary"
                              style={{ marginTop: "2rem", marginLeft: "1rem" }}
                              onClick={() => {
                                post_admin_data({
                                  subscriber_id: _id,
                                  notes: note,
                                }).then(() => {
                                  setAddNotes(!addNotes);
                                });
                              }}
                            >
                              Save
                            </button>
                            <button
                              type="button"
                              className="btn btn-warning"
                              style={{
                                marginTop: "2rem",
                                marginLeft: "2.5rem",
                              }}
                              onClick={() => {
                                setAddNotes(!addNotes);
                              }}
                            >
                              Cancel
                            </button>
                          </Col>
                        </Row>
                      </>
                    </CSSTransition>
                    <div>
                      {loading ? <InitialLoader /> : notes && notes.length && <AdminNotes note={notes} />}
                      {/* {notes.length
                    ? notes.map((note, index) => {
                        return (
                          <Row>
                            <Col
                              md="12"
                              sm="12"
                              style={{
                                borderRadius: "5px",
                                height: "5rem",
                                marginTop: "1rem",
                                background: `${
                                  index == 0
                                    ? `#FEDC97`
                                    : index % 2 == 0
                                    ? "#AFD0D6"
                                    : index % 2 !== 0 && index % 3 == 0
                                    ? "#A5D8FF"
                                    : "#B8B8FF"
                                }`,
                                position: "relative",
                              }}
                            >
                              <span style={{ paddingTop: "5px", fontWeight: "800", fontSize: "1.5rem" }}>
                                {note?.notes}
                              </span>
                              <div>
                                <span
                                  style={{ position: "absolute", bottom: "0" }}
                                >
                                  {new Date(note?.createdAt).toUTCString()}
                                </span>
                              </div>
                            </Col>
                          </Row>
                        );
                      })
                    : "No notes available"} */}
                    </div>
                  </div>
                </Offcanvas.Body>
              </Offcanvas>
            </div>
          </div>
        </Row>
      </MainComponent>
    </>
  );
};

export default IndivisualProfile;
