import {
  AddEmail,
  getAllEmail,
  getAllEmailDelete,
  getAllPhoneDelete,
  getAllPropertyDelete,
  getAllRelationshipDelete,
  getAllPhone,
  getAllProperty,
  getAllRelationship,
  AddProperty,
  addMaterialPersonel,
} from "api/api";
import InitialLoader from "components/InitilalLoader";
import MainComponent from "components/MainComponent";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import "../Subscription/Subscription.scss";
import useMaterialPersonelHook from "customHooks/getAPISHook/useMaterialPersonelHook";
import useSubCrewsPersonelHook from "customHooks/getAPISHook/useSubCrewsPersonelHook";
import useCartingPersonelHook from "customHooks/getAPISHook/useCartingPersonelHook";
import { Modal, ModalHeader, ModalFooter, Button } from "reactstrap";
const Personel = () => {
  const [add, setAdd] = useState("");
  const [email, setEmail] = useState("");
  const [selectEmail, setSelectEmail] = useState("");
  const [allEmail, setAllEmail] = useState([]);
  const [addPhone, setAddPhone] = useState("");
  const [phone, setPhone] = useState("");
  const [selectPhone, setSelectPhone] = useState("");
  const [allPhone, setAllPhone] = useState([]);
  const [addProperty, setAddProperty] = useState("");
  const [property, setProperty] = useState("");
  const [selectProperty, setSelectProperty] = useState("");
  const [allproperty, setAllProperty] = useState([]);
  const [addRelation, setAddRelation] = useState("");
  const [relation, setRelation] = useState("");
  const [selectRelation, setSelectRelation] = useState("");
  const [allrelation, setAllRelation] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [typeOfDeleteOp, setTypeOfDeleteOp] = useState("");
  const [isDelete, setIsDelete] = useState(false);
  const { materialData, addPersonelsMaterial } =
    useMaterialPersonelHook(isLoading);
  const { subcrewData, addPersonelsSubcrews } =
    useSubCrewsPersonelHook(isLoading);
  const { cartingData, addPersonelsCarting } =
    useCartingPersonelHook(isLoading);

  function toggle() {
    setIsDelete(!isDelete);
  }
  console.log("isDelete", isDelete);
  const deleteModal = () => {
    return (
      <Modal isOpen={isDelete} toggle={toggle}>
        <ModalHeader toggle={toggle}>Are you sure to delete it?</ModalHeader>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              deleteRespectiveOptions();
            }}
          >
            Delete
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  const handleOnDeleteMaterials = () => {
    setTypeOfDeleteOp("material");
    setIsDelete(!isDelete);
  };
  const handleOnDeleteCrews = () => {
    setTypeOfDeleteOp("crew");
    setIsDelete(!isDelete);
  };
  const handleOnDeleteCarting = () => {
    setTypeOfDeleteOp("carting");
    setIsDelete(!isDelete);
  };
  const deleteRespectiveOptions = () => {
    if (typeOfDeleteOp === "material") handleOnClickDeletePhone();
    if (typeOfDeleteOp === "crew") handleOnClickDeleteEmail();

    if (typeOfDeleteOp === "carting") handleOnClickDeleteProperty();
    toggle();
  };

  const ClickOnEmail = () => {
    setAdd(!add);
  };
  const handleOnSelectEmail = (e) => {
    setSelectEmail(e.target.value);
  };

  const ClickOnPhone = () => {
    setAddPhone(!addPhone);
  };
  const handleOnSelectPhone = (e) => {
    setSelectPhone(e.target.value);
  };
  const ClickOnProperty = () => {
    setAddProperty(!addProperty);
  };
  const handleOnSelectProperty = (e) => {
    setSelectProperty(e.target.value);
  };
  const ClickOnRelation = () => {
    setAddRelation(!addRelation);
  };
  const handleOnSelectRelation = (e) => {
    setSelectRelation(e.target.value);
  };

  const handleOnClickDeleteEmail = () => {
    setIsLoading(true);

    const body = {
      isDeleted: true,
    };
    setAdd(add);
    setSelectEmail(!selectEmail);
    setAdd(false);
    getAllEmailDelete("/supplier/deleteSubcrewsPersonel/", selectEmail, body)
      .then((response) => {
        setIsLoading(false);

        console.log("response", response);
        return { error: null, response };
      })
      .catch((error) => {
        setIsLoading(false);

        if (error.response) {
          return { error: error.response };
        }
      });
  };

  const handleOnClickDeletePhone = () => {
    setIsLoading(true);

    const body = {
      isDeleted: true,
    };
    setAddPhone(addPhone);
    setSelectPhone(!selectPhone);
    getAllPhoneDelete("/supplier/deleteMaterialPersonel/", selectPhone, body)
      .then((response) => {
        setIsLoading(false);

        return { error: null, response };
      })
      .catch((error) => {
        setIsLoading(false);

        if (error.response) {
          return { error: error.response };
        }
      });
  };
  const handleOnClickDeleteProperty = () => {
    setIsLoading(true);

    const body = {
      isDeleted: true,
    };
    setAddProperty(addProperty);
    setSelectProperty(!selectProperty);
    getAllPropertyDelete(
      "/supplier/deleteCartingPersonel/",
      selectProperty,
      body
    )
      .then((response) => {
        setIsLoading(false);

        return { error: null, response };
      })
      .catch((error) => {
        setIsLoading(false);

        if (error.response) {
          return { error: error.response };
        }
      });
  };
  const handleOnClickDeleteRelationship = () => {
    setIsLoading(true);

    const body = {
      isDeleted: true,
    };
    setAddRelation(addRelation);
    setSelectRelation(!selectRelation);

    getAllRelationshipDelete(
      "/admin/userrole/deleterelationship/",
      selectRelation,
      body
    )
      .then((response) => {
        setIsLoading(false);

        return { error: null, response };
      })
      .catch((error) => {
        setIsLoading(false);

        if (error.response) {
          return { error: error.response };
        }
      });
  };
  const displaypTypeOptionsEmail = () => {
    const emailAllData = subcrewData.filter((elem) => {
      return elem.isDeleted !== true;
    });
    return emailAllData.map((elem) => {
      return (
        <option key={elem._id} value={elem._id}>
          {elem.personelPosition}
        </option>
      );
    });
  };

  const displaypTypeOptionsPhone = () => {
    const phoneAllData = materialData.filter((elem) => {
      return elem.isDeleted !== true;
    });
    return phoneAllData.map((elem) => {
      return (
        <option key={elem._id} value={elem._id}>
          {elem.personelPosition}
        </option>
      );
    });
  };

  const displaypTypeOptionsProperty = () => {
    const propertyeAllData = cartingData.filter((elem) => {
      return elem.isDeleted !== true;
    });
    return propertyeAllData.map((elem) => {
      return (
        <option key={elem._id} value={elem._id}>
          {elem.personelPosition}
        </option>
      );
    });
  };

  const displaypTypeOptionsRelation = () => {
    const relationAllData = cartingData.filter((elem) => {
      return elem.isDeleted !== true;
    });
    return relationAllData.map((elem) => {
      return (
        <option key={elem._id} value={elem._id}>
          {elem.personelPosition}
        </option>
      );
    });
  };
  return (
    <MainComponent>
      {isDelete && deleteModal()}
      <div className="_container_right _user_page">
        <div className="__proposal">
          {isLoading ? (
            <InitialLoader />
          ) : (
            <div style={{ margin: "2% 10% 0" }}>
              <Row style={{ marginBottom: "10%" }}>
                <Col>
                  <Form.Group
                  // style={styles.formInput}
                  >
                    <Form.Label>Material Personnel Position</Form.Label>
                    <Row>
                      <Col md="10">
                        {!addPhone ? (
                          <Form.Control
                            as="select"
                            name="pType"
                            // value={pType}
                            // isInvalid={errAt === "pType"}
                            onChange={handleOnSelectPhone}
                          >
                            <option value="">Choose...</option>

                            {displaypTypeOptionsPhone()}
                          </Form.Control>
                        ) : (
                          <Form.Control
                            name="pType"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                          ></Form.Control>
                        )}
                      </Col>
                      <Col md="2">
                        {!addPhone && !selectPhone ? (
                          <button
                            className="btn btn-primary"
                            onClick={ClickOnPhone}
                          >
                            Add
                          </button>
                        ) : !selectPhone ? (
                          <button
                            className="btn btn-success"
                            onClick={() =>
                              addPersonelsMaterial(
                                phone,
                                setPhone,
                                addPhone,
                                setAddPhone,
                                setIsLoading
                              )
                            }
                          >
                            Save
                          </button>
                        ) : (
                          <button
                            className="btn btn-danger"
                            onClick={handleOnDeleteMaterials}
                          >
                            Delete
                          </button>
                        )}
                      </Col>
                    </Row>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                  // style={styles.formInput}
                  >
                    <Form.Label>Sub/Crews Personnel Position</Form.Label>
                    <Row>
                      <Col md="10">
                        {!add ? (
                          <Form.Control
                            as="select"
                            name="pType"
                            // value={pType}
                            // isInvalid={errAt === "pType"}
                            onChange={handleOnSelectEmail}
                          >
                            <option value="">Choose...</option>
                            {displaypTypeOptionsEmail()}
                          </Form.Control>
                        ) : (
                          <Form.Control
                            name="pType"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          ></Form.Control>
                        )}
                      </Col>
                      <Col md="2">
                        {!add && !selectEmail ? (
                          <button
                            className="btn btn-primary"
                            onClick={ClickOnEmail}
                          >
                            Add
                          </button>
                        ) : !selectEmail ? (
                          <button
                            className="btn btn-success"
                            onClick={() =>
                              addPersonelsSubcrews(
                                email,
                                setEmail,
                                add,
                                setAdd,
                                setIsLoading
                              )
                            }
                          >
                            Save
                          </button>
                        ) : (
                          <button
                            className="btn btn-danger"
                            onClick={handleOnDeleteCrews}
                          >
                            Delete
                          </button>
                        )}
                      </Col>
                    </Row>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                  // style={styles.formInput}
                  >
                    <Form.Label>Carting Personnel Position</Form.Label>
                    <Row>
                      <Col md="10">
                        {!addProperty ? (
                          <Form.Control
                            as="select"
                            name="pType"
                            // value={pType}
                            // isInvalid={errAt === "pType"}
                            onChange={handleOnSelectProperty}
                          >
                            <option value="">Choose...</option>

                            {displaypTypeOptionsProperty()}
                          </Form.Control>
                        ) : (
                          <Form.Control
                            name="pType"
                            value={property}
                            onChange={(e) => setProperty(e.target.value)}
                          ></Form.Control>
                        )}
                      </Col>

                      <Col md="2">
                        {!addProperty && !selectProperty ? (
                          <button
                            className="btn btn-primary"
                            onClick={ClickOnProperty}
                          >
                            Add
                          </button>
                        ) : !selectProperty ? (
                          <button
                            className="btn btn-success"
                            onClick={() =>
                              addPersonelsCarting(
                                property,
                                setProperty,
                                addProperty,
                                setAddProperty,
                                setIsLoading
                              )
                            }
                          >
                            Save
                          </button>
                        ) : (
                          <button
                            className="btn btn-danger"
                            onClick={handleOnDeleteCarting}
                          >
                            Delete
                          </button>
                        )}
                      </Col>
                    </Row>
                  </Form.Group>
                </Col>
                {/* <Col>
                  <Form.Group
                  // style={styles.formInput}
                  >
                    <Form.Label>Sub/Crews Services</Form.Label>
                    <Row>
                      <Col md="10">
                        {!addRelation ? (
                          <Form.Control
                            as="select"
                            name="pType"
                            // value={pType}
                            // isInvalid={errAt === "pType"}
                            onChange={handleOnSelectRelation}
                          >
                            <option value="">Choose...</option>

                            {displaypTypeOptionsRelation()}
                          </Form.Control>
                        ) : (
                          <Form.Control
                            name="pType"
                            value={relation}
                            onChange={(e) => setRelation(e.target.value)}
                          ></Form.Control>
                        )}
                      </Col>
                      <Col md="2">
                        {!addRelation && !selectRelation ? (
                          <button
                            className="btn btn-primary"
                            onClick={ClickOnRelation}
                          >
                            Add
                          </button>
                        ) : !selectRelation ? (
                          <button
                            className="btn btn-success"
                            onClick={handleOnClickSaveRelationship}
                          >
                            Save
                          </button>
                        ) : (
                          <button
                            className="btn btn-danger"
                            onClick={handleOnClickDeleteRelationship}
                          >
                            Delete
                          </button>
                        )}
                      </Col>
                    </Row>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group
                  // style={styles.formInput}
                  >
                    <Form.Label>Carting Services</Form.Label>
                    <Row>
                      <Col md="10">
                        {!addRelation ? (
                          <Form.Control
                            as="select"
                            name="pType"
                            // value={pType}
                            // isInvalid={errAt === "pType"}
                            onChange={handleOnSelectRelation}
                          >
                            <option value="">Choose...</option>

                            {displaypTypeOptionsRelation()}
                          </Form.Control>
                        ) : (
                          <Form.Control
                            name="pType"
                            value={relation}
                            onChange={(e) => setRelation(e.target.value)}
                          ></Form.Control>
                        )}
                      </Col>
                      <Col md="2">
                        {!addRelation && !selectRelation ? (
                          <button
                            className="btn btn-primary"
                            onClick={ClickOnRelation}
                          >
                            Add
                          </button>
                        ) : !selectRelation ? (
                          <button
                            className="btn btn-success"
                            onClick={handleOnClickSaveRelationship}
                          >
                            Save
                          </button>
                        ) : (
                          <button
                            className="btn btn-danger"
                            onClick={handleOnClickDeleteRelationship}
                          >
                            Delete
                          </button>
                        )}
                      </Col>
                    </Row>
                  </Form.Group>
                </Col> */}
              </Row>
            </div>
          )}
        </div>
      </div>
    </MainComponent>
  );
};

export default Personel;
