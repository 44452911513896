import MainComponent from "components/MainComponent";
import React, { useCallback } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { debounce } from "lodash";
import "../Subscription/Subscription.scss";
import useSetup from "customHooks/useSetup/useSetup";
import { randomKey } from "../../utils/utils";
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const Setup = () => {
  const {
    selectCategory,
    setSelectCategory,
    setCategory,
    category,
    addCategory,
    setAddCategory,
    displaypTypeOptionsScreen,
    setUploadVideo,
    uploadVideo,
    UploadVideos,
    addSubCategoeies,
    allVideo,
    description,
    setDesCription,
    progressBar,
    deleteIndivisualVideo,
    setIsDelete,
    isDelete,
  } = useSetup();
  const [videoId, setVideoId] = useState("");
  function toggle() {
    setIsDelete(!isDelete);
  }
  const geiId = (id) => {
    setVideoId(id);
  };
  console.log("description", description);
  const saveToDb = (value, subscreen) => {
    //     let descriptionNew = {
    //       ...description,
    // ,
    //     };
    setDesCription((prevValue) => {
      return {
        ...prevValue,
        ...{ [subscreen.trim().split(" ").join("_").toLowerCase()]: value },
      };
    });
  };

  console.log("descriptionNew ", description);
  const debouncedSave = useCallback(
    debounce((nextValue, subscreen) => saveToDb(nextValue, subscreen), 1500),
    [] // will be created only once initially
  );

  const deleteModal = () => {
    return (
      <Modal
        isOpen={isDelete}
        toggle={toggle}
        // className={props.className}
      >
        <ModalHeader toggle={toggle}>Are you sure to delete it?</ModalHeader>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => deleteIndivisualVideo(videoId)}
            // onClick={() => deleteData(_id)}
          >
            Delete
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  const handleDescription = (e, subscreen) => {
    const { value } = e.target;
    debouncedSave(value, subscreen);
  };

  return (
    <>
      {isDelete && deleteModal()}
      <MainComponent>
        <div className="_container_right _user_page">
          <div className="__proposal">
            <Row style={{ marginBottom: "5%" }}>
              <Col>
                <Form.Group>
                  <Form.Label>Select Screen</Form.Label>
                  <Row>
                    <Col md="3">
                      {!addCategory ? (
                        <Form.Control
                          as="select"
                          name="pType"
                          value={selectCategory}
                          onChange={(e) => setSelectCategory(e.target.value)}
                        >
                          <option value="">Choose...</option>
                          <option value="Application Settings">
                            Application Settings
                          </option>
                          <option value="Supplier Material & Labor">
                            Supplier Material & Labor
                          </option>

                          {/* {displaypTypeOptionsScreen()} */}
                        </Form.Control>
                      ) : (
                        <Form.Control
                          name="pType"
                          value={category}
                          onChange={(e) => setCategory(e.target.value)}
                        ></Form.Control>
                      )}
                    </Col>
                    <Col md="3">
                      {!addCategory ? (
                        <button
                          className="btn btn-primary"
                          onClick={() => setAddCategory(!addCategory)}
                          disabled={!selectCategory}
                        >
                          New Category
                        </button>
                      ) : (
                        <button
                          className="btn btn-success"
                          onClick={addSubCategoeies}
                          disabled={!category}
                        >
                          Save
                        </button>
                      )}
                      {!selectCategory && addCategory && (
                        <button
                          className="btn btn-danger"
                          onClick={() => setAddCategory(false)}
                          style={{ marginLeft: "2%" }}
                        >
                          Cancel
                        </button>
                      )}
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
            </Row>
            {selectCategory && progressBar > 0 && progressBar !== 100 && (
              <div className="progress" style={{ marginBottom: "3%" }}>
                <div
                  className="progress-bar"
                  role="progressbar"
                  aria-valuenow="70"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style={{ width: `${progressBar}%` }}
                >
                  <span className="sr-only">{progressBar}% Complete</span>
                </div>
              </div>
            )}

            {allVideo?.length > 0
              ? allVideo?.map((data) => {
                  return (
                    <Row key={randomKey}>
                      <Col md="3">
                        {" "}
                        <h4>{data?.categoryType}</h4>{" "}
                      </Col>
                      <Col md="3">
                        <input
                          // ref={inputRef}
                          // value={uploadVideo}
                          className="VideoInput_input"
                          type="file"
                          onChange={(e) => setUploadVideo(e.target.files[0])}
                          accept=".mp4"
                        />
                      </Col>
                      <Col md="3">
                        <Form.Group>
                          <Form.Control
                            id={data?.categoryType}
                            // value={description}
                            name="pType"
                            value={
                              description[
                                data?.categoryType
                                  .trim()
                                  .split(" ")
                                  .join("_")
                                  .toLowerCase()
                              ]
                            }
                            onChange={(e) =>
                              handleDescription(e, data?.categoryType)
                            }
                            placeholder="add descriptions"
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col md="2">
                        <button
                          className="btn btn-primary"
                          onClick={() =>
                            UploadVideos(data?._id, data?.categoryType)
                          }
                          disabled={
                            !description[
                              data?.categoryType
                                .trim()
                                .split(" ")
                                .join("_")
                                .toLowerCase()
                            ] ||
                            !uploadVideo ||
                            (progressBar > 0 && progressBar !== 100)
                          }
                        >
                          Upload
                        </button>

                        {/* <input
                        className="VideoInput_input"
                        type="file"
                        onChange={(e) => setUploadVideo(e.target.files[0])}
                        accept=".mov,.mp4,image/png, image/jpeg"
                        style={{ display: "none" }}
                        id="contained-button-file"
                      />
                      <label htmlFor="contained-button-file">
                        <div className="btn-wrap " htmlFor="contained-button-file">
                          {uploadVideo ? (
                            <button
                              className="btn btn-success"
                              onClick={() => UploadVideos(data?.category)}
                              disabled={!description}
                            >
                              Save Upload
                            </button>
                          ) : (
                            <a
                              className="btn btn-primary "
                            >
                              Upload
                            </a>
                          )}
                        </div>
                      </label> */}
                      </Col>
                      <Row
                        style={
                          data?.videoInfo?.length > 0
                            ? { marginTop: "2%" }
                            : {
                                height: "230px",
                                width: "100%",
                              }
                        }
                      >
                        <Carousel
                          swipeable={false}
                          draggable={false}
                          // showDots={true}
                          key={randomKey}
                          responsive={responsive}
                          ssr={true} // means to render carousel on server-side.
                          // infinite={true}
                          autoPlaySpeed={1000}
                          keyBoardControl={true}
                          customTransition="all .5"
                          transitionDuration={500}
                          containerclassName="carousel-container"
                          // removeArrowOnDeviceType={["tablet", "mobile"]}
                          deviceType="desktop "
                          dotListclassName="custom-dot-list-style"
                          itemclassName="carousel-item-padding-10-px"
                        >
                          {data?.videoInfo?.length > 0 ? (
                            data?.videoInfo?.map((res) => {
                              return (
                                <Col md="3" style={{ marginBottom: "10%" }} key={randomKey}>
                                  <div className="owl-carousel owl-theme">
                                    <div className="item">
                                      <p style={{ marginTop: "3%" }}>
                                        {res?.descriptions}
                                      </p>

                                      <video width="290" height="170" controls>
                                        <source
                                          src={res?.url}
                                          type="video/mp4"
                                        />
                                        <source
                                          src={res?.url}
                                          type="video/ogg"
                                        />
                                      </video>
                                      {/* <div>
                                        <div className="item">
                                          <iframe
                                            src={`${res?.url}`}
                                            // src="https://drive.google.com/file/d/13jbPnb1Y3jbFfwqiaDas_grSYyq8MVsU/preview"
                                            width="100%"
                                            height="170"
                                          ></iframe>
                                        </div>
                                      </div> */}
                                      <div style={{ textAlign: "center" }}>
                                        <button
                                          className="btn btn-danger"
                                          onClick={() => {
                                            setIsDelete(!isDelete);
                                            toggle();
                                            geiId(res?.videoId);
                                          }}
                                        >
                                          delete
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                              );
                            })
                          ) : (
                            <h5
                              style={{ textAlign: "center", marginTop: "7%" }}
                            >
                              No video added yet
                            </h5>
                          )}
                        </Carousel>
                      </Row>
                    </Row>
                  );
                })
              : selectCategory && (
                  <h5 style={{ textAlign: "center" }}>No data Found</h5>
                )}
          </div>
        </div>
      </MainComponent>
    </>
  );
};

export default Setup;
