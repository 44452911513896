/* eslint-disable react/prop-types */
import React from 'react';
import LoadingLoader from 'components/LoadingLoader';
import {
  Button,
} from 'react-bootstrap';

export default function InitialLoader({ error, onReload }) {
  return (

    <>
    
      <LoadingLoader />
      {error && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            maxWidth: '307px',
            margin: 'auto',
          }}
        >
          <p
            style={{
              textAlign: 'center',
              fontSize: '1.2em',
              fontWeight: 'bold',
              color: '#999',
            }}
          >
            {error}
          </p>
          <Button onClick={onReload}>Retry</Button>
        </div>
      )}
    </>
  );
}
