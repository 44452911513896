import { combineReducers } from "redux";
import {
  AutoTextFetchReducer,
  StandardStatementsFetchReducer,
} from "./auto-text/auto-text.reducer";
import { staticAllReducer } from "./static-all/static-all.reducer";

import { LoginReducer, GetUsersReducer } from "./user/user.reducer";

const rootReducer = combineReducers({
  login: LoginReducer,
  staticAll: staticAllReducer,
  standardStatements: StandardStatementsFetchReducer,
  autoTexts: AutoTextFetchReducer,
});

export default rootReducer;
