import { axios } from "services";
import { staticAllTypes } from "./static-all.types";

export const fetchStaticAllSuccess = (data) => ({
  type: staticAllTypes.STATIC_ALL_SUCCESS,
  payload: data,
});

export const fetchStaticAllFailure = (errors) => ({
  type: staticAllTypes.STATIC_ALL_FAILURE,
  payload: errors,
});

export const fetchStaticAllStart = () => ({
  type: staticAllTypes.STATIC_ALL_START,
});

export const fetchStaticAll = () => {
  return (dispatch) => {
    dispatch(fetchStaticAllStart());

    return axios
      .get(`/static/all`)
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          dispatch(fetchStaticAllSuccess(data));
        } else {
          dispatch(fetchStaticAllFailure(data));
        }
      })
      .catch((err) => dispatch(fetchStaticAllFailure(err)));
  };
};
