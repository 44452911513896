import MainComponent from "components/MainComponent";
import useNotification from "customHooks/useNotification/useNotification";
import moment from "moment/moment";
import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import "../Subscription/Subscription.scss";
import Box from "@mui/material/Box";
import { MultiSelect } from "react-multi-select-component";
import Modal from "@mui/material/Modal";
import { styyle } from "utils/utils";
import { ImCross } from "react-icons/im";
import InitialLoader from "components/InitilalLoader";
import { randomKey } from "../../utils/utils";

const Notification = () => {
  const {
    setTextMessage,
    textMessage,
    setSelectSend,
    handleOnClicksendMesageInApp,
    handleOnClicksendMesageInEmail,
    handleOnClickRetract,
    selectSend,
    handleOnBroadCastNotification,
    notificationHistory,
    clickOnRetract,
    hideModal,
    setOpenModal,
    openModal,
    openModalBroadCast,
    setOpenModalBroadcast,
    setMonth,
    month,
    handleOnClickClearBroadCastNotification,
    isLoading,
    displaypTypeOptionsCompanyName,
    setSelectComapnyName,
    selectComapanyName,
  } = useNotification();

  return (
    <>
      <MainComponent>
        <div className="_container_right _user_page">
          <div className="__proposal">
            <Row>
              <Row>
                <Col md="3">
                  <p>In Application & Email Notification</p>
                </Col>
                <Col md="5" style={{ marginBottom: "1%" }}>
                  <div>
                    <MultiSelect
                      options={displaypTypeOptionsCompanyName()}
                      value={selectComapanyName}
                      onChange={setSelectComapnyName}
                      labelledBy="Select"
                    />
                  </div>
                  {/* <Form.Group>
                    <Form.Control
                      as="select"
                      name="pType"
                      onChange={(e) => setSelectComapnyName(e.target.value)}
                      value={selectComapanyName}
                    >
                      <option value="">All</option>
                      {displaypTypeOptionsCompanyName()}
                    </Form.Control>
                  </Form.Group> */}
                </Col>
              </Row>
              <Col md="8">
                <div className="form-group">
                  <textarea
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    rows="6"
                    key={randomKey}
                    value={textMessage}
                    onChange={(e) => setTextMessage(e.target.value)}
                  ></textarea>
                </div>
              </Col>
              <Col md="4">
                <button
                  className="btn btn-primary"
                  onClick={() => setTextMessage("")}
                  disabled={!textMessage}
                >
                  Clear Text
                </button>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: "10%" }}
                  onClick={handleOnBroadCastNotification}
                >
                  Clear Broadcast Notification
                </button>
              </Col>
              <Row style={{ marginTop: "2%" }}>
                <Col md="3">
                  <Form.Group>
                    <Form.Control
                      as="select"
                      name="pType"
                      onChange={(e) => setSelectSend(e.target.value)}
                      value={selectSend}
                    >
                      <option value="">Choose...</option>
                      <option value="SubScription Admin">
                        SubScription Admin
                      </option>
                      <option value="Broadcast">Broadcast</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Row style={{ marginTop: "2%" }}>
                <Col md="8">
                  <button
                    className="btn btn-primary"
                    onClick={() => handleOnClicksendMesageInApp("app")}
                    disabled={
                      !selectSend ||
                      !textMessage ||
                      !selectComapanyName?.length > 0
                    }
                  >
                    Send In App
                  </button>
                  <button
                    className="btn btn-primary"
                    style={{ float: "right" }}
                    onClick={() => handleOnClicksendMesageInEmail("email")}
                    disabled={!textMessage || !selectComapanyName?.length > 0}
                  >
                    Send In Email
                  </button>
                </Col>
              </Row>
            </Row>
            <Row style={{ marginTop: "6%" }}>
              <Col md="5"></Col>
              <Col md="4">
                <label>Filter by month : </label>
                <input
                  type="month"
                  id="bdaymonth"
                  name="bdaymonth"
                  value={month}
                  onChange={(e) => setMonth(e.target.value)}
                />
              </Col>

              <Col md="1">Date</Col>
              <Col md="2">Send Type</Col>
            </Row>
            {isLoading ? (
              <InitialLoader />
            ) : (
              <div>
                {notificationHistory?.length > 0 ? (
                  notificationHistory?.map((elem) => {
                    return (
                      <Row className="mb-3 mt-3" key={randomKey}>
                        <Col
                          md="8"
                          style={
                            !elem?.retracted
                              ? {}
                              : {
                                  textDecoration: "line-through",
                                  color: "gray",
                                }
                          }
                        >
                          <textarea
                            className="form-control"
                            id="exampleFormControlTextarea1"
                            rows="2"
                            value={elem?.notification}
                            disabled
                            key={randomKey}
                          ></textarea>
                        </Col>
                        <Col md="1">
                          {elem?.notificationType === "Send In App" && (
                            <button
                              className="btn btn-danger"
                              onClick={() =>
                                handleOnClickRetract(
                                  elem?._id,
                                  elem?.notification,
                                  elem?.receiverType
                                )
                              }
                              disabled={elem?.retracted}
                            >
                              Retract
                            </button>
                          )}
                        </Col>
                        <Col md="1">
                          <span>{moment(elem?.createdAt).format("l")}</span>
                          <br />
                          <span>{moment(elem?.createdAt).format("LT")}</span>
                        </Col>
                        <Col md="2">
                          <span>{elem?.notificationType}</span>
                        </Col>
                      </Row>
                    );
                  })
                ) : (
                  <h4 style={{ textAlign: "center", marginTop: "4%" }}>
                    No data found
                  </h4>
                )}
              </div>
            )}
          </div>
        </div>
      </MainComponent>
      <Modal
        keepMounted
        open={openModal}
        // onClose={hideModal}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={styyle}>
          <span
            style={{ float: "right", cursor: "pointer" }}
            onClick={hideModal}
          >
            <ImCross />
          </span>
          <h4 style={{ textAlign: "center" }}>Are you sure to retract it?</h4>
          <Row className="mt-5" style={{ marginLeft: "20%" }}>
            <Col md="4">
              <button className="btn btn-danger me-3" onClick={hideModal}>
                Cancel
              </button>
            </Col>
            <Col md="4">
              <button className="btn btn-success me-3" onClick={clickOnRetract}>
                Retract
              </button>
            </Col>
          </Row>
        </Box>
      </Modal>
      <Modal
        keepMounted
        open={openModalBroadCast}
        // onClose={hideModal}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={styyle}>
          <span
            style={{ float: "right", cursor: "pointer" }}
            onClick={() => setOpenModalBroadcast(false)}
          >
            <ImCross />
          </span>
          <h4 style={{ textAlign: "center" }}>
            Are you sure to clear broadcast notification?
          </h4>
          <Row className="mt-5" style={{ marginLeft: "20%" }}>
            <Col md="4">
              <button
                className="btn btn-danger me-3"
                onClick={() => setOpenModalBroadcast(false)}
              >
                Cancel
              </button>
            </Col>
            <Col md="4">
              <button
                className="btn btn-success me-3"
                onClick={handleOnClickClearBroadCastNotification}
                style={{ width: "75px" }}
              >
                Clear
              </button>
            </Col>
          </Row>
        </Box>
      </Modal>
    </>
  );
};

export default Notification;
