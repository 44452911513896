import { getEndPoints } from "../api/getEndPoints";
import { axiosInstance as axios } from "services";
import {
  ADD_SUB_ALL_ADMIN_CATEGORY,
  ADD_SUB_CATEGORY,
  DELETE_INDIVISUAL_ALL_ADMIN_VIDEO,
  DELETE_INDIVISUAL_VIDEO,
  GET_ALLVIDEO,
  UPLOAD_ALL_ADMIN__VIDEO,
  UPLOAD_VIDEO,
} from "./utils";
import { toast } from "react-toastify";
const url = getEndPoints(process.env.REACT_APP_MODE);

export const uploadedVideo = (
  bodyFormData,
  getAllVideo,
  selectCategory,
  setProgressBar,
  setDesCription,
  setUploadVideo
) => {
  return axios
    .post(`${url}${UPLOAD_ALL_ADMIN__VIDEO}`, bodyFormData, {
      onUploadProgress: (data) => {
        setProgressBar(Math.round((data.loaded / data.total) * 100));
      },
    })

    .then((response) => {
      getAllVideo(selectCategory);
      toast.success("Video Uploaded  Successfully!");
      setDesCription({});
      setUploadVideo("");
      return { error: null, response };
    })
    .catch((error) => {
      if (error.response) {
        return { error: error.response };
      }
    });
};

export const addSubcategory = (
  body,
  getAllVideo,
  selectCategory,
  setAddCategory
) => {
  return axios
    .post(`${url}${ADD_SUB_ALL_ADMIN_CATEGORY}`, body)

    .then((response) => {
      toast.success("SubCategory Added Successfully!");

      getAllVideo(selectCategory);
      setAddCategory(false);
      return { error: null, response };
    })
    .catch((error) => {
      if (error.response) {
        toast.error(error.response);

        return { error: error.response };
      }
    });
};

export const deleteVideo = (id, getAllVideo, selectCategory, setIsDelete) => {
  return axios
    .post(`${url}${DELETE_INDIVISUAL_ALL_ADMIN_VIDEO}${id}`)

    .then((response) => {
      getAllVideo(selectCategory);
      setIsDelete(false);
      toast.success("Video delete Successfully!");

      return { error: null, response };
    })
    .catch((error) => {
      if (error.response) {
        toast.error(error.response);

        return { error: error.response };
      }
    });
};
