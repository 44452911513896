import { Note1 } from 'iconsax-react';
import React,{useState, useEffect} from 'react'
import { Col, Container, Row } from "react-bootstrap";
import { randomKey } from '../../utils/utils';


const AdminNotes = (props) => {
  const {note} = props
  const [{
    notes
  }] = note

  const options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
  console.log({notesProps: notes})
  return (
    <div>{notes.length
        ? notes.map((note, index) => {
            return (
              <Row key={randomKey}>
                <Col
                  md="12"
                  sm="12"
                  style={{
                    borderRadius: "5px",
                    border: "1px solid #E6E4F0",
                    // borderStyle: "10px #E6E4F0",
                    height: "10rem",
                    marginTop: "1rem",
                    padding: "1rem",
                    // background: "#E6E4F0",
                    // background: `${
                    //   index == 0
                    //     ? `#FEDC97`
                    //     : index % 2 == 0
                    //     ? "#AFD0D6"
                    //     : index % 2 !== 0 && index % 3 == 0
                    //     ? "#A5D8FF"
                    //     : "#B8B8FF"
                    // }`,
                    boxShadow: "0px 4px 8px 0px #06031514",
                  }}
                >
                  <div>
                    <span style={{}}>
                    <Note1 size="32" color="#2ccce4" variant="TwoTone"/>
                    {" "}{new Date(note?.createdAt).toLocaleDateString('en-us', options)}
                    </span>
                  </div>
                  <div style={{paddingTop: "1rem"}}>
                    <span
                      style={{
                        marginTop: "1rem",
                        // fontWeight: "800",
                        // fontSize: "1.5rem",
                      }}
                    >
                      {note?.notes}
                    </span>
                  </div>
                </Col>
              </Row>
            );
          })
        : "No notes available"}</div>
  )
}

export default AdminNotes