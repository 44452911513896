import {
  addNewRoofPitch,
  addNewStyle,
  addNewSubType,
  addUpdateSeriesAPI,
  deleteRoofPitch,
  deleteSeriesType,
  deleteStyle,
  deleteSubType,
} from "api/api";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";

import { getCategoryOption, getCategoryAllSeries } from "api/api";
import {
  getCategoryTypes,
  getStyle,
  getRoofPitchTypes,
  getSubType,
  removeElenentInArray,
} from "utils/crew";

const useAllSubsCrewData = () => {
  const [isLoadingSeries, setIsLoadingSeries] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [add, setAdd] = useState(false);

  const [addNewSeries, setAddNewSeries] = useState(false);

  const [selectCatagory, setSelectCatagory] = useState("");
  const [selectManufacture, setSelectManufacture] = useState("");
  const [selectCatagoryType, setSelectCatagpryType] = useState("");
  const [selectSeriesType, setSelectSeriesType] = useState("");
  const [selectSubType, setSelectSubType] = useState("");
  const [selectRoofPitch, setSelectRoofPitch] = useState("");
  const [selectStyle, setSelectStyle] = useState("");

  const [pricing, setPricing] = useState([]);

  const [categoryList, setCategoryList] = useState([]);
  const [typesList, setTypesList] = useState([]);
  const [subTypeList, setSubTypeList] = useState([]);
  const [styleList, setStyleList] = useState([]);
  const [roofPitchList, setRoofPitchList] = useState([]);

  const [subTypes, setSubTypes] = useState([]);
  const [subName, setSubName] = useState("");

  const [crewAllData, setAllCrewData] = useState([]);
  const [loborSeriesData, setAllSeriesData] = useState([]);

  const [manufactur, setManufactur] = useState("");
  const [subType, setSubType] = useState("");
  const [roofPitch, setRoofPitch] = useState("");
  const [style, setStyle] = useState("");

  const [addManufacturer, setAddManufacture] = useState(false);
  const [addRoofPitch, setAddRoofPitch] = useState(false);
  const [addSubType, setAddSubType] = useState(false);
  const [addMStyle, setAddStyle] = useState(false);

  const [cataGoryType, setCataGoryType] = useState("");
  const [addCatagoryTypee, setAddCatagoryType] = useState(false);
  const [showAddNew, setShowAddNew] = useState(false);

  /**
   *Start All Api Call
   * All Apis
   *
   */
  console.log("selectRoofPitch", selectRoofPitch);

  /**
   * get all catagory ,catagory type ,subtype ,style and roof pitch for labor
   */
  useEffect(() => {
    getAllCatagoryOptions();
  }, [isLoading]);

  const getAllCatagoryOptions = () => {
    getCategoryOption("/admin/laborOld/getOptionsByCategory")
      .then((response) => {
        const { data: { result = [] } = {} } = response;
        setAllCrewData(result);
      })
      .catch((error) => {
        if (error.response) {
          return { error: error.response };
        }
      });
  };

  /**
   * add new Subtype
   */

  const addSubTypes = (body) => {
    setAddSubType(!addSubType);
    setSubType("");
    addNewSubType("/admin/laborOld/addToLaborOption", body)
      .then((response) => {
        const { data: { result = [] } = {} } = response;
        setSubTypeList((prevList) => [...prevList, subType]);
      })
      .catch((error) => {
        if (error.response) {
          return { error: error.response };
        }
      });
  };

  /**
   * add new style
   */

  const addStyle = (body) => {
    setAddStyle(!addMStyle);
    setStyle("");
    addNewStyle("/admin/laborOld/addToLaborOption", body)
      .then((response) => {
        const { data: { result = [] } = {} } = response;
        setStyleList((prevList) => [...prevList, styleList]);
      })
      .catch((error) => {
        if (error.response) {
          return { error: error.response };
        }
      });
  };

  /**
   * add new roofPitch
   */

  const addRoofPitchs = (body) => {
    setAddRoofPitch(!addRoofPitch);
    setRoofPitch("");
    addNewRoofPitch("/admin/laborOld/addToLaborOption", body)
      .then((response) => {
        const { data: { result = [] } = {} } = response;
        setRoofPitchList((prevList) => [...prevList, roofPitchList]);
      })
      .catch((error) => {
        if (error.response) {
          return { error: error.response };
        }
      });
  };

  /**
   * delete new Subtype
   */

  const deleteSubTypes = (body) => {
    setAddSubType(addSubType);
    setSelectSubType(!selectSubType);
    deleteSubType("/admin/laborOld/deleteLaborPricingOptions", body)
      .then((response) => {
        const { data: { result = [] } = {} } = response;

        let newSubType = removeElenentInArray(selectSubType, subTypeList);
        setSubTypeList(newSubType);
      })
      .catch((error) => {
        if (error.response) {
          return { error: error.response };
        }
      });
  };

  /**
   * delete new style
   */

  const deleteStyles = (body) => {
    setAddStyle(addMStyle);
    setSelectStyle(!selectStyle);
    deleteStyle("/admin/laborOld/deleteLaborPricingOptions", body)
      .then((response) => {
        const { data: { result = [] } = {} } = response;
        let newStyle = removeElenentInArray(selectStyle, styleList);
        setStyleList(newStyle);
      })
      .catch((error) => {
        if (error.response) {
          return { error: error.response };
        }
      });
  };

  /**
   * delete new roofPitch
   */

  const deleteRoofPitchs = (body) => {
    setAddRoofPitch(addRoofPitch);
    setSelectRoofPitch(!selectRoofPitch);
    deleteRoofPitch("/admin/laborOld/deleteLaborPricingOptions", body)
      .then((response) => {
        const { data: { result = [] } = {} } = response;
        let newRoofPitch = removeElenentInArray(selectRoofPitch, roofPitchList);
        setRoofPitchList(newRoofPitch);
      })
      .catch((error) => {
        if (error.response) {
          return { error: error.response };
        }
      });
  };
  /*
   *
   * get all series data
   */

  const getAllSeriesData = (data) => {
    const body = {
      q: data,
    };
    setIsLoadingSeries(true);
    setShowAddNew(true);
    getCategoryAllSeries("/admin/laborOld/getLaborData", body)
      .then((response) => {
        const { data: { result = [] } = {} } = response;
        setAllSeriesData(result?.laborPricing);
        setIsLoadingSeries(false);
      })
      .catch((error) => {
        if (error.response) {
          setIsLoadingSeries(false);

          return { error: error.response };
        }
      });
  };

  /*
   *
   * delete the selected series in laboer pricing
   */

  const delteMaterialSeries = (id) => {
    setIsLoadingSeries(true);

    deleteSeriesType("/admin/laborOld/deleteAdminOldLabor/", id)
      .then((response) => {
        setIsLoadingSeries(false);

        const { data: { result = [] } = {} } = response;
      })
      .catch((error) => {
        setIsLoadingSeries(false);

        if (error.response) {
          return { error: error.response };
        }
      });
  };

  /*
   *
   * add and update all series in labor
   */
  const addUpdateSeries = (setIsLoadingSeries) => {
    setIsLoadingSeries(true);
    let body = {
      series: JSON.stringify(loborSeriesData),
    };
    addUpdateSeriesAPI("/admin/laborOld/addSeriesToMasterInAdmin", body)
      .then((res) => {
        setIsLoadingSeries(false);
      })
      .catch((error) => {
        setIsLoadingSeries(false);
      });
  };

  /**
   *End All Api Call
   * All Apis
   *
   */

  useEffect(() => {
    if (crewAllData) {
      setPricing(crewAllData);
    }
  }, [crewAllData]);

  useEffect(() => {
    const list = [];
    if (pricing.length > 0) {
      pricing.map((priceData) => list.push(priceData.category));
    }
    setCategoryList(list);
  }, [pricing]);

  useEffect(() => {
    let result1 = getCategoryTypes(selectCatagory, pricing);
    if (result1.length === 1) {
      setSelectCatagpryType(result1[0]);
    }
  }, [selectCatagory]);

  useEffect(() => {
    setAllSeriesData([]);

    if (selectCatagory) {
      let result = getSubType(selectCatagory, selectCatagory, pricing);
      if (result && result.subTypes && !result.subTypes.length) {
        getAllSeriesData({
          category: selectCatagory,
          categoryType: selectCatagory,
        });
      }
    }

    if (selectCatagoryType) {
      let result = getSubType(selectCatagory, selectCatagoryType, pricing);
      if (result && result.subTypes && !result.subTypes.length) {
        getAllSeriesData(
          selectCatagory,
          selectCatagoryType,
          setIsLoadingSeries
        );
      }
    }

    if (selectSubType) {
      let result = getRoofPitchTypes(selectCatagory, selectCatagory, pricing);
      let styleResult = getStyle(selectCatagory, selectCatagoryType, pricing);
      if (selectCatagory === "Gutters/Leaders") {
        if (
          (result && result.roofPitch && !result.roofPitch.length) ||
          (styleResult && styleResult.style && !styleResult.style.length)
        ) {
          getAllSeriesData({
            category: selectCatagory,
            categoryType: selectCatagoryType,
            subType: selectSubType,
            // roofPitch: selectRoofPitch,
          });
        }
      } else {
        if (
          (result && result.roofPitch && !result.roofPitch.length) ||
          (styleResult && styleResult.style && !styleResult.style.length)
        ) {
          getAllSeriesData({
            category: selectCatagory,
            categoryType: selectCatagory,
            subType: selectSubType,
            // roofPitch: selectRoofPitch,
          });
        }
      }
    }

    if (selectRoofPitch) {
      getAllSeriesData({
        category: selectCatagory,
        categoryType: selectCatagory,
        subType: selectSubType,
        roofPitch: selectRoofPitch,
      });
    }

    if (selectStyle) {
      setAdd(false);

      getAllSeriesData({
        category: selectCatagory,
        categoryType: selectCatagoryType,
        subType: selectSubType,
        style: selectStyle,
      });
    }
  }, [
    selectCatagoryType,
    selectSubType,
    selectRoofPitch,
    selectStyle,
    selectCatagory,
  ]);

  useEffect(() => {
    resetOptions();
    if (selectCatagory) {
      let result = getCategoryTypes(selectCatagory, pricing);
      setTypesList(result);
      setAdd(false);
    }
  }, [selectCatagory]);

  useEffect(() => {
    if (selectCatagory !== "Gutters/Leaders") {
      setSubTypeList([]);

      setSubTypes([]);

      if (selectCatagory) {
        let result = getSubType(selectCatagory, selectCatagory, pricing);
        if (result && result.subTypes) {
          setSubTypeList(result.subTypes);
          setSubName(result.subTypeName);
        }
        setAdd(false);
      }
    }
  }, [selectCatagory]);

  useEffect(() => {
    if (selectCatagory === "Gutters/Leaders") {
      setSubTypeList([]);
      setStyleList([]);
      setSelectStyle("");
      setSelectSubType("");
      setSubTypes([]);

      if (selectCatagoryType) {
        let result = getSubType(selectCatagory, selectCatagoryType, pricing);
        if (result && result.subTypes) {
          setSubTypeList(result.subTypes);
          setSubName(result.subTypeName);
        }
        setAdd(false);
      }
    }
  }, [selectCatagory, selectCatagoryType]);

  useEffect(() => {
    if (selectCatagory !== "Gutters/Leaders") {
      setRoofPitchList([]);
      // setSelectRoofPitch("");
      setSubTypes([]);

      if (selectSubType) {
        let result = getRoofPitchTypes(selectCatagory, selectCatagory, pricing);

        if (result && result.roofPitch) {
          setRoofPitchList(result.roofPitch);
        }
        setAdd(false);
      }
    }
  }, [selectSubType, selectRoofPitch]);

  useEffect(() => {
    if (selectCatagory === "Gutters/Leaders") {
      setStyleList([]);
      setSubTypes([]);
      // setSelectStyle("");

      if (selectSubType) {
        let result = getStyle(selectCatagory, selectCatagoryType, pricing);

        if (result && result.style) {
          setStyleList(result.style);
        }
        setAdd(false);
      }
    }
  }, [selectSubType, selectCatagory]);

  const resetOptions = () => {
    setSubTypeList([]);
    setStyleList([]);
    setRoofPitchList([]);
    setTypesList([]);
    setSelectCatagpryType("");
    setSelectRoofPitch("");
    setSelectSubType("");
    setSelectStyle("");
  };

  /**
   *
   *
   */

  const showOptions = (list) =>
    list.length > 0 &&
    list.map((data) => (
      <option value={data} key={data}>
        {data}
      </option>
    ));

  const handleChange = (e, callback, type) => {
    callback(e.target.value);
    if (type === "ctype") {
      setSelectManufacture("");
      setSelectSeriesType("");
    }

    if (type === "manufacturer") {
      setSelectSeriesType("");
    }
  };

  const saveData = (data) => {
    setAllSeriesData([...loborSeriesData, data]);
    setAdd(false);
  };

  const editData = (data) => {
    setAdd(false);
    const idx = loborSeriesData.findIndex((list) => list._id === data._id);
    loborSeriesData.splice(idx, 1, data);
    setAllSeriesData([...loborSeriesData]);
  };

  const deleteData = (_id) => {
    delteMaterialSeries(_id);

    const idx = loborSeriesData.findIndex((list) => list._id === _id);
    loborSeriesData.splice(idx, 1);
    setAllSeriesData([...loborSeriesData]);
  };

  const handleOnAddNew = () => {
    setAdd(!add);
  };

  return {
    showOptions,
    categoryList,
    typesList,
    subTypes,
    setSelectCatagory,
    setSelectManufacture,
    setSelectCatagpryType,
    setSelectSeriesType,
    selectCatagory,
    selectCatagoryType,
    selectManufacture,
    selectSeriesType,
    handleChange,
    subName,
    loborSeriesData,
    isLoadingSeries,
    setIsLoadingSeries,
    setAllSeriesData,

    isLoading,
    setAddManufacture,
    addManufacturer,
    manufactur,
    setManufactur,
    setAddCatagoryType,
    addCatagoryTypee,
    setCataGoryType,
    cataGoryType,
    setShowAddNew,
    showAddNew,
    delteMaterialSeries,
    saveData,
    editData,
    deleteData,
    handleOnAddNew,
    setAdd,
    add,
    addNewSeries,
    setAddNewSeries,
    setAllCrewData,
    setPricing,
    roofPitchList,
    subTypeList,
    styleList,
    setSelectSubType,
    setSelectRoofPitch,
    setSelectStyle,
    selectRoofPitch,
    selectSubType,
    selectStyle,
    addRoofPitch,
    setAddRoofPitch,
    addSubType,
    setAddSubType,
    addMStyle,
    setAddStyle,
    addUpdateSeries,
    addRoofPitchs,
    subType,
    addStyle,
    setSubType,
    roofPitch,
    setRoofPitch,
    style,
    setStyle,
    addSubTypes,
    deleteSubTypes,
    deleteRoofPitchs,
    deleteStyles,
  };
};

export default useAllSubsCrewData;
