import InitialLoader from "components/InitilalLoader";
import MainComponent from "components/MainComponent";

import "../Subscription/Subscription.scss";
/**
 *
 */

import React, { useState, useEffect, useCallback } from "react";
import "./Proposal.scss";
import { GrFormClose } from "react-icons/gr";
import Box from "@material-ui/core/Box";

import Modal from "@material-ui/core/Modal";
import { Row, Col, Form } from "react-bootstrap";

import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { fetchStaticAll } from "../../redux/static-all/static-all.action";
import {
  fetchAutoText,
  fetchStandardStatements,
  updateStandardStatements,
} from "../../redux/auto-text/auto-text.action";
import DisplayData from "./DisplayData";
import StandardStatements from "./StandardStatments";
import { useHistory } from "react-router-dom";
import { Button, ModalFooter, ModalHeader } from "reactstrap";

const HeaderText =
  "Proposal auto text are line item statements specificly inserted and uniqe to each proposal created. Organized Roofer has provided a general list of work type descriptions and common proposal statements. All are easily customizable to suit your descriptive preferrences. Simply click the 'Edit' button next to each line item then 'Save'. If any line item is not needed, simply click exclude (where displayed) and it will be omitted from future proposals. You will also have the opportunity to make changes when writing proposals.";
const styyle = {
  "@media (max-width: 600px)": { width: 600 },
  "@media (min-width: 600px)": { width: 600 },
  "@media (min-width: 821px)": { width: 600 },

  position: "relative",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  height: 200,
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  p: 2,
  overflow: "auto",
  borderRadius: "15px",
};
const ProposalText = () => {
  /**
   *
   *
   */

  const [statementId, setStatementId] = useState("");
  // const [permission, setPermission] = useState({});

  const { data, errors, isLoading } = useSelector((state) => state.staticAll);
  const { gettingTexts, autoTexts, autoTextsErr } = useSelector(
    (state) => state.autoTexts
  );
  const { gettingStatements, statements, statementsErr } = useSelector(
    (state) => state.standardStatements
  );

  const [statementsCategory, setStatementsCategory] = useState([]);
  const [per, setPer] = useState(0);

  const [percentageModal, setPercentageModal] = useState(false);
  const [percentage, setPercent] = useState(per);
  const [displayText, setDisplayText] = useState("");
  const [percentageStatementIdx, setPercentageStatementIdx] = useState("");
  const [
    updateFixedStandardStatementsPercentage,
    setUpdateFixedStandardStatementsPercentage,
  ] = useState();
  const [fixedStandardStatements, setFixedStandardStatements] = useState([]);
  const [textId, setTextId] = useState("");
  const [isSelected, setIsSelected] = useState(false);

  const history = useHistory();

  const dispatch = useDispatch();
  useEffect(() => {
    setFixedStandardStatements(statements);
  }, [statements]);
  useEffect(() => {
    dispatch(fetchStaticAll());
  }, []);
  useEffect(() => {
    if (data && data.autoTextDropDown) {
      setStatementsCategory(data.autoTextDropDown);
    }
  }, [data]);

  useEffect(() => {
    if (errors) {
      toast.error(errors);
    }
  }, [errors]);
  const onSuccess = () => {
    toast.success("Successfully updated the statement.");
  };

  const onError = (errMsg) => {
    toast.error(errMsg);
  };
  const loadDropDownData = useCallback(() => {
    const isStandardStatements =
      statementsCategory.length > 0 &&
      statementId === statementsCategory[0].dropId;

    if (statementId) {
      if (isStandardStatements) {
        return dispatch(fetchStandardStatements());
      }

      dispatch(fetchAutoText(statementId));
    }
  }, [statementId]);
  const changePer = (value) => setPer(value);

  const changePerData = (increase = true) => {
    let newPer = percentage;
    if (increase) {
      newPer += 1;
    } else {
      newPer -= 1;
    }
    setPercent(newPer < 0 ? 0 : newPer);
  };
  const applyChanges = () => {
    changePer(percentage);

    let postData = {
      isSelected: isSelected === undefined ? isSelected : isSelected,
      displayText,
      percentage,
    };
    dispatch(updateStandardStatements(textId, postData, onSuccess, onError));
    dispatch(fetchStandardStatements());

    hidePercentageStepperModal();
  };

  const showPercentageStepperModal = (displaytext, idx) => {
    setPercent(displaytext.percentage);
    setDisplayText(displaytext.displayText);
    setIsSelected(displaytext.isSelected);
    setTextId(displaytext._id);
    setPercentageModal(true);
    const idd = fixedStandardStatements.findIndex(
      (list) => list._id === displaytext._id
    );
    setPercentageStatementIdx(idd);
  };

  const hidePercentageStepperModal = () => {
    setPercentageModal(false);
  };

  const cancelChanges = () => {
    setPercent(per);
    hidePercentageStepperModal();
  };

  useEffect(() => {
    // let userDataRole = user.userData.role;
    // let permissions = getUserPermissions(proposal, userDataRole, history);
    // setPermission(permissions);

    if (statementId) {
      loadDropDownData();
    }
  }, [statementId]);

  useEffect(() => {
    if (autoTextsErr) {
      toast.error("Unable to get the selected drop down's data");
    }
  }, [autoTextsErr]);

  const switchDropdownBody = () => {
    if (gettingTexts || gettingStatements || autoTextsErr || statementsErr) {
      return (
        <InitialLoader
          error={autoTextsErr || statementsErr}
          onReload={loadDropDownData}
        />
      );
    }
    switch (statementId) {
      case statementsCategory[0].dropId:
        return (
          <StandardStatements
            data={fixedStandardStatements}
            showPercentageStepperModal={showPercentageStepperModal}
            updateFixedStandardStatementsPercentage={
              updateFixedStandardStatementsPercentage
            }
          />
        );

      case statementsCategory[1].dropId:
        return <DisplayData data={autoTexts} />;

      case statementsCategory[2].dropId:
        return <DisplayData data={autoTexts} />;

      case statementsCategory[3].dropId:
        return <DisplayData data={autoTexts} />;

      case statementsCategory[4].dropId:
        return <DisplayData data={autoTexts} />;

      case statementsCategory[5].dropId:
        return <DisplayData data={autoTexts} lg />;

      case statementsCategory[6].dropId:
        return <DisplayData data={autoTexts} lg />;

      case statementsCategory[7].dropId:
        return <DisplayData data={autoTexts} />;

      case statementsCategory[8].dropId:
        return <DisplayData data={autoTexts} lg />;

      case statementsCategory[9].dropId:
        return <DisplayData data={autoTexts} lg />;

      case statementsCategory[10].dropId:
        return <DisplayData data={autoTexts} />;

      case statementsCategory[11].dropId:
        return <DisplayData data={autoTexts} />;

      case statementsCategory[12].dropId:
        return <DisplayData data={autoTexts} lg />;

      case statementsCategory[13].dropId:
        return <DisplayData data={autoTexts} lg />;

      case statementsCategory[14].dropId:
        return <DisplayData data={autoTexts} lg />;

      case statementsCategory[15].dropId:
        return <DisplayData data={autoTexts} lg />;

      case statementsCategory[16].dropId:
        return <DisplayData data={autoTexts} lg />;

      case statementsCategory[17].dropId:
        return <DisplayData data={autoTexts} lg />;

      default:
        return null;
    }
  };

  /**
   *
   *
   */
  return (
    <>
      <MainComponent>
        {/* {percentageModal && ActiveModal()} */}

        <div className="_container_right _user_page">
          <div className="__proposal">
            <Row>
              <Col>
                <span>{HeaderText}</span>
              </Col>
            </Row>
            <Row style={{ marginTop: "2em", marginBottom: "2em" }}>
              <Col lg="3">
                <Form.Group>
                  <Form.Label>Select Statement Category</Form.Label>
                  <Form.Control
                    as="select"
                    value={statementId}
                    onChange={(e) => setStatementId(e.target.value)}
                  >
                    <option value="">select...</option>
                    {statementsCategory.length > 0 &&
                      statementsCategory.map((statement) => (
                        <option key={statement.dropId} value={statement.dropId}>
                          {statement.dropName}
                        </option>
                      ))}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            {statementsCategory.length > 0 && switchDropdownBody()}
          </div>
        </div>
      </MainComponent>
      <Modal
        keepMounted
        open={percentageModal}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={styyle}>
          <div>
            <span
              className="crossIconForm"
              onClick={hidePercentageStepperModal}
              style={{ float: "right" }}
            >
              <span style={{ cursor: "pointer" }}>
                <GrFormClose size={30} />
              </span>
            </span>
            <h5 style={{ fontWeight: "bold" }}>
              {displayText.replace(":", "")}
            </h5>
          </div>

          <div>
            <div
              className="justify-content-center align-items-center mx-2 font-weight-bold row"
              style={{ marginTop: "3%" }}
            >
              <div className="justify-content-end d-flex col-lg-3">
                <button
                  type="button"
                  className="modified_btn __proposal_tab_stepper btn btn-outline-dark"
                  onClick={() => changePerData(false)}
                  style={{ width: "4em", height: "2.5em" }}
                >
                  -
                </button>
              </div>
              <div className="col-lg-3">
                <div>
                  <input
                    disabled
                    type="text"
                    className="form-control"
                    value={percentage}
                    style={{ textAlign: "center", height: "100%" }}
                  />
                </div>
              </div>
              <div className="col-lg-3">
                <button
                  type="button"
                  className="modified_btn __proposal_tab_stepper btn btn-outline-dark"
                  onClick={() => changePerData()}
                  style={{ width: "4em", height: "2.5em" }}
                >
                  +
                </button>
              </div>
            </div>
            <div
              className="modal-footer"
              style={{
                justifyContent: "flex-end",
                border: " none",
                padding: "0.75rem",
                marginTop: "3%",
              }}
            >
              <button
                type="button"
                className="modified_btn btn btn-danger"
                onClick={cancelChanges}
                style={{ width: "6em", height: "2.5em" }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="modified_btn btn btn-primary"
                onClick={applyChanges}
                style={{ width: "6em", height: "2.5em", marginLeft: "2%" }}
              >
                Apply
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default ProposalText;
