import axios from 'axios';

import AuthService from './auth-service';
import settings from 'config/baseSettings';
import baseSettings from 'config/baseSettings';

const axiosConfig = {
	baseURL: settings.baseUrl,
	timeout: baseSettings.axiosTimeout,
	timeoutErrorMessage: 'Sorry! Unable to perform operation. Please try again.',
};

class AxiosService {
	axiosInstance = {};

	constructor() {
		this.initInstance();
	}

	initInstance() {
		this.axiosInstance = axios.create(axiosConfig);

		this.axiosInstance.interceptors.request.use(async (config) => {
			const token = await AuthService.getToken();
			console.log("token ", token);
			if (token) {
				config.headers['x-access-token'] = `${token}`;
			}

			return config;
		});
		return this.axiosInstance;
	}

	getInstance() {
		return this.axiosInstance || this.initInstance();
	}
}

export default new AxiosService();
