import React from 'react'
import {useState, useEffect} from 'react'
import axios from 'axios'
import { addSubcrewsPersonel, getSubCrews } from 'api/api';

const useSubCrewsPersonelHook = (isLoading) => {
    const [subcrewData,setSubcrewData] = useState([])
    //const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
      getSubcrewsPersonel()
    }, [isLoading]);


    const getSubcrewsPersonel = () => {
      getSubCrews("/supplier/subcrewsPersonel")
        .then((response) => {
          const {data: {result = []} = {}} = response;
          setSubcrewData(result);
        })
        .catch((error) => {
          if (error.response) {
            return { error: error.response };
          }
        });
    }

    const addPersonelsSubcrews = (email,setEmail,add,setAdd,setIsLoading) => {
      console.log(email)
      setIsLoading(true);
          const body = {
            personelPosition: email,
          };
          setAdd(!add);
          setEmail("");
          console.log(body)
          addSubcrewsPersonel("/supplier/addsubcrewsPersonel", body)
            .then((response) => {
              setIsLoading(false);
              return { error: null, response };
            })
            .catch((error) => {
              setIsLoading(false);
      
              if (error.response) {
                return { error: error.response };
              }
            });
    }
    return { subcrewData, addPersonelsSubcrews}
}

export default useSubCrewsPersonelHook