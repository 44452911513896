import React, { useState } from "react";
import { updateAutoText } from "../../redux/auto-text/auto-text.action";

import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { Row, Col, Button, Form } from "react-bootstrap";
import { includes } from "lodash";

const styles = {
  btnWrapper: {
    flex: "0 0 10%",
  },
};

function LongTextInput({ detail, lg = false }) {
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [displayText, setDisplayText] = useState(detail.displayText);
  const [oldDisplayText, setOldDisplayText] = useState(detail.displayText);
  const dispatch = useDispatch();

  const onSuccess = () => {
    toast.success("Successfully updated the statement.");
    setOldDisplayText(displayText);
    setEdit(false);
    setLoading(false);
  };

  const onError = (errMsg) => {
    toast.error(errMsg);
    setLoading(false);
  };

  const updateData = (include) => {
    setLoading(true);
    dispatch(
      updateAutoText(
        detail._id,
        {
          displayText,
        },
        onSuccess,
        onError
      )
    );
  };

  const cancelSave = () => {
    setDisplayText(oldDisplayText);
    setEdit(false);
  };

  return (
    <Row>
      <Col lg={lg ? null : 5}>
        <Form.Group>
          <Form.Control
            onChange={(e) => setDisplayText(e.target.value)}
            value={displayText}
            disabled={!edit || loading}
            type="text"
            style={{ marginBottom: "15px" }}
          />
        </Form.Group>
      </Col>

      <Col style={styles.btnWrapper}>
        {edit ? (
          <Button
            className="__btn_expanded"
            disabled={loading}
            variant="outline-success"
            onClick={() => updateData()}
          >
            {loading ? "Saving..." : "Save"}
          </Button>
        ) : (
          <Button
            className="__btn_expanded"
            variant="outline-secondary"
            disabled={loading}
            onClick={() => setEdit(true)}
          >
            Edit
          </Button>
        )}
      </Col>
      <Col style={styles.btnWrapper}>
        {edit && (
          <Button
            className="__btn_expanded"
            variant="danger"
            disabled={loading}
            onClick={cancelSave}
          >
            Cancel
          </Button>
        )}
      </Col>
    </Row>
  );
}

function GetTitle({ title }) {
  return (
    <Row style={{ marginTop: "1em" }}>
      <Col>
        <Form.Label>{title}</Form.Label>
      </Col>
      <Col style={styles.btnWrapper} />
    </Row>
  );
}

function DataItem({ title, nestedData, lg }) {
  return (
    <>
      <GetTitle title={title} />
      {nestedData &&
        nestedData.map((detail) => (
          <LongTextInput key={detail._id} detail={detail} lg={lg} />
        ))}
    </>
  );
}

export default function Index({ data, lg = false }) {
  return (
    <>
      {data &&
        data?.map((info) => (
          <DataItem
            lg={lg}
            key={info._id}
            title={info._id}
            nestedData={info.data}
          />
        ))}
    </>
  );
}
