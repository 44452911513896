export const getCategoryTypes = (category, pricing = []) => {
  let findCategory = pricing.find((doc) => doc.category === category);

  if (findCategory) {
    let categoryTypes = findCategory.categoryType.map((doc) => {
      return doc.typeName;
    });

    return categoryTypes;
  }

  return [];
};

export const getManufactures = (
  category,
  selected_category_type,
  pricing = []
) => {
  if (category === "Shingles") {
    let { categoryType = [] } = pricing.find(
      (doc) => doc.category === category
    );

    if (categoryType.length) {
      let res = categoryType.find(
        (doc) => doc.typeName === selected_category_type
      );

      let singlesManufacturer = res?.manufacturersWithSubType?.map(
        (a) => a.manufacturer
      );
      console.log("singlesManufacturer", singlesManufacturer);

      if (res && singlesManufacturer) {
        return {
          manufacturers: singlesManufacturer,
          subTypeName: res.subTypeName,
        };
      }
    }
  } else {
    let { categoryType = [] } = pricing.find(
      (doc) => doc.category === category
    );

    if (categoryType.length) {
      let res = categoryType.find(
        (doc) => doc.typeName === selected_category_type
      );
      if (res && res.manufacturer) {
        return {
          manufacturers: res.manufacturer,
          subTypeName: res.subTypeName,
        };
      }
    }
  }
};

export const getSeriesType = (
  category,
  selected_category_type,
  selectManufacture,
  pricing = []
) => {
  if (category === "Shingles") {
    let categoryType = pricing.find((doc) => doc.category === category);
    if (categoryType?.categoryType?.length) {
      let res = categoryType?.categoryType.find(
        (doc) => doc.typeName === selected_category_type
      );

      let signleSeriesType = res.manufacturersWithSubType.filter((elem) => {
        return elem.manufacturer === selectManufacture;
      });
      let singleSeries = signleSeriesType?.map((a) => a.subType);
      if (res && singleSeries.length) {
        return {
          subTypes: singleSeries[0],
          subTypeName: res.subTypeName,
        };
      }
    }
  } else {
    let categoryType = pricing.find((doc) => doc.category === category);

    if (categoryType.categoryType.length) {
      let res = categoryType.categoryType.find(
        (doc) => doc.typeName === selected_category_type
      );
      if (res && res.subType) {
        return { subTypes: res.subType, subTypeName: res.subTypeName };
      }
    }
  }
  return [];
};

export const removeElenentInArray = (element = "", array = []) => {
  return array.filter((ele) => ele != element);
};
