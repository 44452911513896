import { getEndPoints } from "../api/getEndPoints";
import { axiosInstance as axios } from "services";
import {
  ADD_SUB_ALL_ADMIN_CATEGORY,
  ADD_SUB_CATEGORY,
  DELETE_INDIVISUAL_ALL_ADMIN_VIDEO,
  DELETE_INDIVISUAL_VIDEO,
  DELETE_SUBSCREEN_VIDEO,
  EDIT_SUBSCREEN_VIDEO,
  GET_ALLVIDEO,
  UPLOAD_ALL_ADMIN__VIDEO,
  UPLOAD_VIDEO,
} from "./utils";
import { toast } from "react-toastify";
const url = getEndPoints(process.env.REACT_APP_MODE);

export const uploadedVideo = (
  bodyFormData,
  getAllVideo,
  selectCategory,
  selectSetup,
  setProgressBar,
  setDesCription,
  setUploadVideo,
  inputRef
) => {
  return axios
    .post(
      `${url}${
        selectCategory === "First Time Setup"
          ? UPLOAD_ALL_ADMIN__VIDEO
          : UPLOAD_VIDEO
      }`,
      bodyFormData,
      {
        onUploadProgress: (data) => {
          console.log(Math.round((data.loaded / data.total) * 100));
          setProgressBar(Math.round((data.loaded / data.total) * 100));
        },
      }
    )

    .then((response) => {
      if (selectCategory === "First Time Setup") {
        getAllVideo({ category: selectSetup });
      } else {
        getAllVideo({ id: selectCategory });
      }

      toast.success("Video Uploaded  Successfully!");
      setDesCription("");
      inputRef.current.value = "";
      setUploadVideo("");
      return { error: null, response };
    })
    .catch((error) => {
      if (error.response) {
        return { error: error.response };
      }
    });
};

export const addSubcategory = (
  body,
  getAllVideo,
  selectCategory,
  setAddCategory,
  setCategory
) => {
  return axios
    .post(`${url}${ADD_SUB_CATEGORY}`, body)

    .then((response) => {
      toast.success("SubCategory Added Successfully!");

      getAllVideo({ id: selectCategory });
      setAddCategory(false);
      setCategory("");
      return { error: null, response };
    })
    .catch((error) => {
      if (error.response) {
        toast.error(error.response);

        return { error: error.response };
      }
    });
};

export const deleteVideo = (
  id,
  getAllVideo,
  selectCategory,
  selectSetup,
  setIsDelete
) => {
  return axios
    .post(
      `${url}${
        selectCategory === "First Time Setup"
          ? DELETE_INDIVISUAL_ALL_ADMIN_VIDEO
          : DELETE_INDIVISUAL_VIDEO
      }${id}`
    )

    .then((response) => {
      if (selectCategory === "First Time Setup") {
        getAllVideo({ category: selectSetup });
      } else {
        getAllVideo({ id: selectCategory });
      }
      setIsDelete(false);
      toast.success("Video delete Successfully!");

      return { error: null, response };
    })
    .catch((error) => {
      if (error.response) {
        toast.error(error.response);

        return { error: error.response };
      }
    });
};

export const deleteSubscreen = (
  selectCategory,
  selectSetup,
  getAllVideo,
  setIsDeleteSubscreen,
  body
) => {
  return axios
    .post(`${url}${DELETE_SUBSCREEN_VIDEO}`, body)

    .then((response) => {
      if (selectCategory === "First Time Setup") {
        getAllVideo({ category: selectSetup });
      } else {
        getAllVideo({ id: selectCategory });
      }
      setIsDeleteSubscreen(false);
      toast.success("Subscreen delete Successfully!");

      return { error: null, response };
    })
    .catch((error) => {
      if (error.response) {
        toast.error(error.response);

        return { error: error.response };
      }
    });
};

export const addSubcategorySetup = (
  body,
  getAllVideo,
  selectCategory,
  setAddCategory,
  setSetupCategory
) => {
  return axios
    .post(`${url}${ADD_SUB_ALL_ADMIN_CATEGORY}`, body)

    .then((response) => {
      toast.success("SubCategory Added Successfully!");

      getAllVideo({ category: selectCategory });
      setAddCategory(false);
      setSetupCategory("");
      return { error: null, response };
    })
    .catch((error) => {
      if (error.response) {
        toast.error(error.response);

        return { error: error.response };
      }
    });
};

export const saveSubscreen = (
  selectCategory,
  selectSetup,
  getAllVideo,
  body,
  setIsEdit
) => {
  return axios
    .patch(`${url}${EDIT_SUBSCREEN_VIDEO}`, body)

    .then((response) => {
      if (selectCategory === "First Time Setup") {
        getAllVideo({ category: selectSetup });
      } else {
        getAllVideo({ id: selectCategory });
      }
      toast.success("Subscreen Updated  Successfully!");
      setIsEdit(false);
      return { error: null, response };
    })
    .catch((error) => {
      if (error.response) {
        toast.error(error.response);

        return { error: error.response };
      }
    });
};
