import { axiosInstance as axios } from "services";
import { axios as axiosNoAuth } from "services"
import querystring from "querystring";
import { clog } from "utils/utils";
// import { clog } from "../utils";
import { getEndPoints } from "./getEndPoints";
const url = getEndPoints(process.env.REACT_APP_MODE);

export function post(endPoint, body) {
  return axios
    .post(`${url}${endPoint}`, body)
    .then((response) => {
      return { error: null, response };
    })
    .catch((error) => {
      if (error.response) {
        return { error: error.response };
      }
    });
}

export function put(endPoint, body) {
  return axios
    .put(`${url}${endPoint}`, body)
    .then((response) => {
      return { error: null, response };
    })
    .catch((error) => {
      if (error.response) {
        return { error: error.response };
      }
    });
}

export function get(endPoint, body) {
  let getUrl = `${url}${endPoint}`;
  if (body) {
    const qs = querystring.stringify(body);
    if (qs) {
      getUrl += (getUrl.indexOf("?") >= 0 ? "&" : "?") + qs;
    }
  }
  clog("calling: " + getUrl);
  return axios
    .get(`${getUrl}`)
    .then((response) => {
      return { error: null, response };
    })
    .catch((error) => {
      if (error.response) {
        return { error: error.response };
      }
      return error;
    });
}

export function del(endPoint, body) {
  let getUrl = `${url}${endPoint}`;
  if (body) {
    const qs = querystring.stringify(body);
    if (qs) {
      getUrl += (getUrl.indexOf("?") >= 0 ? "&" : "?") + qs;
    }
  }
  clog("calling: " + getUrl);
  return axios
    .delete(`${getUrl}`)
    .then((response) => {
      return { error: null, response };
    })
    .catch((error) => {
      if (error.response) {
        return { error: error.response };
      }
      return error;
    });
}

export function setAuthHeaders(auth) {
  axios.defaults.headers.common.Authorization = auth;
}

export function AddEmail(endPoint, body) {
  return axios.post(`${url}${endPoint}`, body);
}
export function getAllEmail(endPoint) {
  return axios.get(`${url}${endPoint}`);
}
export function getAllEmailDelete(endPoint, id, body) {
  return axios.post(`${url}${endPoint}${id}`, body);
}

export function AddPhone(endPoint, body) {
  return axios.post(`${url}${endPoint}`, body);
}
export function getAllPhone(endPoint) {
  return axios.get(`${url}${endPoint}`);
}
export function getAllPhoneDelete(endPoint, id, body) {
  return axios.post(`${url}${endPoint}${id}`, body);
}

export function AddProperty(endPoint, body) {
  return axios.post(`${url}${endPoint}`, body);
}
export function getAllProperty(endPoint) {
  return axios.get(`${url}${endPoint}`);
}
export function getAllPropertyDelete(endPoint, id, body) {
  return axios.post(`${url}${endPoint}${id}`, body);
}

export function AddRelationship(endPoint, body) {
  return axios.post(`${url}${endPoint}`, body);
}
export function getAllRelationship(endPoint) {
  return axios.get(`${url}${endPoint}`);
}
export function getAllRelationshipDelete(endPoint, id, body) {
  return axios.post(`${url}${endPoint}${id}`, body);
}
/**
 * subscriptions apis

 */
export function basePlanUser(endPoint, body) {
  return axios.post(`${url}${endPoint}`, body);
}

export function update_onboarding_charge(endPoint, body) {
  return axios.post(`${url}${endPoint}`, body);
}

export function getAllPlans(endPoint) {
  return axios.get(`${url}${endPoint}`);
}

export function getAllOnBoardingCharge(endPoint) {
  return axiosNoAuth.get(`${url}${endPoint}`);
}

/**
 * user roles

 */
export function AddUserRoles(endPoint, body) {
  return axios.post(`${url}${endPoint}`, body);
}
export function getAllUserRoles(endPoint) {
  return axios.get(`${url}${endPoint}`);
}
export function getAllUserRolesDelete(endPoint, id, body) {
  return axios.post(`${url}${endPoint}${id}`, body);
}
/**
 *  screen api
 */
export function AddScreen(endPoint, body) {
  return axios.post(`${url}${endPoint}`, body);
}
export function getAllScreen(endPoint) {
  return axios.get(`${url}${endPoint}`);
}
export function getAllScreenDelete(endPoint, id, body) {
  return axios.post(`${url}${endPoint}${id}`, body);
}
/**
 *  permissions api
 */
export function AddPermisions(endPoint, body) {
  return axios.post(`${url}${endPoint}`, body);
}
export function getAllPermisions(endPoint) {
  return axios.get(`${url}${endPoint}`);
}
export function getAllPermisionsDelete(endPoint, id, body) {
  return axios.post(`${url}${endPoint}${id}`, body);
}
export function AdminPermisions(endPoint, body) {
  return axios.post(`${url}${endPoint}`, body);
}
export function getAllAdminPermisions(endPoint) {
  return axios.get(`${url}${endPoint}`);
}
/**
 *  Personnel
 */
export function getPersonnel(endPoint) {
  return axios.get(`${url}${endPoint}`);
}

export function getSubCrews(endPoint) {
  return axios.get(`${url}${endPoint}`);
}

export function getCarting(endPoint) {
  return axios.get(`${url}${endPoint}`);
}

export function getAllAdminData(endPoint) {
  return axiosNoAuth.get(`${url}${endPoint}`);
}

export function addMaterialPersonel(endPoint, body) {
  return axios.post(`${url}${endPoint}`, body);
}

export function addSubcrewsPersonel(endPoint, body) {
  return axios.post(`${url}${endPoint}`, body);
}

export function addCartingPersonel(endPoint, body) {
  return axios.post(`${url}${endPoint}`, body);
}

export function updatePaymentStatus(endPoint,body) {
  return axiosNoAuth.post(`${url}${endPoint}`,body);
}

export function updateActivateStatus(endPoint,body) {
  return axiosNoAuth.post(`${url}${endPoint}`,body);
}


/**
 *  Material Api
 * get all data of material like catagory ,catagory type,manufacture,series type,uom
 */

export function getAllMaterialOptionsData(endPoint) {
  return axios.get(`${url}${endPoint}`);
}
/**
 *  Material Api
 * get all data of series
 */
export function getAllSeriesDatas(endPoint, body) {
  return axios.post(`${url}${endPoint}`, body);
}
/**
 *  Material Api
 * get uom data of series
 */
export function getAllUomData(endPoint, body) {
  return axios.post(`${url}${endPoint}`, body);
}

/**
 *  Subs?/crew api
 * get all option by category
 */

export function getCategoryOption(endPoint) {
  return axios.get(`${url}${endPoint}`);
}
/**
 *  Subs?/crew api
 * get all option by category
 */
export function getCategoryAllSeries(endPoint, body) {
  return axios.post(`${url}${endPoint}`, body);
}
/**
 *  Subs?/crew api
 * add all the series value
 */
export function addUpdateSeriesAPI(endPoint, body) {
  return axios.post(`${url}${endPoint}`, body);
}
/**
 *  Material Api
 * get all Prefered data
 */
export function getAllPreferedData(endPoint, body) {
  return axios.post(`${url}${endPoint}`, body);
}
/**
 *  Material Api
 * add manufacture and catagory type
 */
export function addManuftureOrSubType(endPoint, body) {
  return axios.patch(`${url}${endPoint}`, body);
}

/**
 *  Material Api
 * add manufacture and catagory type
 */
export function deleteManufactureCatagoryType(endPoint, body) {
  return axios.post(`${url}${endPoint}`, body);
}
/**
 *  Material Api
 * delete series type
 */
export function materialSeriesDelete(endPoint, id) {
  return axios.delete(`${url}${endPoint}${id}`);
}

export function deleteSeriesType(endPoint, id) {
  return axios.delete(`${url}${endPoint}${id}`);
}
/**
 *  Subs/crew api
 * add api of sub type style and roofPitch
 */
export function addNewSubType(endPoint, body) {
  return axios.post(`${url}${endPoint}`, body);
}

export function addNewStyle(endPoint, body) {
  return axios.post(`${url}${endPoint}`, body);
}

export function addNewRoofPitch(endPoint, body) {
  return axios.post(`${url}${endPoint}`, body);
}
/**
 *  Subs/crew api
 * delete api of sub type style and roofPitch
 */
export function deleteSubType(endPoint, body) {
  return axios.post(`${url}${endPoint}`, body);
}

export function deleteStyle(endPoint, body) {
  return axios.post(`${url}${endPoint}`, body);
}

export function deleteRoofPitch(endPoint, body) {
  return axios.post(`${url}${endPoint}`, body);
}


/**
 *  Carting
 *get All series Data of Carting
 *
 */
 export function getAllSeriesCarting(endPoint, body) {
  return axios.post(`${url}${endPoint}`, body);
}
/**
 *delete seriesdata of carting
 */
export function deleteSeriesCarting(endPoint, id) {
  return axios.delete(`${url}${endPoint}${id}`);
}
/**
 *post all series data of carting
 */
export function addUpdateCartingSeries(endPoint, body) {
  return axios.post(`${url}${endPoint}`, body);
}

// export function getAllAdminData(endPoint) {
//   return axios.get(`${url}${endPoint}`);
// }

export function postAdminNotes(endPoint,body) {
  return axiosNoAuth.post(`${url}${endPoint}`,body);
}

export function getParticularCompanyData(endPoint,body) {
  return axiosNoAuth.post(`${url}${endPoint}`,body);
}

// admin email template get

export function get_all_admin_email_template(endPoint) {
  return axiosNoAuth.get(`${url}${endPoint}`);
}

// admin email edit

export function edit_admin_email_template(endPoint,body) {
  return axiosNoAuth.post(`${url}${endPoint}`,body);
}
