import React from 'react'
import {useState, useEffect} from 'react'
import axios from 'axios'
import { addMaterialPersonel, getPersonnel } from 'api/api';

function useMaterialPersonelHook(isLoading) {
  const [materialData,setMaterialData] = useState([])
  //const [isLoading, setIsLoading] = useState(false);
  const [materialDatas, setMaterialDatas] = useState("");
  

  useEffect(() => {
    getMaterialPersonel()
  }, [isLoading]);

  const getMaterialPersonel = () => {
    getPersonnel("/supplier/materialPersonel")
      .then((response) => {
        console.log("response material ", materialData);
        const {data: {result = []} = {}} = response;
        setMaterialData(result);
      })
      .catch((error) => {
        if (error.response) {
          return { error: error.response };
        }
      });
  }

  const addPersonelsMaterial = (phone,setPhone,addPhone,setAddPhone,setIsLoading) => {
    setIsLoading(true);
        const body = {
          personelPosition: phone,
        };
        setAddPhone(!addPhone);
        setPhone("");
        addMaterialPersonel("/supplier/addmaterialPersonel", body)
          .then((response) => {
            setIsLoading(false);
            return { error: null, response };
          })
          .catch((error) => {
            setIsLoading(false);
    
            if (error.response) {
              return { error: error.response };
            }
          });
  }
  return { materialData, addPersonelsMaterial, isLoading }
}

export default useMaterialPersonelHook