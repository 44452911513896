export const getCategoryTypes = (category, pricing = []) => {
  let findCategory = pricing.find((doc) => doc.category === category);

  if (findCategory) {
    let categoryTypes = findCategory.categoryType.map((doc) => {
      return doc.typeName;
    });

    return categoryTypes;
  }

  return [];
};

export const getStyle = (category, selected_category_type, pricing = []) => {
  let { categoryType = [] } = pricing.find((doc) => doc.category === category);

  if (categoryType.length) {
    let res = categoryType.find(
      (doc) => doc.typeName === selected_category_type
    );
    if (res && res.style) {
      return { style: res.style };
    }
  }
};

export const getSubType = (category, selected_category_type, pricing = []) => {
  let { categoryType = [] } = pricing.find((doc) => doc.category === category);

  if (categoryType.length) {
    let res = categoryType.find(
      (doc) => doc.typeName === selected_category_type
    );
    if (res && res.subType) {
      return { subTypes: res.subType, subTypeName: res.subTypeName };
    }
  }

  return [];
};

export const getRoofPitchTypes = (
  category,
  selected_category_type,
  pricing = []
) => {
  let { categoryType = [] } = pricing.find((doc) => doc.category === category);

  if (categoryType.length) {
    let res = categoryType.find(
      (doc) => doc.typeName === selected_category_type
    );
    if (res && res.roofPitch) {
      return { roofPitch: res.roofPitch };
    }
  }
};
export const removeElenentInArray = (element = "", array = []) => {
  return array.filter((ele) => ele != element);
};
