import { getEndPoints } from "../../api/getEndPoints";
import { axios } from "services";
import { useEffect } from "react";
import { useState } from "react";
import {
  getAllAdminData,
  updateActivateStatus,
  updatePaymentStatus,
} from "api/api";
import { toast } from "react-toastify";
import { axios as axiosNoAuth } from "services";

const url = getEndPoints(process.env.REACT_APP_MODE);

const useBilling = (id) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState();
  const [data, setData] = useState();
  const [upcomingInvoice, setUpcomingInvoice] = useState();
  const [historyData, setHistoryData] = useState();
  const [companyBasePlan, setCompanyBasePlan] = useState();

  useEffect(() => {
    // get_all_data();
    // get_all_data_by_companyId();
    // get_upcoming_invoice();
    // get_company_basePlan_by_companyId();
  }, []);
  
  const getSubscriberData = async () => {
    setIsLoading(true);
    try {
      const res = await getAllAdminData(`/admin/payment/allDataForAdmin`);
      console.log({ resData: res });
      if (res?.data?.success) {
        setData(res?.data?.result);
        return res.data.result;
      } else {
        setData([]);
        return [];
      }
    } catch (err) {
      setData([]);
      setIsError(err);
    } finally {
      setIsLoading(false);
    }
  };
  

  const update_subscription_status = (body) => {
    console.log({ bodyveryimp: body });
    setIsLoading(true);
    return updatePaymentStatus(`/admin/payment/updateSubscriberDetails`, {
      ...body,
    })
      .then((res) => {
        if (res.data.success) {
          toast.success(`Payment status updated`);
          return res.data.result;
        } else {
          toast.error(`Something went wrong`);
          return "Something went wrong";
        }
      })
      .catch((err) => {
        setIsError(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const update_activate_status = (body) => {
    console.log({ bodyveryimp: body });
    setIsLoading(true);
    return updateActivateStatus(
      `/admin/payment/updateActivateStatusSubscriberDetails`,
      { ...body }
    )
      .then((res) => {
        if (res.data.success) {
          toast.success(`Subscription status updated`);
          return res.data;
        } else {
          toast.error(`Something went wrong`);
          return "Something went wrong";
        }
      })
      .catch((err) => {
        setIsError(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  console.log({ dataHook: data });

  const get_upcoming_invoice = (customerId) => {
    console.log({ customerId });
    setIsLoading(true);
    return axiosNoAuth
      .post(`/admin/payment/getUpcomingInvoiceForParticularCompany`, {
        customer: customerId,
      })
      .then((res) => {
        console.log({ resDataImpvery: res });
        if (res?.data?.success) {
          setUpcomingInvoice(res?.data?.result);
          return res.data.result;
        } else {
          return null;
        }
      })
      .catch((err) => {
        setUpcomingInvoice(null);
        setIsError(err);
        return null;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const get_all_data_by_companyId = () => {
    setIsLoading(true);
    return axiosNoAuth
      .post(`/admin/payment/particularCompanyDataForAdminDromCompanyId`, {
        companyId: id,
      })
      .then((res) => {
        console.log({ resDataImp: res });
        if (res?.data?.success) {
          setHistoryData(res?.data?.result);
          return res.data.result;
        } else {
          setHistoryData([]);
        }
      })
      .catch((err) => {
        setHistoryData([]);
        setIsError(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const get_company_basePlan_by_companyId = () => {
    setIsLoading(true);
    return axiosNoAuth
      .post("/admin/baseplanuser/userByCompanyId", { companyId: id })
      .then((res) => res.data)
      .then((data) => {
        console.log({ data });
        if (data.success) {
          setCompanyBasePlan(data.result);
          // return dispatch({
          //   type: UserActionTypes.GET_BASE_SUCCESS,
          //   payload: data.result,
          // });
        }
        // return dispatch({
        //   type: UserActionTypes.GET_BASE_FAILURE,
        //   payload: data.error,
        // });
      })
      .catch((errors) => setIsError(errors))
      .finally(() => {
        setIsLoading(false);
      });
  };

  return {
    isLoading,
    isError,
    data,
    update_subscription_status,
    update_activate_status,
    get_upcoming_invoice,
    upcomingInvoice,
    historyData,
    companyBasePlan,
    getSubscriberData
  };
};

export default useBilling;
