import MainComponent from "components/MainComponent";
import React from "react";
import SubscriberTable from "./SubscriberTable";

const InformationPage = () => {
  return (
    <MainComponent>
      <div className="_container_right _user_page">
        <div className="__proposal">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h4>Total Subscribers { }</h4>
          </div>
          <div
            style={{ width: "20rem", float: "right", marginBottom: "3rem" }}
          ></div>
          <SubscriberTable />
        </div>
      </div>
    </MainComponent>
  );
};

export default InformationPage;
