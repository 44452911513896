export function getEndPoints(mode = "DEV") {
  let endPoint = "";
  if (mode === "UAT") {
    endPoint = "http://xxx.xxx.XXX.XXX";
  } else if (mode === "PROD") {
    endPoint = "http://xxx.xxx.XXX.XXX";
  } else {
    endPoint = "https://api.organizedroofer.net";
  }
  return endPoint;
}
