import { getParticularCompanyData, postAdminNotes } from "api/api";
import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";

const useAdminNotes = (body) => {
    const [loading, setLoading] = useState(false)
    const [isError, setIsError] = useState()
    const [data, setData] = useState()

    useEffect(() => {
        get_particular_company_details(body)
    }, [])
    

    const post_admin_data = (body) => {
        setLoading(true)
        return postAdminNotes(`/admin/payment/postAdminNotes`, body).then(res => {
            if(res.data.success){
                toast.success("Note created for th company")
            }else{
                toast.error("Note couldn't be saved. Please try again")
            }
        }).catch(err => {
            toast.error("Something went wrong")
        }).finally(() => {
            setLoading(false)
        })
    }

    const get_particular_company_details = (body) => {
        setLoading(true)
        return getParticularCompanyData(`/admin/payment/particularCompanyDataForAdmin`, body).then(res => {
            if(res?.data?.success){
                console.log({resNotes: res})
                // toast.success("Note created for th company")
                setData(res?.data?.result)
                return res.data.result
            }else{
                return []
            }
        }).catch(err => {
            return []
        }).finally(() => {
            setLoading(false)
        })
    }

    // const get_admin_all_notes_for_particular_comapny = (body) => {

    // }

    return {
        post_admin_data,
        get_particular_company_details,
        data,
        loading
    }
}

export default useAdminNotes